import { useState, useEffect, useRef } from 'react';

// Path2D for a Heart SVG
// const heartSVG = "M0 200 v-200 h200 a100,100 90 0,1 0,200 a100,100 90 0,1 -200,0 z"
// const SVG_PATH = new Path2D(heartSVG);

// // Scaling Constants for Canvas
// const SCALE = 0.1;
// const OFFSET = 80;
export const canvasWidth = 984;
export const canvasHeight = 1240;

export function draw(ctx, location){
  // console.log("attempting to draw")
  // ctx.fillStyle = 'red';
  // ctx.shadowColor = 'blue';
  // ctx.shadowBlur = 15;
  // ctx.save();
  // ctx.scale(SCALE, SCALE);
  // ctx.translate(location.x / SCALE - OFFSET, location.y / SCALE - OFFSET);
  // ctx.rotate(225 * Math.PI / 180);
  // ctx.fill(SVG_PATH);
  // // .restore(): Canvas 2D API restores the most recently saved canvas state
  // ctx.restore();  

  ctx.beginPath();
  ctx.fillStyle = location.selected ? 'blue' :  'white';
  ctx.shadowColor = location.selected ? 'blue' :  'white';
  ctx.shadowBlur = 15;
  ctx.save();
  // if(location.w > 800) {
  //   ctx.rect(location.x1-2,location.y1-9,location.w+6,location.h+6);
  // }
  ctx.stroke();
  ctx.font = "12px Arial";
  ctx.strokeText(location.str, location.x1+4, location.y1+2, location.w);
  // .restore(): Canvas 2D API restores the most recently saved canvas state
  ctx.restore();  
};

export function useCanvasPDF(){
    const canvasRef = useRef(null);
    const [coordinates, setMyCoordinates] = useState([]);

    useEffect(()=>{
        const canvasObj = canvasRef.current;
        const ctx = canvasObj.getContext('2d');
        // clear the canvas area before rendering the coordinates held in state
        ctx.clearRect( 0,0, canvasWidth, canvasHeight );

        // draw all coordinates held in state
        coordinates.forEach((coordinate)=>{draw(ctx, coordinate)});
    });

    return [ coordinates, setMyCoordinates, canvasRef, canvasWidth, canvasHeight ];
}