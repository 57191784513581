import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/utils/ProtectedRoute';
import ListItem from './components/listitem/ListItem';
import Tran from './components/tran/Tran';
import Uploader from './components/upload/Uploader';
import Profile from './components/auth/Profile';
import Account from './components/account/Account';
import AccountInfo from './components/account/AccountInfo';
import SearchTran from './components/tran/SearchTran';
import { getTokenExpiry, isAuthenticated } from './components/utils/Session';
import Footer from './components/layout/Footer';
import NavBar from './components/layout/NavBar';
import SideBar from './components/layout/Sidebar';
import './App.css';
import AdminPanel from './components/admin/AdminPanel';

const Page404 = () => (
  <div>
  <h1>Error 404: Page not found</h1>
  <p>Please click here to go back to <a href="/"><strong>Home</strong></a> page</p>
  </div>
);

class App extends Component {

  render() {
    return (
      <div className="wrapper">
        <SideBar />
        <div className="main-panel">
          <NavBar 
            isAuthenticated={isAuthenticated()}
            sessionExpiry={getTokenExpiry()} />
          <div className="content">
            {this.renderRouter()}
          </div>
          <Footer />
        </div>
      </div>
    )
  }

  renderRouter() {
    return (
        <Switch>
          <ProtectedRoute exact path="/dashboard" component={Account} />
          <ProtectedRoute exact path="/accounts/ac/:accountId" component={AccountInfo} />
          <ProtectedRoute exact path="/accounts" component={Account} />
          <ProtectedRoute exact path="/transactions/ac/:accountId/link/:linkId" component={Tran} />
          <ProtectedRoute exact path="/transactions/ac/:accountId" component={Tran} />
          {/* clean up */}
          <ProtectedRoute exact path="/transactions/:accountId" component={Tran} />
          <ProtectedRoute exact path="/transactions" component={Tran} />
          <ProtectedRoute exact path="/search" component={SearchTran} />
          <ProtectedRoute exact path="/listitems" component={ListItem} />
          <ProtectedRoute exact path="/upload/ac/:accountId" component={Uploader} />
          {/* clean up */}
          <ProtectedRoute exact path="/upload/:accountId" component={Uploader} />
          <ProtectedRoute exact path="/upload" component={Uploader} />
          <ProtectedRoute exact path="/profile" component={Profile} />
          <ProtectedRoute exact path="/admin" component={AdminPanel} />
          <Route component={Page404} />
      </Switch>
    );
  }
}
  
export default App;
