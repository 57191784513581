export const contributorObject = {
  name                   : {
    alias: 'Name',
    value: '',
    hasErrors : {
      error   : false,
      message : null
    },
    rules     : {
      required : true,
      maxLength: 50
    }
  },
  email                   : {
    alias: 'Email Address',
    value: '',
    hasErrors : {
      error   : false,
      message : null
    },
    rules     : {
      required : true,
      noSpaces: true,
      maxLength: 30
    }
  },
  role                   : {
    alias: 'Role',
    value: 'viewer',
    hasErrors : {
      error   : false,
      message : null
    },
    rules     : {
      required : true
    }
  },
  status                   : {
    alias: 'Status',
    value: 'ac',
    hasErrors : {
      error   : false,
      message : null
    },
    rules     : {
      required : true
    }
  }
};
