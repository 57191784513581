/**
 *
 * This module contains details of a tran in form of an object, the details of a tran object
 * determine how a field of a tran should be treated, and what rules should be applied to it
 * 
 * @module common/utils/skeletonTran
 * 
 */

/**
	* 
  *  @property {object} object - object that defines all tran fields
  *  @property {string} object.id - identifier
  *  @property {string} object.data_cy - identifier for testing purposes (cypress)
  *  @property {string} object.type - determines how the field should be displayed (radio, string, dropdown, checkbox, date)
  */
export const skeletonTran = {
	object    : {
		id                   : {
			id        : '_id',
			data_cy   : 'fldId',
			type      : 'string',
			disabled  : false,
			required  : true,
			fields    : {
				id   : '',
				name : ''
			},
			hasErrors : {
				error   : false,
				message : null
			},
			rules     : {
				required : true
			}
		},
		dr                   : {
			id        : 'dr',
			data_cy   : 'fldDebit',
			type      : 'number',
			decimalScale : 2,
			disabled  : false,
			required  : true,
			fields    : {
				id   : '',
				name : ''
			},
			hasErrors : {
				error   : false,
				message : null
			},
			rules     : {
				required : true
			}
		},
		cr                   : {
			id        : 'cr',
			data_cy   : 'fldCredit',
			type      : 'number',
			decimalScale : 2,
			disabled  : false,
			required  : true,
			fields    : {
				id   : '',
				name : ''
			},
			hasErrors : {
				error   : false,
				message : null
			},
			rules     : {
				required : true
			}
		},
		bal                           : {
			id           : 'bal',
			data_cy      : 'fldBalance',
			type         : 'string',
			decimalScale : 2,
			disabled     : false,
			required     : true,
			fields       : {
				id   : '',
				name : ''
			},
			hasErrors    : {
				error   : false,
				message : null
			},
			rules        : {
				required : false
			}
		},
		curr                           : {
			id           : 'curr',
			data_cy      : 'fldCurrency',
			type         : 'string',
			disabled     : false,
			required     : true,
			fields       : {
				id   : '',
				name : ''
			},
			hasErrors    : {
				error   : false,
				message : null
			},
			rules        : {
				required : false
			}
		},
		type                           : {
			id           : 'type',
			data_cy      : 'fldType',
			type         : 'string',
			disabled     : false,
			required     : true,
			fields       : {
				id   : '',
				name : ''
			},
			hasErrors    : {
				error   : false,
				message : null
			},
			rules        : {
				required : false
			}
		},
		qtr                           : {
			id           : 'qtr',
			data_cy      : 'fldQuarter',
			type         : 'string',
			disabled     : false,
			required     : true,
			fields       : {
				id   : '',
				name : ''
			},
			hasErrors    : {
				error   : false,
				message : null
			},
			rules        : {
				required : false
			}
		},
		account                           : {
			id           : 'account',
			data_cy      : 'fldAccount',
			type         : 'string',
			disabled     : false,
			required     : true,
			fields       : {
				id   : '',
				name : ''
			},
			hasErrors    : {
				error   : false,
				message : null
			},
			rules        : {
				required : false
			}
		},
		date       : {
			id        : 'date',
			data_cy   : 'fldDate',
			type      : 'date',
			disabled  : true,
			required  : false,
			fields    : {
				id   : '',
				name : ''
			},
			hasErrors : {
				error   : false,
				message : null
			},
			rules     : {
				required : false
			}
		}
	},
	aliases   : {
		sno: 'S.No',
		dr: 'Debit',
		cr: 'Credit',
		bal: "Balance",
		curr: "Currency",
		type:"Type",
		qtr:"Quarter",
		id: "ID",
		account:"Account",
		date:"Date",
		desc: 'Description',
		note: 'Note'
	}
};
