import React, { Component } from 'react';
import { Button, Grid, Select, MenuItem, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from "notistack";
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {concat as _concat, cloneDeep as _cloneDeep} from 'lodash';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import LoadingOverlay from '../common/loadingOverlay';
import {TranStyle} from './TranStyle';
import { prepareExport } from './prepareExport';
import './Tran.css'
import { getInstance } from '../utils/axiosLoader';

import Constants from '../utils/constants'
import { tranObject } from './tranObject';
import mapDispatchToProps from '../actions/dispatchProps';
import mapStateToProps from '../actions/stateToProps';
import moment from 'moment-timezone';
import LinkTran from './LinkTran';
import CopyTran from './CopyTran';
import { skeletonTran } from './skeletonTran';

let SESSION_KEYS = Constants.SESSION_KEYS;
let defaultFilter = {filterCrDr: -1, filterCrDrText: 'Show Credits'};
class SearchTran extends Component {
    constructor(props) {
		super(props);
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.resetShowAll = this.resetShowAll.bind(this);
		this.handleResetAllFilters = this.handleResetAllFilters.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.applyQuickFilter = this.applyQuickFilter.bind(this);
	}

	updateWindowDimensions() {
		this.setState({ 
			width: window.innerWidth, 
			height: window.innerHeight,
		});
	}

	state = {
		width: 0,
		height: 0,
		enabledAdd: false,
		enabledDelete: false,
		enabledUpdate: false,
		enabledLink: false,
		enabledUnLink: false,
		enabledCopy: false,
		enabledMove: false,
		enabledClone: false,
		enableSubmitOrder: false,
		tranTypes: [],
		tranTypesCrDr: [],
		tranTypeObj: {},
		rowsSelected: [],
		open: {
			createModal   : false,
			updateModal : false,
			debitModal: false,
			creditModal: false,
			linkModal: false,
			copyModal: false,
			moveModal: false
		},
		openOverlay              : false,
		accountLineUp : [Constants.DEFAULT_ACCOUNT_SHOW_ALL],
		selectedAccount: Constants.DEFAULT_ACCOUNT_SHOW_ALL,
		trans : [],
		transOriginal: [],
		tranOrder: [],
		filters: _cloneDeep(defaultFilter),
		searchTerm: ''
	};

	componentDidMount = () => {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		this.props.changePageTitle('Search Transactions')
		
		this.handleGetAccounts();
		//load default tran types of the selected account type default
		// this.handleRefreshRequest();
	};
      
	componentWillUnmount() {
			window.removeEventListener('resize', this.updateWindowDimensions);
	}
	
	handleResetAllFilters = () => {
		this.applyQuickFilter('')
		this.gridApi.setFilterModel(null);
	}
	handleFieldChange = (e) => this.applyAccountChange(e.target.value);
	resetShowAll = () => this.applyAccountChange('unknown');
	handleSearchChange = (e) => this.applyQuickFilter(e.target.value)
	applyQuickFilter = (q) => {
		this.gridApi.setQuickFilter(q);
		this.gridApi.setRowData(this.state.trans);
		this.setState({ searchTerm: q })
	}

	//TODO: Use AccountHandler -> getAccountsCached()
	handleGetAccounts = async () => {
		let defaultAccount = Constants.DEFAULT_ACCOUNT_SHOW_ALL;
		let retrieveFrom = Constants.URLs.ACCOUNTS;
		// this.setState({ openOverlay: true });
		await getInstance()
			.get(retrieveFrom)
			.then((response) => {
				let requested = null;
				// if (parameter !== undefined && parameter !== '') {
				// 	requested = response.data.filter((item) => {
				// 		return item._id === parameter; 
				// 	})[0];
				// } else {
				let active = sessionStorage.getItem(SESSION_KEYS.ACTIVE_ACCOUNT);
				if (active !== undefined && active !== '') {
					requested = response.data.filter((item) => {
						return item._id === active; 
					})[0];
				}
				// }
				// console.log('identified object by the supplied param is:' + JSON.stringify(requested));
				if (!requested) {
					requested = defaultAccount;
					this.props.enqueueSnackbar('invalid key supplied, default account is selected.', {
						variant : 'warning'
					});
				}
				if (requested && requested.hasOwnProperty('curr') && requested.curr !== '') {
					sessionStorage.setItem(SESSION_KEYS.ACTIVE_ACCOUNT_CURR, requested.curr);
				}
				if (requested && requested.hasOwnProperty('type') && requested.type !== '') {
					sessionStorage.setItem(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE, requested.type);
				}
				this.setState({ 
					// openOverlay: false,
					enabledAdd: (requested.code !== 'unknown'),  
					accountLineUp: _concat(defaultAccount, response.data), 
					selectedAccount: requested
				});
			})
			.catch((error) => {
				console.log(error);
				this.props.enqueueSnackbar('Error: Could not be able to retrieve accounts', {
					variant : 'error'
				});
			}).finally(()=>this.handleRefreshTranGrid())
	}

	applyAccountChange = async function (accId) {
		console.log(`applyAccountChange(${accId})`);
		sessionStorage.setItem(SESSION_KEYS.ACTIVE_ACCOUNT, accId);
		let account = this.state.accountLineUp.filter((item)=>{
			return item._id === accId;
		})[0];
		if (account && account.hasOwnProperty('curr') && account.curr !== '') {
			sessionStorage.setItem(SESSION_KEYS.ACTIVE_ACCOUNT_CURR, account.curr);
		}
		if (account && account.hasOwnProperty('type') && account.type !== '') {
			sessionStorage.setItem(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE, account.type);
		}
		//reset last link account since account is changed;
		sessionStorage.removeItem(Constants.SESSION_KEYS.LAST_LINK_ACCOUNT);
		this.setState({ 
			enabledAdd: (account.code !== 'unknown'), 
			filters: _cloneDeep(defaultFilter),
			selectedAccount: account
		});
		
		// this.handleRefreshTranGrid();
		this.handleQuickFilter('account', account.code)
	}

	handleRefreshRequest = () => {
		sessionStorage.setItem('fetch_all_trans_req_ts', moment().format('YYYYMMDDhhmmss'));
		this.handleRefreshTranGrid();
	}

	handleRefreshTranGrid = async (quite = false ) => {
		let retrieveFrom = `${Constants.URLs.TRANS_OF_USER}`;
		this.setState({ openOverlay: true });
		if (sessionStorage.getItem('fetch_all_trans_req_ts')) {
			retrieveFrom += '?request=' + sessionStorage.getItem('fetch_all_trans_req_ts');
		}
		await getInstance()
			.get(retrieveFrom)
			.then((response) => {
				let typeObj = this.state.tranTypeObj;
				let data = response.data; 
				//inject transaction type name from code
				data.forEach((t)=>{
					if (typeObj.hasOwnProperty(t.type)) {
						t.typeName = typeObj[t.type]
					}
				});
				let orders = data.reduce((arr, item) => {
					arr.push({ _id: item._id, sno: item.sno });
					return arr;
				}, []);
				if (!quite) {
					this.props.enqueueSnackbar('Transactions refreshed successfully.', {
						variant : 'success',
						autoHideDuration: 1000
					});
				}
				// _trans = response.data;
				this.setState({ 
					openOverlay: false,
					trans: data, 
					transOriginal: _cloneDeep(data), 
					tranOrder: orders 
				});
			})
			.catch((error) => {
				console.log(error);
				if (!quite) {
					this.props.enqueueSnackbar('Error: Could not be able to retrieve funds', {
						variant : 'error'
					});
				}
			})
			.finally(()=>{
				// 	if (refresh) {
				// 		console.log('refreshing grid...');
				// 		console.dir(_trans);
				// 		this.gridApi.setRowData(_trans);
				// 	}
				this.resetShowAll();
			})
	};


	handleRowHighlights = params => {
		// let isCC = (sessionStorage.getItem(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE) && 
		// 		(sessionStorage.getItem(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE) === 'CC'));
		let cls = '';
		let isCC = false;
		if (params.data.cr > 0) cls = isCC ? 'tran-debit-row' : 'tran-credit-row';
		else if (params.data.dr > 0) cls = isCC ? 'tran-credit-row': 'tran-debit-row';
		
		if (params.data.link_io === 'i') cls += ' tran-link-i';
		else if (params.data.link_io === 'o') cls += ' tran-link-o';
		else if (params.data.link_io === 'iq') cls += ' tran-link-iq';
		else if (params.data.link_io === 'oq') cls += ' tran-link-oq';
		else cls += ' tran-link-na';
		return cls;
	}
	

	handleCloseModal = () => {
		let showModal = this.state.open;
		showModal.createModal = false;
		showModal.updateModal = false;
		showModal.linkModal = false;
		showModal.copyModal = false;
		showModal.moveModal = false;
		showModal.creditModal = false;
		showModal.debitModal = false;
		this.setState({
			open             : showModal
		});
	};


	handleDownloadTrans = async () => {
		this.gridApi.exportDataAsCsv(
			prepareExport.exportSearchTrans(this.state.selectedAccount.name)
		);
	}

	handleOnGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		// if (sessionStorage.getItem('force_refresh')) {
		// 	sessionStorage.removeItem('force_refresh');
		// 	this.handleRefreshRequest();
		// } else {
		// 	this.handleRefreshTranGrid();
		// }
	};

	onSelectionChanged() {
		let flagDelete = false;
		let flagUpdate = false;
		let flagLink = false;
		let flagUnlink = false;
		let flagCopy = false;
		let flagMove = false;
		let flagClone = false;
		let selectedRows = this.gridApi.getSelectedRows();
		let oneRow = selectedRows[0];
		if (oneRow && oneRow._id && oneRow._id !== '') {
			//proceed
		} else {
			return;
		}
		flagDelete = selectedRows.length > 0;
		flagUpdate = selectedRows.length === 1;
		flagClone = selectedRows.length === 1;
		flagCopy = selectedRows.length > 0;
		flagMove = selectedRows.length > 0;
		if (selectedRows.length === 2) {
			// let linksFilter = selectedRows.filter((row)=>{
			// 	return row.link  && row.link === '';
			// }) || [];
			// console.log('linksFilter', selectedRows);
			flagLink = selectedRows[0].link !== '' && selectedRows[1].link !== '' &&
						(selectedRows[0].account.code !== selectedRows[1].account.code);
			if (selectedRows[0].link_io || selectedRows[1].link_io ) {
				flagLink = false;
			}
		}
		let unlinkFilter = selectedRows.filter((row)=>{
			return row.link_io !== '';
		});
		flagUnlink = unlinkFilter && unlinkFilter[0] && unlinkFilter[0].link_io;
		
		// flagLink = selectedRows.length  === 2 && linksFilter.length === 0;
		// if (selectedRows.length === 2 && oneRow.link && oneRow.link !== '') {
		// 	flagLink = false;
		// }
		this.setState({ 
			rowsSelected: selectedRows, 
			enabledDelete: flagDelete, 
			enabledUpdate: flagUpdate,
			enabledLink: flagLink,
			enabledUnlink: flagUnlink,
			enabledCopy: flagCopy,
			enabledMove: flagMove,
			enabledClone: flagClone
		})
	}

	handleQuickFilter = (type, acCode="unknown") => {
		console.log(`handleQuickFilter(${type}, ${acCode})`);
		if (type === 'filterCrDr') {
			// let current = this.state.filters.filterCrDr;
			// let nextFilter = 0;
			let _filters = this.state.filters;
			switch(_filters.filterCrDr) {
				case 0: 
					_filters.filterCrDr = -1; 
					_filters.filterCrDrText = "Show Credits";
					this.destroyColumnFilter('cr')
					this.destroyColumnFilter('dr')
					break;
				case -1: 
					_filters.filterCrDr = 1; 
					_filters.filterCrDrText = "Show Debits";
					this.destroyColumnFilter('cr')
					this.createColumnFilter('dr', 'equals', 0)
					break;
				default: 
					_filters.filterCrDr = 0; 
					_filters.filterCrDrText = "Show All";
					this.destroyColumnFilter('dr')
					this.createColumnFilter('cr', 'equals', 0)
			}
			this.setState({ filters: _filters});
		} else if (type === 'account') {
			if (acCode !== 'unknown') {
				this.createColumnFilter('account.code', 'equals', acCode)
			} else {
                this.destroyColumnFilter('account.code')
            }
		}
	}

	createColumnFilter = (instance, type, filter) => {
		if (!this.gridApi) return;
		var creditFilterComponent = this.gridApi.getFilterInstance(instance);
		var model = {
		  type: type,
		  filter: filter,
		  filterTo: null,
		};
		creditFilterComponent.setModel(model);
		this.gridApi.onFilterChanged();
	}

	destroyColumnFilter = (instance) => {
		if (this.gridApi) this.gridApi.destroyFilter(instance);
	};

	handleLinkTransactions() {
		let _trans = this.state.trans;
		let trans = this.state.rowsSelected;
		let drs = trans.filter((tran)=>{
			return tran.dr > tran.cr;
		});
		let crs = trans.filter((tran)=>{
			return tran.cr > tran.dr;
		});
		let payload = {};
		if (drs && drs[0] && drs[0]._id && crs && crs[0] && crs[0]._id) {
			payload.from = drs[0]._id;
			payload.to = crs[0]._id;
		} else {
			return this.props.enqueueSnackbar('Unable to link selected transactions. Please chose valid ones and try again', {
				variant: 'warning'
			});
		}
		if (trans.length === 2) {
			let URL = Constants.URLs.TRANS + '/linkboth'
			getInstance().post(URL, payload).then(async (response)=>{
				this.props.enqueueSnackbar('Transactions linked succesfully. Reload once all links are done.', {
					variant: 'success'
				});
				let _transio = _trans.reduce((arr, tran)=>{
					if (tran._id === payload.from) {
						tran.link_io = 'oq'
					} else if (tran._id === payload.to) {
						tran.link_io = 'iq'
					}
					arr.push(tran);
					return arr;
				}, []);
				this.setState({ trans: _transio })
				this.gridApi.setRowData(_transio);
			}).catch((error)=>{
				this.props.enqueueSnackbar('Error while linking selected transactions. ' + error.message, {
					variant: 'error'
				});
			})
		}
	}

	handleUnLinkTransactions() {
		let ids = this.state.rowsSelected.reduce((arr, tran)=>{
			arr.push(tran._id);
			return arr;
		},[]);
		getInstance().post(Constants.URLs.TRANS + '/bulkunlink', { ids: ids }).then((response)=>{
			this.props.enqueueSnackbar('Transactions unlinked succesfully.', {
				variant: 'success'
			});
			this.handleRefreshRequest();
		}).catch((error)=>{
			this.props.enqueueSnackbar('Error while unlinking selected transactions. ' + error.message, {
				variant: 'error'
			});
		})
	}

	/**
	 * Render UI components
	 */

	render() {
		let isCompact = this.state.width <= 612;
		return (
			<div className="content-fluid">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">All Transactions</h4>
								<p className="card-category">View and manage all transactions at once.</p>
							</div>
							<div className="card-body" style={{/* backgroundColor: 'blue' */}}>
								{this.renderSelectAccount()}
								{!isCompact && this.renderActions()}
								{this.renderTranGrid()}
								{this.renderGridTitle()}
								{this.state.open.linkModal && this.state.rowsSelected.length === 1 && 
									this.renderModalLinkTran()}
								{this.state.open.linkModal && this.state.rowsSelected.length > 1 && 
									this.renderModalLinkTranMulti()}
								{this.state.open.copyModal && this.renderModalCopyTranMulti()}
								{this.state.open.moveModal && this.renderModalMoveTranMulti()}
								{this.state.openOverlay && <LoadingOverlay />}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderSelectAccount() {
		const { classes } = this.props;
		let isCompact = this.state.width <= 612;
		return (
			<Grid container id="search-trans-account-select" style={{/* marginLeft: "1%" */}}>
				
				<Grid item lg={6} md={6} sm={6} xs={12}>
					<div className={classes.tranAccount} /* style={{ float: "right" }} */>
						<InputLabel>Filter Account:</InputLabel>
						<Select
							name="select-account"
							data-cy="ddTranSelectAccount"
							value={this.state.selectedAccount._id}
							onChange={this.handleFieldChange}
							className={'search-trans-account-select-width'}>
							{this.state.accountLineUp.map((account, index) => {
								return (
									<MenuItem
										key={`account-${account._id}`}
										value={account._id}>
										{Constants.getAccountName(account)}	
									</MenuItem>
								);
							})}
						</Select>
						{this.state.selectedAccount.code !== 'unknown' && <Button onClick={this.resetShowAll}
							variant="outlined"
							size="small"
							style={{marginLeft: 10}}
							color="default">Show All</Button>}
					</div>
				</Grid>

				{!isCompact && <Grid item lg={6} md={6} sm={6} xs={12}>
					<div style={{  float: "right"/*, marginRight: 30 */}}>
						{<Button onClick={() => this.handleQuickFilter('filterCrDr')}
								variant={this.state.filters.filterCrDr === 0 ? "outlined": "contained"}
								size="small"
								style={{margin: 4}}
								color={this.state.filters.filterCrDr === -1 ? "primary" : "secondary"}>{this.state.filters.filterCrDrText}</Button>}
						<Button onClick={() => this.handleDownloadTrans()}
							variant="contained"
							size="small"
							color="primary"
							style={{margin: 4, marginRight: 6}}
						>
							<i className="pe-7s-cloud-download pe-va"></i> Download
						</Button>
					</div>
				</Grid>}
			</Grid>
		)
	}

	renderActions() {
		return (
			<Grid container id="trans-actions" >
				<Grid item md={8} sm={8} xs={12} id="trans-actions-left">
					<Button onClick={() => this.handleRefreshRequest()}
						variant="contained"
						size="small"
						color="primary">Reload</Button>
					<Button disabled={!this.state.enabledLink}
						onClick={() => this.handleLinkTransactions()}
						variant="contained"
						size="small"
						color="default">Link </Button>
						<Button disabled={!this.state.enabledUnlink}
							onClick={() => this.handleUnLinkTransactions()}
							variant="contained"
							size="small"
							color="default">Unlink</Button>
				</Grid>
				<Grid item md={4} sm={4} xs={12}>
						<div style={{ float: "right", marginRight: 8 }}>
							<label forhtml="quickSearch">Search:</label>
							<input  id="quickSearch" type="name" placeholder="Search here" 
								value={this.state.searchTerm} 
								style={{width: 120, height: 30}}
								onChange={this.handleSearchChange} />
							<Button
								onClick={() => this.handleResetAllFilters()}
								variant="contained"
								size="small"
								style={{height: 28, marginBottom: 4}}
								color="default">Reset</Button>
						</div>
				</Grid>
			</Grid>
		)
	}

	renderGridTitle() {
		let crSum = this.state.rowsSelected.reduce((sum, row)=>{
			sum += row.cr
			return sum;
		}, 0);
		let cr = (Math.round(crSum * 100) / 100).toFixed(2);
		let drSum = this.state.rowsSelected.reduce((sum, row)=>{
			sum += row.dr
			return sum;
		}, 0);
		let dr = (Math.round(drSum * 100) / 100).toFixed(2);
		let diff = (Math.round((cr - dr) * 100) / 100).toFixed(2);
		return (
			<Grid container id="trans-grid-notification">
				<Grid item>
					{/* <div className="row"> */}
					<span style={{"fontSize": "14px"}}>Viewing {this.state.trans.length} transactions</span>
					{/* </div>
					<div className="row"> */}&nbsp;&nbsp;
						<span>Credit Sum: {cr}</span>&nbsp;&nbsp;&nbsp;&nbsp;
						<span>Debit Sum: {dr}</span>&nbsp;&nbsp;&nbsp;&nbsp;
						<span>Diff: {diff}</span>
					{/* </div> */}
				</Grid>
			</Grid>
		)
	}

	renderTranGrid() {
		const { classes } = this.props;
		const agStyles = `ag-theme-balham ${classes.agThemeModified}`;
		let colDef = [{
			headerName: skeletonTran.aliases['sno'],
			field: 'sno',
			width: 60,
			sortable: true,
			filter: 'number'
		},{
			headerName: skeletonTran.aliases['date'],
			width: 90,
			field: 'date',
			sortable: true,
			filter: 'date',
			cellRenderer: function(params) {
				try {
					return params.value.slice(0,10);  
				} catch (error) {
					return params.value;
				}
			},
			filterParams: {
					buttons: ['apply','reset'],
					inRangeInclusive: true,
					comparator: Constants.agGridComparatorDate
			},
			floatingFilterComponentParams: {
					suppressFilterButton: true
			}
		},{
			headerName: 'Account',
			field: 'account.code',
			sortable: true,
			filter: true,
			width: 140
		},{
			headerName: 'IO',
			field: 'link_io',
			width: 60,
			sortable: true,
			filter: true,
			cellRenderer: function(params) {
				if (params.value === 'i') {
					return 'IN';
				} else if (params.value === 'o') {
					return 'OUT';
				} else {
					return params.value?.replace('q', '?');
				}
			}
		},{
			headerName: 'Beneficiary',
			width: 140,
			field: 'link_ac.code',
			sortable: true,
			filter: true,
			cellRenderer: function(params) {
				// if (params.data.link_ac && params.data.link_ac._id) {
				//   return `<a target="_blank" href="/transactions/${params.data.link_ac._id}">${params.value}</>`;
				// } else {
					return params.value;
				// }
			}
		},{
			headerName: skeletonTran.aliases['desc'],
			width: 240,
			field: 'desc',
			sortable: true,
			filter: true
		},{
			headerName: skeletonTran.aliases['type'],
			width: 100,
			field: 'type',
			sortable: true,
			filter: true
		},{
			headerName: skeletonTran.aliases['cr'],
			width: 100,
			field: 'cr',
			sortable: true,
			filter: 'number',
			cellStyle: {textAlign: "right"},
			cellRenderer: function(params) {
				return parseFloat(params.value).toFixed(2);
			}
		},{
			headerName: skeletonTran.aliases['dr'],
			width: 90,
			field: 'dr',
			sortable: true,
			filter: 'number',
			cellStyle: {textAlign: "right"},
			cellRenderer: function(params) {
				return parseFloat(params.value).toFixed(2);
			}
		},{
			headerName: skeletonTran.aliases['bal'],
			width: 160,
			field: 'bal',
			sortable: true,
			filter: 'number',
			cellStyle: {textAlign: "right", fontSize: '16px'},
			cellRenderer: function(params) {
				return Constants.toSimpleCurrency(Number(params.value), params.data.curr);
			}
		},{
			headerName: skeletonTran.aliases['curr'],
			width: 90,
			field: 'curr',
			sortable: true,
			filter: true,
			cellStyle: {textAlign: "left"},
		},{
			headerName: skeletonTran.aliases['note'],
			width: 200,
			field: 'note',
			sortable: true,
			filter: true
		}
	];
		return (
			<Grid container id='trans-tran-grid' /* style={{"height": "70%", width: "96%", marginLeft: "2%", marginTop: "10px"}} */ >
				<Grid item  md={12} sm={12} xs={12}>
					<div className={agStyles}>
						<AgGridReact
							gridOptions={{
								defaultColDef: {
									resizable: true
								}
							}}
							columnDefs={colDef}
							rowData={this.state.trans}
							getRowClass={this.handleRowHighlights}
							rowSelection="multiple"
							onSelectionChanged={this.onSelectionChanged.bind(this)}
							animateRows
							onGridReady={this.handleOnGridReady}
							// paginationPageSize={25}
						/>
					</div>
				</Grid>
			</Grid>
		)
	}

	renderModalLinkTran() {
		let tranToLink = _cloneDeep(tranObject);
		if (this.state.rowsSelected.length > 0) {
			let tran  = this.state.rowsSelected[0];
			if (tran._id) tranToLink._id = tran._id;
			if (tran.sno) tranToLink.sno.value = tran.sno;
			if (tran.dr) tranToLink.cr.value = tran.dr;
			if (tran.cr) tranToLink.dr.value = tran.cr;
			if (tran.bal) tranToLink.bal.value = tran.bal;
			if (tran.curr) tranToLink.curr.value = tran.curr;
			if (tran.type) {
				let type;
				switch(tran.type) {
					case 'loan_in': type = 'loan_out'; break;
					case 'loan_out': type = 'loan_in'; break;
					case 'remit_in': type = 'remit_out'; break;
					case 'remit_out': type = 'remit_in'; break;
					case 'credit_in': type = 'credit_out'; break;
					case 'credit_out': type = 'credit_in'; break;
					case 'transfer_in': type = 'transfer_out'; break;
					case 'transfer_out': type = 'transfer_in'; break;
					default: type = 'unknown';
				}
				tranToLink.type.value = type;
			}
			if (tran.qtr) tranToLink.qtr.value = tran.qtr;
			if (tran.account) tranToLink.account.value = tran.account;
			if (tran.date) {
				// tranToLink.date.value = moment(tran.date).format('YYYY-MM-DD');
				tranToLink.date.value = tran.date.substr(0,10)
			}
			if (tran.desc) tranToLink.note.value = 'desc:' + tran.desc + ' ';
			if (tran.note) tranToLink.note.value += 'note:' + tran.note;
		}
		// console.log('transaction to update:', tranToLink);

		let fromAc;
		let active = sessionStorage.getItem(SESSION_KEYS.ACTIVE_ACCOUNT);
		if (active !== undefined && active !== '') {
			fromAc = this.state.accountLineUp.filter((item) => {
				return item._id === active; 
			})[0];
		}
		// }
		// console.log('identified object by the supplied param is:' + JSON.stringify(requested));
		if (!fromAc) {
			this.props.enqueueSnackbar('Please select an account before linking a transaction', {
				variant : 'error'
			});
			return;
		}
		//Allow link between accounts with same current for now; excluding current account
		let linkEligibleAccounts = this.state.accountLineUp.filter((item) => {
			return (item.curr === fromAc.curr && item._id !== fromAc._id) || item._id === 'unknown'; 
		});
		
		tranToLink.desc.value = 'Linked ' + (this.state.open.creditModal ? 'from:' : 'to:') + fromAc.name;

		// console.log('tranToLink', tranToLink);
		return (
			<LinkTran
				display={this.state.open.linkModal}
				onCancel={() => {
					this.handleCloseModal();
				}} 
				onSubmit={() => {
						this.handleCloseModal();
						//TODO: Provide this option as a setting.
						//this.handleRefreshRequest();
						this.props.enqueueSnackbar('Please refresh the account to see link (IO) status', {
							variant : 'info'
						});
				}} 
				tranTypes={this.state.tranTypesCrDr}
				account={this.state.selectedAccount}
				isCredit={this.state.open.creditModal}
				mode={"single"}
				accountLineUp={linkEligibleAccounts}
				transactions={[tranToLink]}
			/>
		)
	}

	renderModalLinkTranMulti() {
		let isDebit = true; //default debit
		let fromAc;
		let active = sessionStorage.getItem(SESSION_KEYS.ACTIVE_ACCOUNT);
		if (active !== undefined && active !== '') {
			fromAc = this.state.accountLineUp.filter((item) => {
				return item._id === active; 
			})[0];
		}
		// }
		// console.log('identified object by the supplied param is:' + JSON.stringify(requested));
		if (!fromAc) {
			this.props.enqueueSnackbar('Please select an account before linking a transaction', {
				variant : 'error'
			});
			return;
		}
		//Allow link between accounts with same current for now; excluding current account
		let linkEligibleAccounts = this.state.accountLineUp.filter((item) => {
			return (item.curr === fromAc.curr && item._id !== fromAc._id) || item._id === 'unknown'; 
		});
		
		let transToLink = this.state.rowsSelected.reduce((arr, tran)=>{
			let tranToLink = _cloneDeep(tranObject);
			if (tran._id) tranToLink._id = tran._id;
			if (tran.sno) tranToLink.sno.value = tran.sno;
			if (tran.dr) tranToLink.cr.value = tran.dr;
			if (tran.cr) {
				tranToLink.dr.value = tran.cr;
				if(tran.cr > 0) isDebit = false;
			}
			if (tran.bal) tranToLink.bal.value = tran.bal;
			if (tran.curr) tranToLink.curr.value = tran.curr;
			if (tran.type) {
				let type;
				switch(tran.type) {
					case 'loan_in': type = 'loan_out'; break;
					case 'loan_out': type = 'loan_in'; break;
					case 'remit_in': type = 'remit_out'; break;
					case 'remit_out': type = 'remit_in'; break;
					case 'credit_in': type = 'credit_out'; break;
					case 'credit_out': type = 'credit_in'; break;
					case 'transfer_in': type = 'transfer_out'; break;
					case 'transfer_out': type = 'transfer_in'; break;
					default: type = 'unknown';
				}
				tranToLink.type.value = type;
			}
			if (tran.qtr) tranToLink.qtr.value = tran.qtr;
			if (tran.account) tranToLink.account.value = tran.account;
			if (tran.date) {
				// tranToLink.date.value = moment(tran.date).format('YYYY-MM-DD');
				tranToLink.date.value = tran.date.substr(0,10)
			}
			if (tran.desc) tranToLink.note.value = 'desc:' + tran.desc + ' ';
			if (tran.note) tranToLink.note.value += 'note:' + tran.note;

			tranToLink.desc.value = 'Linked ' + (isDebit ? 'to:' : 'from:') + fromAc.name;

			arr.push(tranToLink);
			return arr;
		}, []);

		return (
			<LinkTran
				display={this.state.open.linkModal}
				onCancel={() => {
					this.handleCloseModal();
				}} 
				onSubmit={() => {
						this.handleCloseModal();
						//TODO: Provide this option as a setting.
						//this.handleRefreshRequest();
						this.props.enqueueSnackbar('Please refresh the account to see link (IO) status', {
							variant : 'info'
						});
				}} 
				tranTypes={this.state.tranTypesCrDr}
				account={this.state.selectedAccount}
				isCredit={this.state.open.creditModal}
				mode={"multiple"}
				accountLineUp={linkEligibleAccounts}
				transactions={transToLink}
			/>
		)
	}

	
	renderModalCopyTranMulti() {
		let fromAc;
		let active = sessionStorage.getItem(SESSION_KEYS.ACTIVE_ACCOUNT);
		if (active !== undefined && active !== '') {
			fromAc = this.state.accountLineUp.filter((item) => {
				return item._id === active; 
			})[0];
		}
		// }
		// console.log('identified object by the supplied param is:' + JSON.stringify(requested));
		if (!fromAc) {
			this.props.enqueueSnackbar('Please select an account before copy a transaction', {
				variant : 'error'
			});
			return;
		}
		//Allow link between accounts with same current for now; excluding current account
		let linkEligibleAccounts = this.state.accountLineUp.filter((item) => {
			return (item.curr === fromAc.curr && item._id !== fromAc._id) || item._id === 'unknown'; 
		});
		
		let transToLink = this.state.rowsSelected.reduce((arr, tran)=>{
			let tranToLink = _cloneDeep(tranObject);
			if (tran._id) tranToLink._id = tran._id;
			if (tran.sno) tranToLink.sno.value = tran.sno;
			if (tran.dr) tranToLink.dr.value = tran.dr;
			if (tran.cr) tranToLink.cr.value = tran.cr;
			if (tran.bal) tranToLink.bal.value = tran.bal;
			if (tran.curr) tranToLink.curr.value = tran.curr;
			if (tran.type) tranToLink.type.value = tran.type;
			if (tran.qtr) tranToLink.qtr.value = tran.qtr;
			if (tran.account) tranToLink.account.value = tran.account;
			if (tran.date) tranToLink.date.value = tran.date.substr(0,10)
			if (tran.desc) tranToLink.desc.value = tran.desc;
			if (tran.note) tranToLink.note.value = tran.note;

			arr.push(tranToLink);
			return arr;
		}, []);

		return (
			<CopyTran
				display={this.state.open.copyModal}
				onCancel={() => {
					this.handleCloseModal();
				}} 
				onSubmit={() => {
						this.handleCloseModal();
						//TODO: Provide this option as a setting.
						//this.handleRefreshRequest();
						// this.props.enqueueSnackbar('Please refresh the account to see link (IO) status', {
						// 	variant : 'info'
						// });
				}} 
				tranTypes={this.state.tranTypesCrDr}
				account={this.state.selectedAccount}
				isCredit={this.state.open.creditModal}
				mode={"copy"}
				accountLineUp={linkEligibleAccounts}
				transactions={transToLink}
			/>
		)
	}
	
	
	renderModalMoveTranMulti() {
		let fromAc;
		let active = sessionStorage.getItem(SESSION_KEYS.ACTIVE_ACCOUNT);
		if (active !== undefined && active !== '') {
			fromAc = this.state.accountLineUp.filter((item) => {
				return item._id === active; 
			})[0];
		}
		// }
		// console.log('identified object by the supplied param is:' + JSON.stringify(requested));
		if (!fromAc) {
			this.props.enqueueSnackbar('Please select an account before move a transaction', {
				variant : 'error'
			});
			return;
		}
		//Allow link between accounts with same current for now; excluding current account
		let linkEligibleAccounts = this.state.accountLineUp.filter((item) => {
			return (item.curr === fromAc.curr && item._id !== fromAc._id) || item._id === 'unknown'; 
		});
		
		let transToLink = this.state.rowsSelected.reduce((arr, tran)=>{
			let tranToLink = _cloneDeep(tranObject);
			if (tran._id) tranToLink._id = tran._id;
			if (tran.sno) tranToLink.sno.value = tran.sno;
			if (tran.dr) tranToLink.dr.value = tran.dr;
			if (tran.cr) tranToLink.cr.value = tran.cr;
			if (tran.bal) tranToLink.bal.value = tran.bal;
			if (tran.curr) tranToLink.curr.value = tran.curr;
			if (tran.type) tranToLink.type.value = tran.type;
			if (tran.qtr) tranToLink.qtr.value = tran.qtr;
			if (tran.account) tranToLink.account.value = tran.account;
			if (tran.date) tranToLink.date.value = tran.date.substr(0,10)
			if (tran.desc) tranToLink.desc.value = tran.desc;
			if (tran.note) tranToLink.note.value = tran.note;

			arr.push(tranToLink);
			return arr;
		}, []);

		return (
			<CopyTran
				display={this.state.open.moveModal}
				onCancel={() => {
					this.handleCloseModal();
				}} 
				onSubmit={() => {
						this.handleCloseModal();
						//TODO: Provide this option as a setting.
						//this.handleRefreshRequest();
						// this.props.enqueueSnackbar('Please refresh the account to see link (IO) status', {
						// 	variant : 'info'
						// });
				}} 
				tranTypes={this.state.tranTypesCrDr}
				account={this.state.selectedAccount}
				isCredit={this.state.open.creditModal}
				mode={"move"}
				accountLineUp={linkEligibleAccounts}
				transactions={transToLink}
			/>
		)
	}
}

SearchTran.propTypes = {
	classes : PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withStyles(TranStyle)(SearchTran)));
