import React from 'react'
import PropTypes from 'prop-types'
import * as csv from "csvtojson";

const CSVParser = ({
  accept = '.csv',
  cssClass = 'csv-reader-input',
  cssInputClass = 'csv-input',
  fileEncoding = 'UTF-8',
  inputId = 'csvUpload',
  inputStyle = {},
  label,
  onError,
  onFileLoaded,
  headerPresent
}) => {
  const handleChangeCSVFile = async e => {
    let reader = new FileReader()
    const filename = e.target.files[0].name
    reader.onload = async event => {
      const bufferCSV = event.target.result;
      console.log('bufferCSV', bufferCSV)
      let header = bufferCSV.split('\n')[0];
      console.log("csv first row:", bufferCSV.split('\n')[0])
      let errored = false;
      let columns = header.split(',');
      columns.forEach((column)=>{
        let match = columns.filter((c)=>{
          return c === column;
        })
        if (match !== undefined)
        {
          if (match.length > 1) {
            errored = true;
          }
        }
      })
      if (errored) {
        onError(`Header row [${columns.join('|')}] contains duplicate keys. Please make sure header keys are unique.`);
      } else {
        let config = {
            ignoreEmpty: false,
            checkType: true,
            flatKeys: true,
            nullObject: true,
            checkColumn: false,
            maxRowLength: 999
        };
        csv(config)
        .fromString(bufferCSV)
        .then((records) => {      
            onFileLoaded(records, filename);
        })
        .catch((err)=>{
          onError(err.message)
        })

      }
    }
    reader.readAsText(e.target.files[0], fileEncoding)
  }

  return (
    <div className={cssClass}>
      {label && <label htmlFor={inputId}>{label}</label>}
      <input
        className={cssInputClass}
        type="file"
        id={inputId}
        style={inputStyle}
        accept={accept}
        onChange={e => handleChangeCSVFile(e)}
      />
    </div>
  )
}

CSVParser.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onError: PropTypes.func, 
  onFileLoaded: PropTypes.func.isRequired,
  headerPresent: PropTypes.bool.isRequired
}

export default CSVParser
