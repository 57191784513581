import React, { Component } from 'react';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep as _cloneDeep} from 'lodash';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import LoadingOverlay from '../common/loadingOverlay';
import {TranStyle} from './TranStyle';
import './Tran.css'

import mapDispatchToProps from '../actions/dispatchProps';
import mapStateToProps from '../actions/stateToProps';
import { withSnackbar } from 'notistack';
import { TransactionHandler, AccountHandler } from '../api';
import ServiceHandler from '../api/ServiceHandler';

let defaultFilter = {filterCrDr: -1, filterCrDrText: 'Show Credits Only'};

class TranBaseActions extends Component {
    constructor(props) {
		super(props);
		this._handler = new TransactionHandler(this);
		this._accountHandler = new AccountHandler(this);
		this._serviceHandler = new ServiceHandler(this);
	}

	state = {
		openOverlay              : false,
		filters: _cloneDeep(defaultFilter),
	};

	handleRefreshRequest() {
		//reset local buttons
		this.setState({
			filters: _cloneDeep(defaultFilter)
		})
		//return callback
		if (this.props.onRefreshRequest) this.props.onRefreshRequest();
	}

	createColumnFilter = (instance, type, filter) => {
		if (this.props.onCreateColumnFilter) this.props.onCreateColumnFilter(instance, type, filter);
	}

	destroyColumnFilter = (instance) => {
		if (this.props.onDestroyColumnFilter) this.props.onDestroyColumnFilter(instance);
	};

	handleQuickFilter(type) {
		if (type === 'filterCrDr') {
			// let current = this.state.filters.filterCrDr;
			// let nextFilter = 0;
			let _filters = this.state.filters;
			switch(_filters.filterCrDr) {
				case 0: 
					_filters.filterCrDr = -1; 
					_filters.filterCrDrText = "Show Credits Only";
					this.destroyColumnFilter('cr')
					this.destroyColumnFilter('dr')
					break;
				case -1: 
					_filters.filterCrDr = 1; 
					_filters.filterCrDrText = "Show Debits Only";
					this.destroyColumnFilter('cr')
					this.createColumnFilter('dr', 'equals', 0)
					break;
				default: 
					_filters.filterCrDr = 0; 
					_filters.filterCrDrText = "Show Both";
					this.destroyColumnFilter('dr')
					this.createColumnFilter('cr', 'equals', 0)
			}
			if (this.props.onUpdateEnableProperty)
				this.props.onUpdateEnableProperty({
					enabledAttach: false,
					enabledOpenAttach: false,
					enabledClone: false
				})
			this.setState({ 
				filters: _filters,
				// enabledAttach: false,
				// enabledOpenAttach: false,
				// enabledClone: false
			});
		}
	}


	/**
	 * Render UI components
	 */

	renderCoreActionGrid() {
		let { filterCrDr } = this.state.filters;
		let crDrColor = `btn-${filterCrDr === -1 ? 'primary' :
			(filterCrDr === 0 ? 'info' : 'danger')}`;
		let {viewer} = this.props.accountRoles;
		return (
			<Grid item lg={6} md={6} sm={8} xs={12} id="trans-actions-left">
				<Button disabled={!this.props.enabledAdd}
					onClick={() => this.props.onOpenModal('credit')}
					data-cy="btnTranCredit"
					variant="contained"
					size="small"
					color="primary">Credit</Button>
				<Button disabled={!this.props.enabledAdd}
					onClick={() => this.props.onOpenModal('debit')}
					data-cy="btnTranDebit"
					variant="contained"
					size="small"
					color="secondary">Debit</Button>
				<Button disabled={!this.props.enabledUpdate}
					onClick={() => this.props.onOpenModal('update')}
					data-cy="btnTranUpdate"
					variant="contained"
					size="small"
					color="default">Update</Button>
				{(this.props.enabledAttach || this.props.enabledOpenAttach) &&
					<Button
						onClick={() => this.props.onOpenModal('attach')}
						variant="contained"
						size="small"
						color="default">
						{this.props.enabledOpenAttach ? 'View Receipts' : 'Attach Receipts'}
					</Button>}
				<Button disabled={!this.props.enabledClone}
					onClick={() => this._handler.cloneTransaction()}
					data-cy="btnTranClone"
					variant="contained"
					size="small"
					color="default">Clone</Button>
				{this.props.enableSubmitOrder && <Button disabled={!this.props.enableSubmitOrder}
					onClick={() => {
						if (this.props.onSubmitNewTransactionOrder)
							this.props.onSubmitNewTransactionOrder()
					}}
					data-cy="btnTranUpdateOrder"
					variant="contained"
					size="small"
					color="default">Update Order</Button>}
				<button onClick={() => this.handleQuickFilter('filterCrDr')}
					data-cy="btnTranFilterCrDr"
					className={`btn ${crDrColor}`}
				>
					{this.state.filters.filterCrDrText}
				</button>
				{!viewer && <button className="btn btn-secondary btn-sm"
					data-cy="btnTranUnlock"
					onClick={() => {
						if (this.props.onEditMode)
							this.props.onEditMode()
					}}
				>
					<i className={`pe-7s-${this.props.gridEditMode ? 'lock' : 'unlock'}`}
						style={{ fontSize: 24, fontWeight: 'bold' }}></i>
				</button>}
				<Button
					onClick={() => this.handleRefreshRequest()}
					data-cy="btnTranRefreshAll"
					variant="contained"
					size="small"
					color="default">Refresh</Button>
			</Grid>
		)
	}

	renderDropDownMoreButton() {
		let accountId = this.props.selectedAccount._id;
		let {owner,admin, editor, viewer} = this.props.accountRoles;
		let isChitAC = this.props.selectedAccount.type === 'CHIT';
		return (
			<div className="dropdown">
				<button className="btn btn-warning dropdown-toggle" type="button" data-toggle="dropdown"
					aria-haspopup="true" aria-expanded="false" id="dropDownMore"
				>
					More
			</button>
				<div className="dropdown-menu" aria-labelledby="dropDownMore" role="menu">
					{<span className="dropdown-item"
						onClick={() => this.handleQuickFilter('filterCrDr')}>
						<i className="pe-7s-filter pe-va"></i> {this.state.filters.filterCrDrText}
					</span>}
					{owner && this.props.trans.length === 0 &&
						<span className="dropdown-item"
							onClick={() => this.props.onOpenModal("confirmation")}>
							<i className="pe-7s-trash pe-va"></i> Delete Account
                    </span>}
					{accountId !== 'unknown' && <span className="dropdown-item"
						onClick={() => this.props.history.push(`/accounts/ac/${accountId}`)}>
						<i className="pe-7s-pen pe-va"></i> Account Info
					</span>}
					{this.props.trans.length > 0 && !viewer && <span className="dropdown-item"
						onClick={() => this._handler.recalculateAccount()}>
						<i className="pe-7s-calculator pe-va"></i> Recalculate
					</span>}
					{this.props.trans.length > 0 && !viewer && !isChitAC && <span className="dropdown-item"
						onClick={() => this._handler.reorderAccount().finally(()=>{
								this.handleRefreshRequest();
						})}>
						<i className="pe-7s-shuffle pe-va"></i> Re-order transaction by date
				</span>}
					<div className="dropdown-divider"></div>
					{(owner || admin || editor) && !isChitAC && <span className="dropdown-item"
						onClick={() => this.props.history.push(`/upload/${accountId}`)}>
						<i className="pe-7s-cloud-upload pe-va"></i> Import transactions
				</span>}
					{this.props.trans.length > 0 &&
						<span className="dropdown-item"
							onClick={() => {
								if (this.props.onDownloadTrans)
									this.props.onDownloadTrans()
							}}>
							<i className="pe-7s-cloud-download pe-va"></i> Download transactions
					</span>}
					{this.props.trans.length > 0 &&
						<span className="dropdown-item"
							onClick={() => this._serviceHandler.emailTransactions()}>
							<i className="pe-7s-mail-open-file pe-va"></i> Email transactions
					</span>}
					<div className="dropdown-divider"></div>
					{this.props.trans.length > 0 && <span className="dropdown-item"
						onClick={() => this.props.onOpenModal('graph1')}>
						<i className="pe-7s-graph2 pe-va"></i> Yearly Trend
				</span>}
					{this.props.trans.length > 0 && <span className="dropdown-item"
						onClick={() => this.props.onOpenModal('graph2')}>
						<i className="pe-7s-graph pe-va"></i> Tran Type Usage
				</span>}
					<div className="dropdown-divider"></div>
					{this.props.trans.length > 0 && owner && !isChitAC && <span className="dropdown-item"
						onClick={() => this._handler.refreshBeneficiaries()}>
						<i className="pe-7s-refresh-2 pe-va"></i> Refresh Beneficiaries
				</span>}
					<span className="dropdown-item"
						onClick={() => this.handleRefreshRequest()}>
						<i className="pe-7s-refresh-2 pe-va"></i> Refresh Transactions
				</span>
				</div>
			</div>
		)
	}

	renderDropDownCoreButton() {
		let {owner, viewer} = this.props.accountRoles;
		return (
			<div className="dropdown">
				<button className="btn btn-success dropdown-toggle" type="button" data-toggle="dropdown"
					aria-haspopup="true" aria-expanded="false" id="dropDownCore"
				>
					Core
				</button>
				<div className="dropdown-menu"
					aria-labelledby="dropDownCore" role="menu"
				>
					{this.props.enabledAdd && <span className="dropdown-item"
						onClick={() => this.props.onOpenModal('debit')}>
						<i className="pe-7s-less pe-va"></i>  Add Debit</span>}
					{this.props.enabledAdd && <span className="dropdown-item"
						onClick={() => this.props.onOpenModal('credit')}>
						<i className="pe-7s-plus pe-va"></i>  Add Credit</span>}
					{this.props.enableSwapTypes && <span className="dropdown-item"
						onClick={() => {
							if (this.props.onSubmitSwapTransactions)
								this.props.onSubmitSwapTransactions()
						}}>
						<i className="pe-7s-copy-file pe-va"></i>  Swap Cr/Dr </span>}
					{this.props.enabledUpdate && <span className="dropdown-item"
						onClick={() => this.props.onOpenModal('update')}>
						<i className="pe-7s-pen pe-va"></i>  Update</span>}
					{this.props.enabledBulkUpdate && <span className="dropdown-item"
						onClick={() => this.props.onOpenModal('bulkupdate')}>
						<i className="pe-7s-pen pe-va"></i>  Bulk Update </span>}
					{this.props.enabledBulkUpdate && !viewer && <span className="dropdown-item"
						onClick={() => this.props.onOpenModal('bulkupdate')}>
						<i className="pe-7s-pen pe-va"></i>  Bulk Account Update </span>}
					<div className="dropdown-divider"></div>
					{this.props.enabledDelete && <span className="dropdown-item"
						onClick={() => {
							if (this.props.onDeleteTrans) this.props.onDeleteTrans()
						}}>
						<i className="pe-7s-trash pe-va"></i>  Delete Selected</span>}
					{owner && this.props.enabledLink && <span className="dropdown-item"
						onClick={() => this.props.onOpenModal('link')}>
						<i className="pe-7s-link pe-va"></i>  Link To Account </span>}
					{owner && this.props.enabledUnlink && <span className="dropdown-item"
						onClick={() => this._handler.unLinkTransactions()}>
						<i className="pe-7s-link pe-va"></i>  UnLink </span>}
					{this.props.enabledCopy && <span className="dropdown-item"
						onClick={() => this.props.onOpenModal('copy')}>
						<i className="pe-7s-copy-file pe-va"></i>  Copy To Account </span>}
					{this.props.enabledMove && <span className="dropdown-item"
						onClick={() => this.props.onOpenModal('move')}>
						<i className="pe-7s-next-2 pe-va"></i>  Move To Account </span>}
					{this.props.enabledClone && <span className="dropdown-item"
						onClick={() => this._handler.cloneTransaction()}>
						<i className="pe-7s-copy-file pe-va"></i>  Clone transaction </span>}
					<div className="dropdown-divider"></div>
					{!viewer && <span className="dropdown-item"
						onClick={() => {
							if (this.props.onEditMode)
								this.props.onEditMode()
						}}>
						<i className={`pe-7s-${this.props.gridEditMode ? 'unlock' : 'lock'}`}
						></i>
						{this.props.gridEditMode ? ' UnLock Grid' : ' lock Grid'}
					</span>}
				</div>
			</div>
		)
	}

	render() {
		return (
			<Grid container id="trans-actions">
				{!this.props.isCompact && this.renderCoreActionGrid()}
				<Grid item lg={6} md={6} sm={4} xs={12}>
					<div style={{ float: "right", marginRight: 8 }}>
						<div className="btn-group">
							{this.renderDropDownCoreButton()}
							{this.renderDropDownMoreButton()}
						</div>
					</div>
				</Grid>
				{this.state.openOverlay && <LoadingOverlay />}
			</Grid>
		)
	}

}

TranBaseActions.propTypes = {
	classes : PropTypes.object.isRequired,
	history: PropTypes.any,
	selectedAccount: PropTypes.object.isRequired,
	trans: PropTypes.array.isRequired,
	accountRoles: PropTypes.object.isRequired,
	isCompact: PropTypes.bool.isRequired,		//this.state.width <= 612
	onOpenModal: PropTypes.func.isRequired,

	enabledAdd: PropTypes.bool.isRequired,
	enabledDelete: PropTypes.bool.isRequired,
	enabledUpdate: PropTypes.bool.isRequired,
	enabledBulkUpdate: PropTypes.bool.isRequired,
	enabledAttach: PropTypes.bool.isRequired,
	enabledOpenAttach: PropTypes.bool.isRequired,
	enabledLink: PropTypes.bool.isRequired,
	enabledUnlink: PropTypes.bool,
	enabledCopy: PropTypes.bool.isRequired,
	enabledMove: PropTypes.bool.isRequired,
	enabledClone: PropTypes.bool.isRequired,
	enableSubmitOrder: PropTypes.bool.isRequired,
	enableSwapTypes: PropTypes.bool.isRequired,
	gridEditMode: PropTypes.bool.isRequired,
	rowsToUnlink: PropTypes.array.isRequired,
	rowsSelected: PropTypes.array.isRequired,

	onRefreshTranGrid: PropTypes.func,
	onRefreshRequest: PropTypes.func,
	onEditMode: PropTypes.func,
	onSubmitNewTransactionOrder: PropTypes.func,
	onSubmitSwapTransactions: PropTypes.func,
	onDownloadTrans: PropTypes.func,
	onDeleteTrans: PropTypes.func,
	onDeleteAccount: PropTypes.func,
	onCreateColumnFilter: PropTypes.func,
	onDestroyColumnFilter: PropTypes.func,
	onUpdateEnableProperty: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withStyles(TranStyle)(TranBaseActions)));
