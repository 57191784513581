import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './pdf-parser-lines.css'
import Dropzone from '../dropzone/Dropzone'
// import CSVReader from 'react-csv-reader'
// import { TranGrid } from '../tran/TranGrid';
import {TranStyle} from '../../tran/TranStyle';
// import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { withStyles, Button } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import Constants from '../../utils/constants';
import { getInstance } from '../../utils/axiosLoader';
// import moment from 'moment-timezone';
import CanvasPDF from '../../common/canvasPDF';
import mapDispatchToProps from '../../actions/dispatchProps';
import DatePicker from '../components/date-picker';
import NumericEditor from '../components/numeric-editor';
import mapStateToProps from '../../actions/stateToProps';
import { withSnackbar } from 'notistack';

const SESSION_KEYS = Constants.SESSION_KEYS;
class PDFParserLines extends Component {
  constructor(props) {
    super(props)
    this.state = {
        files: [],
        uploading: false,
        uploadProgress: {},
        successfullUploaded: false,
        // csvUploadRows: [{"type":"SAV","curr":"CAD","status":"ac","_id":"5e56bc9aeec11641c4eed919","name":"HDFC Savings Account 4917","code":"HDFC_AC_4917","date":"2020-02-26T18:44:42.849Z","__v":0}],          
        csvUploadRows: [],
        ofxUploadRows: [],
        ofxUploadError: '',
        rawData: "Not parsed yet",
        ddTranTypes: ["unknown"],
        tranTypes: [{"status":"ac","_id":"5e5a00f099332e69a8a0fe29","name":"Unknown","code":"unknown","list":"card_tran_type"}],    
        account: this.props.account,
				canvasData: [],
				canvasPage: 2,
        canvas: false,
        trans: []
      };
  
      this.onFilesAdded = this.onFilesAdded.bind(this);
      this.uploadFiles = this.uploadFiles.bind(this);
      this.renderActions = this.renderActions.bind(this);
  }

  componentDidMount() {
    this.handleGetListItems();
  }

	handleGetListItems = async () => {
		let retrieveFrom = Constants.URLs.LIST_ITEMS_LIST.BASE + '/';
		let accType = 'bank'; //default value
		if (sessionStorage.getItem(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE)) {
			let type = sessionStorage.getItem(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE);
			if (type === 'CC') accType = 'card';
			else if (type === 'SAV' || type === 'CHQ' || type === 'DEF') accType = 'bank';
			// retrieveFrom += `${type}`;	
		}
		// if (CrDr !== null) {
		// 	accType += `_${CrDr}`;
		// }
		retrieveFrom += accType + '_tran_type';
		this.setState({ openOverlay: true });
		await getInstance()
			.get(retrieveFrom)
			.then((response) => {
        // //console.log('list items:' + JSON.stringify(response.data));
        let ddTranTypes = response.data.reduce((arr, type)=>{
          arr.push(type.code);
          return arr;
        },[]);
        ddTranTypes.sort();
				this.setState({ openOverlay: false, tranTypes: response.data, ddTranTypes: ddTranTypes});
			})
			.catch((error) => {
				if (error.response)
				//@////console.log(error);
				this.props.enqueueSnackbar('Error: Could not be able to retrieve list items', {
					variant : 'error'
				});
			});
  }
  
	serializeCanvasData(page) {
		let serialize = [];
			let lineIndex = 0;
			let bayBloorDate1 = null;
			// let bayBloorDate2 = null;
			for(let line in page) {
				lineIndex++;
				let l = {
					y1: line * 10,
					x1: 10,
					w: 820,
					h: 10,
					str: lineIndex,
					line: lineIndex,
					selected: false
				}
				let elements = [];
				let lineParts = [];
				for (let elem of page[line]){
					l.y1 = elem.y1 * 1.6;
					l.h = elem.h * 1.0;
					elem.y1 *= 1.6;
					elem.x1 *= 1.4;
					elem.w *= 1.6;
					elem.h *= 1.1;
					elem.line = lineIndex;
					elem.selected = false;
					lineParts.push(elem);
					elements.push(elem.str);
				}
				try {
					if (elements[0].includes('/') && elements[4].startsWith('$')) {
						l.selected = true;
					}	
				} catch (error) {}
				try {
					if (elements[2].includes("PAYMENT - THANK YOU")) {
						l.selected = true;
					}
        } catch (error) {}
				try {
					if (elements[2] !== ''  && elements[3].startsWith('-$')) {
						l.selected = true;
					}
				} catch (error) {}
				try {
					if (elements[2].includes("OVER LIMIT FEE")) {
						l.selected = true;
					}
				} catch (error) {}
				try {
					if (elements[2].includes("INTEREST CHARGE ON PURCHASES")) {
						l.selected = true;
					}
				} catch (error) {}


				
				try {
					if (elements[2].startsWith("The Bay")) {
						bayBloorDate1 = lineParts[0];
						// bayBloorDate2 = lineParts[1];
					}
				} catch (error) {}
				if (bayBloorDate1) {
					l.selected = true;
				// 	bayBloorDate1.y1 = lineParts[0].y1;
				// 	bayBloorDate2.y1 = lineParts[0].y1;
				// 	lineParts.push(bayBloorDate1);
				// 	lineParts.push(bayBloorDate2);
				}
				try {
					if (elements[0].includes("Transaction Total")) {
						bayBloorDate1 = null;
					}
				} catch (error) {}

				lineParts.forEach(function(linePart){
					linePart.selected = l.selected;
					serialize.push(linePart);
				})

				l.parts = elements;
				serialize.push(l);
			}
			// console.log('serialize', serialize);
      this.setState({canvasData: serialize})
      document.getElementById('pdf-canvas-draw').click();
  }
  
	handleImportPDF = async () => {
    this.setState({ openOverlay: true });
    let ac = this.state.account._id;
    if (ac === 'unknown') {
      this.props.enqueueSnackbar('Selected Account is invalid. Upload cannot be completed.', {
        variant : 'error'
      });
      return;
    }
    // let _actionInProgress = this.state.actionInProgress;
    // _actionInProgress.disableActionButton = true;
    // this.setState({ actionInProgress : _actionInProgress });
    //console.log('upload:',this.state.csvUploadRows )
		await getInstance()
			.post(Constants.URLs.TRANS  + '/many', {account: this.state.account, trans: this.state.trans})
			.then((response)=>{
        //@////console.log(response)
				this.props.enqueueSnackbar(response.data.message, {
					variant : 'success'
        });
        // _actionInProgress.disableActionButton = false;
        this.setState({ /* actionInProgress : _actionInProgress, */ openOverlay: false });
        this.props.onDone();
			})
			.catch((error)=>{
				this.props.enqueueSnackbar('Error: An error occured while importing the PDF transactions', {
					variant : 'error'
        });
        this.props.onErr('An error occured while importing the PDF transactions');
			})
  }

  onFilesAdded(files) {
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    let pageIndex = this.state.canvasPage;
    // console.log('pageIndex', pageIndex);
    this.state.files.forEach(file => {
      promises.push(this.sendRequestAxios(file));
    });
    try {
      await Promise.all(promises)
            .then((response) => {
              // console.log(JSON.stringify(response[0][pageIndex], null, 2 ))
              let page2 = response[0][pageIndex];
			        // console.log(page2);
              this.serializeCanvasData(page2);
              this.setState({ successfullUploaded: true, uploading: false });
              //extract transactions only
              // let lines = response[0].text.split('\n');
              // let start = false;
              // let end = false;
              // let extract  = [];
              // lines.forEach((line)=>{
              //   if (line.startsWith('SUMMARY THIS PERIOD')) start = false;
              //   if (start) {
              //     if (line.substr(2,1) === '/' && !line.endsWith('The Bay BLOOR'))
              //     extract.push(line);
              //   }
              //   if (line.startsWith('TRANSACTIONS FOR ')) start = true;
              // })
              // let trans = [];
              // extract.forEach((tran)=>{
              //   if (extract.indexOf(tran) > 0) {
              //     let dateT = tran.substr(0, 8);
              //     let dateP = '';
              //     try {
              //       dateP = moment(tran.substr(8, 8), 'MM/DD/YY').format('YYYY-MM-DD');  
              //     } catch (error) {}                  
              //     finally {
              //       if (dateP === 'Invalid date') {
              //         dateP = moment().format('YYYY-MM-DD');
              //       }
              //     }
              //     let index = tran.lastIndexOf('$');
              //     let cr = 0;
              //     let dr = tran.substr(index+1);
              //     let desc = tran.substr(16, index - 16).trim();
              //     let type = 'unknown';
              //     if (desc.endsWith('Purchase')) {
              //       type = 'Purchase';
              //       desc = desc.substr(0, desc.length - 8);
              //     }
              //     let obj = {
              //       date: dateP,
              //       cr: cr,
              //       dr: dr,
              //       desc: desc,
              //       type: type
              //     }
              //     trans.push(obj);
              //   }
              // })
              // this.setState({ successfullUploaded: true, uploading: false, rawData: lines.join('\n'),  canvasData: extract.join('\n'), trans: trans, canvas: true });
            })
            .catch((err) => {
              console.log('error: ' + err.message);
            })
  
      
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequestAxios(file) {
    const _this = this; 
    return new Promise(async (resolve, reject) => {
      var formData = new FormData();
      formData.append("file", file, file.name);
      
      var config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: function(progressEvent) {
          if (progressEvent.lengthComputable) {
            const copy = { ..._this.state.uploadProgress};
            copy[file.name] = {
             state: "pending",
             percentage: Math.round( (progressEvent.loaded * 100) / progressEvent.total )
            };
            _this.setState({ uploadProgress: copy });
           }
        },
        onUploadLoad: function(event) {
          const copy = { ..._this.state.uploadProgress};
          copy[file.name] = { state: "done", percentage: 100 };
          _this.setState({ uploadProgress: copy });
          //console.log(req.response)
          //resolve(req.response);
        },
        onUploadError: function(event) {
          const copy = { ..._this.state.uploadProgress};
          copy[file.name] = { state: "error", percentage: 0 };
          _this.setState({ uploadProgress: copy });
          //reject(req.response);
        }
      };

      await getInstance()
				.post(`${Constants.URLs.SERVICE}/extract-pdf`, formData,  config)
				.then((response) => {
					if (response.status !== 200) {
            reject(response.data)
					}
					else {
            resolve(response.data)
					}
				})
				.catch((error) => {
					reject(error);
				});
    })
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <button
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Clear
        </button>
      );
    } else {
      return (
        <button
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          Upload
        </button>
      );
    }
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          {<progress value={uploadProgress ? uploadProgress.percentage : 0} />}
          <img
            className="CheckIcon"
            alt="done"
            src="baseline-check_circle_outline-24px.svg"
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  }

  processCSV = (data) => {
    console.log(data)
    this.setState({ csvUploadRows: data })
  }
  
  handleOnGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  render() {
    let ac = this.state.account._id;
    if (ac === 'unknown') {
      this.props.enqueueSnackbar('Selected Account is invalid. Upload cannot be completed.', {
        variant : 'error'
      });
      return;
    }
    return (
      <div className="content">
        <div className="content-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Upload a PDF file</h4>
                </div>
                <div className="card-body">
                  <Button onClick={() => this.props.onCancel()}
                    variant="contained"
                    size="small"
                    color="primary">Cancel</Button>
                  <div className="Upload">
                    <span className="Title">Please chose a HBC pdf statement and upload.</span>
                    <div className="Content">
                      <div>
                          <Dropzone
                            onFilesAdded={this.onFilesAdded}
                            disabled={this.state.uploading || this.state.successfullUploaded}
                          />
                      </div>
                      <div className="Files" >
                        {this.state.files.map(file => {
                            return (
                                <div key={file.name} className="Row">
                                <span className="Filename">{file.name}</span>
                                {this.renderProgress(file)}
                                </div>
                            );
                            })}
                      </div>
                    </div>
                    {this.renderActions()}
                    {this.state.canvasData.length > 0 && 
                      <CanvasPDF label={'hello world!!'}
                        data={this.state.canvasData}
                        onDataChange={data=>{
                          // console.log(data);
                          data.forEach(function(row) {
                            row.account = ac;
                          })
                          this.setState({trans: data})
                        }} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {this.state.canvas && <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="raw-extract">Raw Extract:</label>
                <textarea id="raw-extract" style={{width: '100%', height: '600px'}} defaultValue={this.state.rawData} />
              </div>
            </div>
          </div>}
          {this.state.canvas && <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="filtered-extract">Filtered:</label>
                <textarea id="filtered-extract" style={{width: '100%', height: '600px'}} defaultValue={this.state.canvasData} />
              </div>
            </div>
          </div>} */}
          {this.state.trans.length > 0 && 
            <div className="row">
              <div className="col-md-12">
                {this.renderProcessedGrid()}
                <Button disabled={this.state.trans<=0}
                      onClick={() => this.handleImportPDF()}
                      variant="contained"
                      size="small"
                      color="primary">Upload</Button>
              </div>
            </div>}
        </div>
      </div>
    )
  }


  renderProcessedGrid() {
		let ac = this.state.account._id;
    if (ac === 'unknown') {
      this.props.enqueueSnackbar('Selected Account is invalid. Upload cannot be completed.', {
        variant : 'error'
      });
      return;
    }
    let processedColDefs = [/* {
      headerName: 'Account', field: 'account', width: 180,
    }, */{
      headerName: 'Date', field: 'date', width: 100, editable: true, cellEditor: 'datePicker'
    },{
      headerName: 'Credit', field: 'cr', width: 80, editable: true, cellEditor: 'numericEditor'
    },{
      headerName: 'Debit', field: 'dr', width: 80, editable: true, cellEditor: 'numericEditor'
    }, {
      headerName: 'Type', field: 'type', width: 100, editable: true, 
      cellRenderer: (params) => {
        let code = params.value;
        let type = this.state.tranTypes.filter((type)=>{
          return type.code === code;
        })[0];
        if (type !== undefined) {
          return type.name;
        } else {
          return code;
        }
      },
      // keyCreator: function (type) {
      //   return type.code;
      // },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
          // cellRenderer: (params) => {}
          values: this.state.ddTranTypes 
      }
    } ,{
      headerName: 'Description', field: 'desc', width: 240, editable: true
    },{
      headerName: 'Note', field: 'note', width: 180, editable: true
    }]
    return (
        <div className="ag-theme-balham" style={{height: '500px'}} >
            {Array.isArray(this.state.trans) && this.state.trans.length > 0 && 
              <AgGridReact
                gridOptions={ {
                //   // editType: 'fullRow',
                  components: {
                    datePicker: DatePicker(),
                    numericEditor: NumericEditor(),
                    typeCellRenderer: (params) => params.value.name
                  },
                  defaultColDef: {
                    resizable: true
                  }
                }}  
                columnDefs={processedColDefs}
                rowData={this.state.trans}
                // getRowClass={this.handleRowHighlights}
                // onGridReady={this.handleOnGridReady}
                // onGridSizeChanged={this.handleGridSizeChanged}
                rowSelection="multiple"
                animateRows
                // suppressFieldDotNotation={true}
              />}
        </div>
    )
  }
}

PDFParserLines.propTypes = {
  classes: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
  onErr: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withStyles(TranStyle)(PDFParserLines)));
