import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import {getInstance} from '../utils/axiosLoader';
import Constants from '../utils/constants';
import mapDispatchToProps from '../actions/dispatchProps';
import LoadingOverlay from '../common/loadingOverlay';
import './authstyle.css';

let _this=null;
let host = window.location.hostname;
let port = window.location.port;
let _domain=`https://${host}`;
if (host.includes('localhost') || host.includes('192.168')) {
    _domain = `http://${host}:${port}`;
} else if(host.includes('codecubers.lab')) {
    _domain = `http://${host}`;
} 
class Register extends Component {
    constructor(props) { 
        super(props) 
        this.handleFieldChange = this.handleFieldChange.bind(this);
        _this = this;
    };

    state = {
        openOverlay: false,
        form: {
            name: '',
            email: '',
            password: '',
            verifyPassword: '',
            domain: _domain
        }
    };

    handleFieldChange = (e) => {
        let _form = this.state.form;
        _form[e.target.name] = e.target.value;
        this.setState({ form: _form });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let _form = this.state.form;
        if (_form.password === '' || (_form.password !== _form.verifyPassword)) {
            this.props.enqueueSnackbar('Password is missing or do not match. Please correct.', {
                variant : 'warning'
            });
            return;
        }
        let instance = getInstance();
        instance.defaults.headers['Content-Type'] = 'application/json';
        this.setState({ openOverlay: true })
        await instance
            .post(Constants.URLs.AUTH.REGISTER, this.state.form)
            .then(async (response) => {
                this.setState({ openOverlay: false })
                //console.log(response.data);
                if (response.status !== 201) {
                    this.props.enqueueSnackbar('Error: ' + response.data.msg, {
                        variant : 'error'
                    });
                }
                else {
                    this.props.enqueueSnackbar(`${response.data.msg}`, {
                        variant : 'success'
                    });
                    this.setState({ openOverlay: false })
                    // await getInstance()
                    //     .get(Constants.URLs.AUTH.ME)
                    //     .then((response)=>{
                    //         this.setState({ openOverlay: false })
                    //         IndexDb.setItem(IndexDb.KEYS.PROFILE, response.data)
                    //         .then((response) => {
                    //             console.log('profile saved with:' + JSON.stringify(response))
                    //         })
                    //         .catch((error)=>{
                    //             console.log(error)
                    //         })
                    //     }).catch((err)=>{
                    //         this.setState({ openOverlay: false })
                    //         console.log(err)
                    //     }).finally(()=>{
                            _this.props.history.push("/auth/login");
                        // })
                }
            })
            .catch((err) => {
                this.setState({ openOverlay: false })
                console.log(err);
                let msg = 'Error: An internal error occured';
                if (err.response && err.response.data && err.response.data.message) {
                    msg = err.response.data.message;
                }
				this.props.enqueueSnackbar(msg, {
					variant : 'error'
				});
            });
    }
    
    renderRegisterForm() {
        return (
        <form className="form" method="">
            <div className="card card-register text-center">
                <div className="card-header ">
                    <div className="row  justify-content-center">
                        <div className="header-text">
                            <h3 className="card-title">Gumasta</h3>
                            <h6 className="card-subtitle">Free ledger to care your monies</h6>
                            <hr />
                            <h3 className="header text-center">Register</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body ">
                    <div className="card-body">
                         <div className="form-group">
                                <input name="name" type="name" placeholder="Your Name" className="form-control" 
                                value={this.state.form.name} 
                                onChange={this.handleFieldChange} />
                            </div>
                            <div className="form-group">
                                <input name="email" type="email" placeholder="Enter email" className="form-control" 
                                value={this.state.form.email} 
                                onChange={this.handleFieldChange} />
                            </div>
                            <div className="form-group">
                                <input name="password" type="password" placeholder="Password" className="form-control"
                                value={this.state.form.password} 
                                onChange={this.handleFieldChange} />
                            </div>
                            <div className="form-group">
                                <input name="verifyPassword" type="password" placeholder="Password Confirmation" className="form-control"
                                value={this.state.form.verifyPassword} 
                                onChange={this.handleFieldChange} />
                            </div>
                    </div>
                </div>
                <div className="card-footer ml-auto mr-auto">
                    <button type="submit" onClick={this.handleSubmit} className="btn btn-warning btn-wd">Create Free Account</button>
                    <hr />
                    <a href="/auth/login">Already a member? Login here</a>
                </div>
            </div>
        </form>
        )
    }

    render() {
        return (
          <div className="container auth-form-container">
            <div className="row">
              <div className="col-md-6 mr-auto ml-auto">
                {this.renderRegisterForm()}
              </div>
            </div>
            {this.state.openOverlay && <LoadingOverlay />}
          </div>
        )
    }
}

Register.propTypes = {};

export default connect(null, mapDispatchToProps)(withSnackbar(Register));