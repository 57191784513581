const appStateReducer = (state = 'C', action) => {
  switch (action.type) {
    case 'SET_APP_STATE': {
      return {
        ...state,
        appStateVal: action.data
      };
    }
    case 'SET_VIEW_PORT': {
      return {
        ...state,
        viewPort: action.data
      };
    }
    case 'SET_DEVICE_PLATFORM': {
      return {
        ...state,
        platForm: action.data
      };
    }
    case 'SET_SCREEN_WIDTH': {
      return {
        ...state,
        width: action.data
      };
    }
    default:
      return state;
  }
};

export default appStateReducer;
