import ApiHandler from './ApiHandler';
import Constants from '../utils/constants';

export default class 
ServiceHandler extends ApiHandler {

    constructor(callerComponent) {
        super(callerComponent);
        this.baseUrl = Constants.URLs.SERVICE;
    }

    emailTransactions() {
        let ac = this.getActiveAccount();
		if (!ac) {
			this.showWarn('Please select an account to recalculate.');
			return;
		}
        let url = `${this.baseUrl}/email-trans/${ac}`;
        return new Promise((resolve, reject)=>{
            this.getInstance().post(url).then((response) => {
                this.showSuccess(response.data.message);
                resolve(true);
            }).catch(err => 
                this.handleError(err, `Error while emailing account transactions. Try again.`))
        });
    }

}
