import React, { Component } from 'react'
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { withSnackbar } from 'notistack';
import {cloneDeep as _cloneDeep} from 'lodash';

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { withStyles } from '@material-ui/core';
import { Button, Grid } from '@material-ui/core';

import Constants from '../../utils/constants';
import { getInstance } from '../../utils/axiosLoader';

import '../Upload.css'
import { TranStyle } from '../../tran/TranStyle';
import mapStateToProps from '../../actions/stateToProps';

const defaultColDefs = { 
  editable: true,
  sortable: true,
  flex: 1,
  minWidth: 100,
  filter: true,
  resizable: true
}

class DupCheckGrid extends Component {
  constructor(props) {
    super(props)
    this.processedTrans = [];
    
    this.state = {
        files: [],
        uploading: false,
        uploadProgress: {},
        successfullUploaded: false,
        showActions: true,
        actionInProgress      : {
          disableActionButton : false
        },
        rowsSelected: [],
        enabledIgnore: false,
        accountTrans: _cloneDeep(this.props.trans),
        processed: [],
        processedColDefs: [/* {
          headerName: 'Account', field: 'account', width: 180,
        }, */{
          headerName: 'Date', field: 'date', width: 110, tooltipField: 'dupData', tooltipComponentParams: { color: '#ececec' }
        },{
          headerName: 'Credit', field: 'cr', width: 90, tooltipField: 'dupData'
        },{
          headerName: 'Debit', field: 'dr', width: 90, tooltipField: 'dupData'
        },{
          headerName: 'Type', field: 'type', width: 100, tooltipField: 'dupData'
        },{
          headerName: 'Description', field: 'desc', width: 200,  tooltipField: 'dupData' 
        },{
          headerName: 'Note', field: 'note', width: 100, tooltipField: 'dupData'
        }],
      };
  }

  // componentDidMount() {
  //   this.handleCheckForDuplicates();
  // }
  // componentDidUpdate() {
  //   this.handleCheckForDuplicates();
  // }

	onSelectionChanged() {
		let selectedRows = this.gridApi.getSelectedRows();
    console.log('selected rows', selectedRows)
		this.setState({ rowsSelected: selectedRows });
		this.setState({ enabledIgnore: (selectedRows.length > 0) })
  }

  handleOnGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }
  
  handleRowHighlights = params => {
    if (params.data.duplicate && params.data.duplicate === 4) return 'ofx-dup-row-4';
    if (params.data.duplicate && params.data.duplicate === 3) return 'ofx-dup-row-3';
    if (params.data.duplicate && params.data.duplicate === 2) return 'ofx-dup-row-2';
    if (params.data.duplicate && params.data.duplicate === 1) return 'ofx-dup-row-1';
    if (params.data.duplicate && params.data.duplicate === 0) return '';
  }

  getAllRows() {
    let rowData = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));
    return rowData;
  }

  handleCheckForDuplicates() {
    let processed = this.props.preProcessed.reduce((arr, tran) => {
			let obj = tran;
      console.log("------> ", obj)
      if (obj.override) {
        //duplicate tag already exists; skip re-evaluating
      } else {
        let _trans = this.state.accountTrans;
        for (var i = _trans.length - 1; i >= 0; i--) {
          if(!_trans[i]){
            break;
          }
          let _tran = _trans[i];
          let _objMonth = moment(obj.date).format('YYYYMM');
          let _tranMonth = moment(_tran.date).format('YYYYMM');
          let _objDate = moment(obj.date).format('YYYYMMDD');
          let _tranDate = moment(_tran.date).format('YYYYMMDD');
          //console.log("_objDate:" + _objDate + " _objMonth:" + _objMonth + " _tranDate:" + _tranDate + " _tranMonth:" + _tranMonth)
          //console.log("Comparing against:", _tran)
          
          if (_objDate === _tranDate && obj.desc === _tran.desc && obj.cr === _tran.cr && obj.dr === _tran.dr) {
            console.log("duplicate level 4 found")
            obj.duplicate = 4;
            obj.dupData = `Exact match: ${_tran.date} | ${_tran.curr} ${_tran.cr + _tran.dr}, ${_tran.desc}, ${_tran.note} `
          }
          else if (_objMonth === _tranMonth && obj.desc === _tran.desc && obj.cr === _tran.cr && obj.dr === _tran.dr) {
            console.log("duplicate level 3 found")
            obj.duplicate = 3;
            obj.dupData = `Possible match: ${_tran.date} | ${_tran.curr} ${_tran.cr + _tran.dr}, ${_tran.desc}, ${_tran.note} `
          }
          else if (_objDate === _tranDate && obj.cr === _tran.cr && obj.dr === _tran.dr) {
            console.log("duplicate level 3 found")
            obj.duplicate = 3;
            obj.dupData = `Possible match: ${_tran.date} | ${_tran.curr} ${_tran.cr + _tran.dr}, ${_tran.desc}, ${_tran.note} `
          }
          else if (obj.desc === _tran.desc && obj.cr === _tran.cr && obj.dr === _tran.dr) {
            console.log("duplicate level 1 found")
            obj.duplicate = 1;
            obj.dupData = `Very-less-Possible match: ${_tran.date} | ${_tran.curr} ${_tran.cr + _tran.dr}, ${_tran.desc}, ${_tran.note} `
          }        
          else if (_objMonth === _tranMonth && obj.cr === _tran.cr && obj.dr === _tran.dr) {
            console.log("duplicate level 2 found")
            obj.duplicate = 2;
            obj.dupData = `Less-possible match: ${_tran.date} | ${_tran.curr} ${_tran.cr + _tran.dr}, ${_tran.desc}, ${_tran.note} `
          }

          if (obj.duplicate === 4) break;
        }
      }
			
      arr.push(obj);
			return arr;
    },[]);
    // this.setState({
    //   processed: processed
    // })
    this.processedTrans = processed;
  }

  handleMarkForUpload() {
    let _trans = this.gridApi.getSelectedRows();
    console.log('selected rows', _trans);
    let _updatedTrans = this.processedTrans.reduce((arr, item, index1)=>{
      let _item = item;
      _trans.forEach((value, index)=>{
        console.log('selected', value)
        console.log("record", item);
        if (value === item) {
          console.log("match found")
          _item.duplicate = 0;
          _item.override = true;
        }
      });
      arr.push(_item);
      return arr;
    }, []);
    console.log('_updatedTrans', _updatedTrans)
    // this.setState({
    //   processed: _updatedTrans
    // })
    this.processedTrans = _updatedTrans;
    this.gridApi.setRowData(_updatedTrans);
  }

	handleUploadTrans = async () => {
    let data = this.getAllRows();
    if (data.length <= 0) {
      this.props.enqueueSnackbar('Date is yet to processed. Please retry.', {
        variant: 'warning'
      })
      return;
    }

    let _trans = data.reduce((arr, _tran)=>{
      if (_tran && _tran.duplicate && _tran.duplicate >= 4) {
        //do not upload exact match records
      } else {
        arr.push(_tran);
      }
      return arr;
    },[]);

    if (_trans.length <= 0) {
      this.props.enqueueSnackbar('Duplicate records ignored and there are no records to upload.', {
        variant: 'warning'
      })
      return;
    }

    let _actionInProgress = this.state.actionInProgress;
    _actionInProgress.disableActionButton = true;
    this.setState({ actionInProgress : _actionInProgress });

		await getInstance()
			.post(Constants.URLs.TRANS  + '/many', {account: this.props.account, trans: _trans})
			.then((response)=>{
        console.log(response)
				this.props.enqueueSnackbar(response.data.message, {
					variant : 'success'
        });
        _actionInProgress.disableActionButton = false;
        this.setState({ actionInProgress : _actionInProgress });
        this.props.onSubmit();
			})
			.catch((error)=>{
				console.log('An error occured while importing the OFX transactions. Error:' + error.message);
				this.props.enqueueSnackbar('Error: An error occured while importing the OFX transactions', {
					variant : 'error'
        });
        this.props.onSubmit();
			})
	}

  /**
   * Render UI Components
   */

  render() {
    this.handleCheckForDuplicates();
    return (
        <div className="ag-theme-balham" style={{height: `${this.props.asModal ? '40vh' : '70vh'}`}} >
            {this.renderActions()}
            {Array.isArray(this.processedTrans) && this.processedTrans.length > 0 && 
              <AgGridReact
                defaultColDef={defaultColDefs}
                columnDefs={this.state.processedColDefs}
                rowData={this.processedTrans}
                getRowClass={this.handleRowHighlights}
                onGridReady={this.handleOnGridReady}
                // onGridSizeChanged={this.handleGridSizeChanged}
                rowSelection="multiple"
                onSelectionChanged={this.onSelectionChanged.bind(this)}
                animateRows
                resizable={true}
                tooltipShowDelay={0}
                tooltipHideDelay={0}
                // suppressFieldDotNotation={true}
                //getRowNodeId={(data)=>data.index}
              />}
        </div>
    )
  }

  
  renderActions() {
    const {classes} = this.props;
    return (
      this.state.showActions && 
        <Grid item md={12} sm={12} xs={12} style={{ /* backgroundColor: "#ff0000", */ justifyItems: "end" }}>
          <div className={classes.ofxUploadActions}>
              <Button
                variant="contained"
                className="btn btn-success"
                disabled={this.processedTrans.length <= 0}
                onClick={() => this.handleUploadTrans()}>
                Upload
              </Button>
              <Button
                variant="contained"
                className="btn btn-success"
                disabled={!this.state.enabledIgnore}
                onClick={() => this.handleMarkForUpload()}>
                Mark For Upload
              </Button>
          </div>
        </Grid>
    )
  }


}

DupCheckGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  display: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  account: PropTypes.object,
  asModal: PropTypes.bool.isRequired,
  trans: PropTypes.array.isRequired,
  preProcessed: PropTypes.array.isRequired,
  onProcessed: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(withSnackbar(withStyles(TranStyle)(DupCheckGrid)));
  