import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';
import { contributorObject } from '../account/contributorObject';
let api = process.env.REACT_APP_API_HOST;

const MONTH_NAMES = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];


function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
    const day = date.getDate();
    const month = MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();

    if (minutes < 10) {
        // Adding leading zero to minutes
        minutes = `0${minutes}`;
    }

    if (prefomattedDate) {
        // Today at 10:20
        // Yesterday at 10:20
        return `${prefomattedDate} at ${hours}:${minutes}`;
    }

    if (hideYear) {
        // 10. January at 10:20
        return `${day} ${month} at ${hours}:${minutes}`;
    }

    // 10. January 2017. at 10:20
    return `${day} ${month} ${year}. at ${hours}:${minutes}`;
}


const Constants = {
    EMPTY_CSV_HEADERS: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
    GRID_ROW_MAX_LENGTH: 300,
    FREE_USER_ACCOUNTS_QUOTA: 20,
    FREE_USER_TRANS_QUOTA: 10000,
    FREE_USER_RECEIPT_SIZE_QUOTA: 100 * 1024, // In KBs
    FREE_USER_RECEIPT_COUNT_QUOTA: 100,
    FREE_USER_RECEIPT_UPLOAD_SIZE_LIMIT: 2 * 1024 * 1024, // 5MB In bytes
    cookie_session: '__session',
    COLORs: {
        ADDAM: 'transparent',
        JAAJI: '#F48860',
        SABBU: '#5AAAF1',
        VANKAYA_250: '#DFD8F5',
        KATUKA_500: '#333333'
    },
    /* "రంగులు": {
        "దర్పనం": 'transparent',
        "జాజి": '#F48860',
        "సబ్బు": '#5AAAF1',
        "వంకాయ_250": '#DFD8F5',
        "కాటుక_500": '#333333'
    }, */
    URLs: {
        ACCOUNTS: `${api}/accounts`,
        ACCOUNT_MEMBERS: `${api}/members`,
        ACCOUNT_SUBSCRIBERS: `${api}/subscribers`,
        ACCOUNT_RECEIPTS: `${api}/receipts`,
        ACCOUNT_SCHEDULE: `${api}/schedule`,
        TRANS: `${api}/transactions`,
        USERS: `${api}/users`,
        TRANS_OF_ACCOUNT: `${api}/transactions/account`,
        TRANS_OF_USER: `${api}/transactions/user`,
        AUTH: {
            LOGOUT: `${api}/auth/logout`,
            LOGIN: `${api}/auth/login`,
            REGISTER: `${api}/auth/register`,
            FORGOT: `${api}/auth/forgot`,
            RESET: `${api}/auth/reset`,
            TOKEN: `${api}/auth/token`
        },
        LIST_ITEMS: `${api}/listitems`,
        LIST_ITEMS_LIST: {
            BASE: `${api}/listitems/list`,
            CURRENCIES: `${api}/listitems/list/currencies`,
            ACCNT_TYPES: `${api}/listitems/list/account_type`,
            LIST_MAJOR: `${api}/listitems/list/list_major`,
        },
        LIST_ITEMS_AVAILABLE: `${api}/listitems/available`,
        SERVICE: `${api}/service`,
        TRAN_TYPES: `${api}/trantypes`,
        WIDGETS: `${api}/widgets`,
        RECEIPTS: `${api}/receipts`,
        STATEMENT: `${api}/statement`
    },
    SESSION_KEYS: {
        ACTIVE_ACCOUNT: "SESSION_KEY_ACTIVE_ACCOUNT",
        ACTIVE_ACCOUNT_TYPE: "SESSION_KEY_ACTIVE_ACCOUNT_TYPE",
        ACTIVE_ACCOUNT_CURR: "SESSION_KEY_ACTIVE_ACCOUNT_CURR",
        ACTIVE_ACCOUNT_GRID_MODE: "SESSION_KEY_ACTIVE_ACCOUNT_GRID_MODE",
        ACTIVE_TRAN_TYPE: "SESSION_KEY_ACTIVE_TRAN_TYPE",
        ACTIVE_LIST_MAJOR: "SESSION_KEY_ACTIVE_LIST_MAJOR",
        CURRENCIES: "SESSION_KEY_CURRENCIES",
        ACCOUNT_TYPES: "SESSION_KEY_ACCOUNT_TYPES",
        LAST_LINK_ACCOUNT: "SESSION_KEY_LAST_LINK_ACCOUNT",
        USER_NAME: "SESSION_KEY_USER_NAME",
        USER_EMAIL: "SESSION_KEY_USER_EMAIL",
        USER_ID: "SESSION_KEY_USER_ID"
    },
    DEFAULT_ACCOUNT: {
        "_id": "unknown",
        "name": "Please Select An Account",
        "code": "unknown",	/* TODO: make unknown a reserved word when adding a new account */
        "status": "ac"
    },
    DEFAULT_ACCOUNT_SHOW_ALL: {
        "_id": "unknown",
        "name": "Show All Accounts",
        "code": "unknown",	/* TODO: make unknown a reserved word when adding a new account */
        "status": "ac"
    },
    // cleanUpCurrency: (s) => {
    //     var expression = /^\$?\([\d,\.]*\)$/;
    //     s = s.trim().replace(',', '');
    //     //Check if it is in the proper format
    //     if(s.match(expression)){
    //         //It matched - strip out parentheses and append - at front      
    //         return parseFloat('-' + s.replace(/[\$\(\),]/g,'')) || 0;
    //     }
    //     else{
    //         return parseFloat(s) || 0;
    //     }   
    // },
    extractAmnt: (s) => {
        let str = s.toString().trim();
        console.log('extractAmnt(str):', str);
        if (str === undefined || str === '') return 0;
        // var str = "$jhkj1,7s68.28.34";
        // console.log('>' + str, str.length);
        let val = '';
        for (let i = 0; i < str.length; i++) {
            let n = -1;
            // extract digit at this position
            try {
                n = parseInt(str[i]);
            } catch (ex) { }
            // if what extracted is finite, append to total string
            if (isFinite(n)) {
                if (!val.includes('-') && (
                    str.substr(0, i).includes('-') ||
                    (
                        str.substr(0, i).includes('(') && str.includes(')')
                    ))) {
                    val = '-';
                }
                val += n;
            } else if (str[i] === '.' && !val.includes('.')) {
                val += '.';
            }
        }
        // finally parse the string sequence as float
        try {
            val = parseFloat(val);
            val = parseFloat(parseInt((val * 100)) / 100)
            val = val.toFixed(2);
        } catch (ex) { }
        // if is not rendered correctly; return 0;
        if (isNaN(val) || val === '') return 0;
        console.log('value:' + val);
        return val;
    },
    getQuarter: (d) => {
        let d1 = new Date();
        try {
            if (!isNaN(new Date(d))) {
                d1 = new Date(d);
            }
        } catch (error) { }
        // console.log('d1:', d1);
        var q = [1, 2, 3, 4];
        var qtr = q[Math.floor(d1.getMonth() / 3)];
        var y = d1.getFullYear();
        return [y, qtr];
    },
    getQtrStr: (d) => Constants.getQuarter(d).join('Q'),
    SIDEBAR_ITEM_LIST: [
        {
            name: "Dashboard",
            path: '/dashboard',
            icon: 'nc-chart-pie-35',
            active: false
        },
        {
            name: "Accounts",
            path: '/accounts',
            icon: 'nc-bank',
            active: false
        },
        {
            name: "Transactions",
            path: '/transactions',
            icon: 'nc-paper-2',
            active: false
        },
        {
            name: "Search",
            path: '/search',
            icon: 'nc-zoom-split',
            active: false
        }/* ,
        {
            name: "Widgets",
            path: '/widgets',
            icon: 'nc-chart-bar-32',
            active: false
        } */,
        {
            name: "Upload",
            path: '/upload',
            icon: 'nc-cloud-upload-94',
            active: false
        },
        {
            name: "User Profile",
            path: '/profile',
            icon: 'nc-circle-09',
            active: false
        },
        {
            name: "Logout",
            path: '/logout',
            icon: 'nc-tap-01',
            active: false
        },
    ],
    timeAgo: (dateParam) => {
        if (!dateParam) {
            return null;
        }

        const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
        const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
        const today = new Date();
        const yesterday = new Date(today - DAY_IN_MS);
        const seconds = Math.round((today - date) / 1000);
        const minutes = Math.round(seconds / 60);
        const isToday = today.toDateString() === date.toDateString();
        const isYesterday = yesterday.toDateString() === date.toDateString();
        const isThisYear = today.getFullYear() === date.getFullYear();


        if (seconds < 5) {
            return 'now';
        } else if (seconds < 60) {
            return `${seconds} seconds ago`;
        } else if (seconds < 90) {
            return 'about a minute ago';
        } else if (minutes < 60) {
            return `${minutes} minutes ago`;
        } else if (isToday) {
            return getFormattedDate(date, 'Today'); // Today at 10:20
        } else if (isYesterday) {
            return getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
        } else if (isThisYear) {
            return getFormattedDate(date, false, true); // 10. January at 10:20
        }

        return getFormattedDate(date); // 10. January 2017. at 10:20
    },
    getCurrencyLocale: (curr = 'USD') => {
        switch (curr.toUpperCase()) {
            case 'INR':
                return 'en-IN'
            case 'JPY':
                return 'ja-JP';
            case 'EUR':
                return 'de-DE';
            default:
                return 'en-US';
        }
    },
    toSimpleCurrency: (amnt = 0.00, curr = 'USD') => {
        let bal = Number(amnt);
        return bal.toLocaleString(Constants.getCurrencyLocale(curr));
    },
    toCurrency: (amnt = 0.00, curr = 'USD') => {
        let bal = Number(amnt);
        return bal.toLocaleString(Constants.getCurrencyLocale(curr), { style: 'currency', currency: curr });
    },
    toCurrencyES6: (amnt = 0.00, curr = 'USD') => {
        let bal = Number(amnt);
        return new Intl.NumberFormat(Constants.getCurrencyLocale(curr),
            { style: 'currency', currency: curr }
        ).format(bal);
    },
    agGridComparatorDate: (filterLocalDateAtMidnight, cellValue) => {
        var dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }
        // var dateAsString = cellValue;
        // var dateParts = dateAsString.split('-');
        // var cellDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));
        var cellDate = moment(cellValue.substr(0, 10)).toDate();
        // console.log('a', moment(filterLocalDateAtMidnight).format('YYYY-MM-DD'))
        // console.log('b',moment(cellDate).format('YYYY-MM-DD'))
        if (moment(filterLocalDateAtMidnight).format('YYYY-MM-DD') === moment(cellDate).format('YYYY-MM-DD')) {
            return 0;
        }

        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }

        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
    },
    getDomain: () => {
        let host = window.location.hostname;
        return (host.includes('codecubers.lab') ? `http://${host}` : `https://${host}`);
    },

    mapMemberToContributor: (member) => {
        console.log('member', member);
        let contributor = cloneDeep(contributorObject);
        if (!member) return contributor;
        // try {
            let {name, email, role, status} = member;
            contributor.name.value = name;
            contributor.email.value = email;
            contributor.role.value = role;
            contributor.status.value = status;
        // } catch (error) {
        //     //
        // }
        console.log('contributor', contributor);
        return contributor;
    },

    getAccountName: (account) => {
        let name = '';
        try {
            name = account.name;
            name += (account.isAdmin ? ' (Admin)' : '');
            name += (account.isEditor ? ' (Editor)' : '');
            name += (account.isViewer ? ' (Viewer)' : '');
        } catch (error) {}
        return name;
    },
    
    secondsToDhmsLong: (seconds) => {
        seconds = Number(seconds);
        var d = Math.floor(seconds / (3600*24));
        var h = Math.floor(seconds % (3600*24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 60);
        
        var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
        var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
        var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;
    },
    
    secondsToDhms: (seconds) => {
        seconds = Number(seconds);
        var d = Math.floor(seconds / (3600*24));
        var h = Math.floor(seconds % (3600*24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 60);
        
        var dDisplay = d > 0 ? d + "D ": "";
        var hDisplay = h > 0 ? h + "H " : "";
        var mDisplay = m > 0 ? m + "M " : "";
        var sDisplay = s > 0 ? s + "S " : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;
    },
    
    secondsToDhmsTime: (seconds) => {
        seconds = Number(seconds);
        var d = Math.floor(seconds / (3600*24));
        var h = Math.floor(seconds % (3600*24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 60);
        
        var dDisplay = d > 0 ? d + "Days ": "";
        var hDisplay = (h < 10 ? "0" : "") + h + ":";
        var mDisplay = (m < 10 ? "0" : "") + m + ":";
        var sDisplay = (s < 10 ? "0" : "") + s;
        return dDisplay + hDisplay + mDisplay + sDisplay;
    },

    /**
     * // formatBytes(bytes,decimals)
     *  formatBytes(1024);       // 1 KB
     *  formatBytes('1024');     // 1 KB
     *  formatBytes(1234);       // 1.21 KB
     *  formatBytes(1234, 3);    // 1.205 KB
     * @param {bytes} a 
     * @param {decimals} b 
     * @returns size in formated string
     */
    formatBytes: (a, b = 2) => { 
        if (0 === a) return "0 Bytes"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024)); return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d] 
    },

    requestTranRefresh: (ac) => {
        if (!ac) {
            ac = sessionStorage.getItem(Constants.SESSION_KEYS.ACTIVE_ACCOUNT);
            if (!ac) return;
        }
        sessionStorage.setItem(ac + '_req_ts', moment().format('YYYYMMDDhhmmss'));
    }

};

export default Constants;
