
export const AdminPanelGrid = {
  defaultColDef: {
    resizable: true,
    filter: true,
    sortable: true,
  },

  columns: [
    {
      headerName: 'Email',
      field: 'email',
      width: 100
    },{
      headerName: '#Accounts',
      field: 'acs',
      width: 60
    },{
      headerName: '#Trans',
      field: 'trans',
      width: 60
    },{
      headerName: 'Id',
      field: 'id',
      width: 100
    },
  ]
};
