import Cookies from 'js-cookie';
import Constants from './constants';
import jwt from 'jsonwebtoken';

let key = Constants.cookie_session;

export const getTokenExpiry = () => {
  let exp = 0;
  let time = 'expired';
  try {
    const token = getSession();
    if (token) {
      var decodedToken=jwt.decode(token, {complete: true});
      exp = decodedToken.payload.exp;
    }
  } catch (error) {
    //
  } finally {
    if (exp && exp > 0) {
      let x = (exp - ((new Date()).getTime() / 1000));
      // let m = parseInt(x / 60);
      // if (m > 59) {
      //   let h = parseInt()
      //   time = `${}`
      // }
      // let s = parseInt(x - (m * 60));
      // time = `${m.toFixed(0)} Min ${s} Sec`;
      time = Constants.secondsToDhmsTime(x);
    }
  }
  return time;
}

export const isAuthenticated = () => {
  const token = getSession();
  // let session = false;
  let isExp = false;
  try {
    if (token) {
      // console.log('token', token);
      // const base64Url = token.split('.')[1];
      // const base64 = base64Url.replace('-', '+').replace('_', '/');
      // // what is window.atob ?
      // // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
      // session = JSON.parse(window.atob(base64));
      var decodedToken=jwt.decode(token, {complete: true});
      // console.log('decoded', decodedToken);
      var dateNow = new Date();
      // console.log('dateNow.getTime()', dateNow.getTime() / 1000)
      isExp = (decodedToken && decodedToken.payload && decodedToken.payload.exp > (dateNow.getTime() / 1000));
      // isExp ? console.info('Session is active until' + Constants.timeAgo(decodedToken.payload.exp * 1000)) :
      // console.warn('Please login, your session is expired, ' + decodedToken.payload.exp + ' ' + Constants.timeAgo(decodedToken.payload.exp * 1000));
    }
  } catch (error) {
    console.log(error);
  }
  

  return isExp;
}

export const isAuthorized = () => {
    let email = sessionStorage.getItem(Constants.SESSION_KEYS.USER_EMAIL);
    return (email && email !== undefined && email !== '' && 
            ['pravynandas@gmail.com', 'chinnusmile4ever@gmail.com'].includes(email.toLowerCase().trim()));
}
export const getSession = () => Cookies.get(key);
export const setSession = (token) => { Cookies.set(key, token); }
export const killSession = () => { 
  Cookies.remove(key); 
  localStorage.removeItem('refreshToken');
}
export const setRefreshToken = (refreshToken) => { localStorage.setItem('refreshToken', refreshToken); };
export const getRefreshToken = () => localStorage.getItem('refreshToken')
