import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'react-responsive-modal';
import { Button, Grid, Typography, TextField, LinearProgress, withStyles } from '@material-ui/core';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import {ListItemStyle} from './ListItemStyle';
import { listitemObject } from './listitemObject';

import Constants from '../utils/constants';
import {validator} from '../utils/validator';
import { getInstance } from '../utils/axiosLoader';
import { withSnackbar } from 'notistack';
import mapStateToProps from '../actions/stateToProps';

class NewListItem extends Component {

	state = {
		actionInProgress      : {
			openLinearLoading   : false,
			disableActionButton : false
		},
		sqlErrorMessage: '',
		listitem          : cloneDeep(listitemObject),
	};

	handleCreateListItem = async () => {
		const {onSubmit} = this.props;
		let _listitem = this.state.listitem;
		let Major = this.props.isMajor;
		let params = {
			list: this.props.listCode,
			code: _listitem.code.value,
			isMajor: Major,
			status: 0,
			msg: ''
		}
		let _actionInProgress = this.state.actionInProgress;
		_actionInProgress.disableActionButton = true;
		_actionInProgress.openLinearLoading = true;
		this.setState({ actionInProgress : _actionInProgress });

		let prepareListItemObject = this.prepareListItemObjectForSave(_listitem, Major);
		await getInstance()
			.post(Constants.URLs.LIST_ITEMS, prepareListItemObject)
			.then((response) => {
				
				_actionInProgress.disableActionButton = false;
				_actionInProgress.openLinearLoading = false;
				this.setState({
					sqlErrorMessage  : response.data.message,
					actionInProgress : _actionInProgress
				});

				// console.log(response.data)
				params.list = prepareListItemObject.list;
				params.status = 'warning';
				if (response.status === 201) {
					params.status = 'success';
					params.msg = 'List Item added successfully';
				} else if (response.status === 200) {
					params.msg = 'List item already exists';
				} else {
					params.msg = 'Undefined server response';
				}
				onSubmit(params);
				
			})
			.catch((error) => {
				console.log(error);
				params.status = 'error';
				if (error.response.data.message) {
					params.msg = error.response.data.message;
				} else {
					params.msg = 'An error occurred from the server';
				}
				onSubmit(params);
			});
	};

	prepareListItemObjectForSave = (_listitem = null, _majorItem = false) => {
		let preparingReleaseObject = {};
		let _keys = Object.keys(_listitem);

		for (let key of _keys) preparingReleaseObject[key] = _listitem[key].value;
		if (_majorItem) {
			preparingReleaseObject.list = 'list_major';
		} else {
			preparingReleaseObject.list = this.props.listCode;
		}
		return preparingReleaseObject;
	};

	handleFieldValidation = (e) => {
		let _listitem = this.state.listitem;
		_listitem[e.target.name].value = e.target.value;
		_listitem.list.value = this.props.listCode;
		// _field.value = e.target.value;
		
		for (let _fieldKey of Object.keys(_listitem)) {
			let _field = _listitem[_fieldKey];
			let rules = Object.keys(_field.rules);
			if (rules) {
				for (let rule of rules) {
					if (_field.rules[rule] && rule in validator) {
						let validation = validator[rule](_field.value, _field.rules[rule]);

						if (!validation.passed) {
							_field.hasErrors.error = true;
							_field.hasErrors.message = validation.message;

							break;
						}
						else {
							_field.hasErrors.error = false;
							_field.hasErrors.message = validation.message;
						}
					}
				}
			}
		}
		this.handleFieldsErrorFree(_listitem)
	};

	handleFieldsErrorFree = async (fields/* type = 'N' */) => {
		// let _originalCopiedRelease = this.state.originalCopiedRelease;
		// let fields = this.state.release;
		let _keys = Object.keys(fields);
		let errorFree = [];

		for (let key of _keys) {
			if (fields[key].hasErrors.error) {
				errorFree.push(fields[key].hasErrors.error);
				continue;
			}
			else {
				if (fields[key].rules.hasOwnProperty('required')) {
					let validation = validator['required'](fields[key].value, fields[key].rules.required);
					if (!validation.passed) {
						fields[key].hasErrors.error = true;
						fields[key].hasErrors.message = validation.message;
					}
					else {
						fields[key].hasErrors.error = false;
						fields[key].hasErrors.message = validation.message;
					}

					if (fields[key].hasErrors.error === false) {
						// if (type === 'U') {
						// 	if (_originalCopiedRelease[key].value !== fields[key].value) {
						// 		await this.findValueInDB(fields, fields[key]);
						// 		if (fields[key].hasErrors.error) errorFree.push(fields[key].hasErrors.error);
						// 	}
						// }
						// else {
							// if (fields[key].alias === 'Code' && fields[key].value.length > 2) {
							// 	await this.findValueInDB(fields, fields[key]);
							// 	if (fields[key].hasErrors.error) errorFree.push(fields[key].hasErrors.error);
							// }
						// }
					}
				}
				errorFree.push(fields[key].hasErrors.error);
			}
		}

		this.setState({ 
			listitem: fields,
			actionInProgress : {disableActionButton: errorFree.includes(true)}
		});
		return !errorFree.includes(true); // if the array includes at least one "true" then field(s) has errors
	};

	
	// findValueInDB = async (_newListItem, _field) => {
	// 	let list = this.props.isMajor ? 'list_major' : this.props.listCode;
	// 	let checkIn = `${Constants.URLs.LIST_ITEMS_AVAILABLE}/${list}/${_field.value}`;
	// 	let errorMessage = 'Code already exists in this list';

	// 	await getInstance()
	// 		.get(checkIn)
	// 		.then((response) => {
	// 			if (response.data.response) {
	// 				_field.hasErrors.error = true;
	// 				_field.hasErrors.message = errorMessage;
	// 			}
	// 			else {
	// 				_field.hasErrors.error = false;
	// 				_field.hasErrors.message = '';
	// 			}
	// 			this.setState({ listitem: _newListItem });
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 			this.props.enqueueSnackbar('An error occurred from the server', {
	// 				variant : 'error'
	// 			});
	// 		});
	// };


	render() {
		const { classes } = this.props;
		return (
			<Modal
				open={this.props.display}
				center
				showCloseIcon={false}
				closeOnOverlayClick={false}
				closeOnEsc={false}
				onClose={() => {}}
				styles={{ modal: { padding: 0 } }}>
				<Grid container className={classes.newReleaseModal}>
					<Grid item md={12} lg={12} xs={12}>
						{this.props.isMajor && 
							<Typography align="center">
								Create new list item
							</Typography>}
						{!this.props.isMajor && 
							<div>
								<Typography align="center">
								Create new list item in
								</Typography>
								<Typography variant="h5" align="center">
									{`${this.props.listName}`}
								</Typography>
							</div>
							}
						
					</Grid>
					<Grid item md={12} lg={12} xs={12}>
						<TextField
							name={'name'}
							label={this.state.listitem.name.alias}
							className={classes.textField}
							margin="normal"
							type="string"
							value={this.state.listitem.name.value}
							error={this.state.listitem.name.hasErrors.error}
							required={true}
							onChange={this.handleFieldValidation}
							variant="standard" // opt: standard, outlined or filled
							helperText={this.state.listitem.name.hasErrors.message}
						/>
						<TextField
							name={'code'}
							label={this.state.listitem.code.alias}
							className={classes.textField}
							margin="normal"
							type="string"
							value={this.state.listitem.code.value}
							error={this.state.listitem.code.hasErrors.error}
							required={true}
							onChange={this.handleFieldValidation}
							variant="standard" // opt: standard, outlined or filled
							helperText={this.state.listitem.code.hasErrors.message}
						/>
					</Grid>
					<Grid item md={12} lg={12} xs={12}>
						<Typography variant="caption" align="center" className={classes.sqlErrorMessage}>
							{this.state.sqlErrorMessage}
						</Typography>
					</Grid>
				</Grid>
				<div className={classes.newReleaseModalActions}>
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={() => this.props.onCancel()}>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="secondary"
						className={classes.button}
						disabled={this.state.actionInProgress.disableActionButton}
						onClick={() => this.handleCreateListItem()}>
						Submit
					</Button>
				</div>
				{this.state.actionInProgress.openLinearLoading && (
					<LinearProgress color="secondary" variant="query" />
				)}
			</Modal>
		);
	}
}

NewListItem.propTypes = {
	classes : PropTypes.object.isRequired,
	isMajor: PropTypes.bool.isRequired,
	listCode: PropTypes.string.isRequired,
	listName: PropTypes.string.isRequired,
	display: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(withSnackbar(withStyles(ListItemStyle)(NewListItem)));
