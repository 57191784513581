import React from 'react';
import { withStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import UploadOFX from './UploadOFX';
import UploadCSV from './UploadCSV';
import Constants from '../utils/constants';
import { getInstance } from '../utils/axiosLoader';
import LoadingOverlay from '../common/loadingOverlay';
import { TranStyle } from './TranStyle';
import mapDispatchToProps from '../actions/dispatchProps';
import PDFParserLines from '../upload/pdf-parser/pdf-parser-lines';
import UploadQIF from './UploadQIF';
import SelectAccount from '../common/selectAccount';
import { AccountHandler } from '../api';
let SESSION_KEYS = Constants.SESSION_KEYS;

class Uploader extends React.Component {

    constructor(props) {
        super(props);
        this._handler = new AccountHandler(this);
    }

    state = {
        enabledUpload: false,
        open: {
            uploadOFX: false,
            uploadCSV: false,
            uploadPDF: false,
            uploadQIF: false
        },
        roles: {
            owner: true,
            admin: false,
            editor: false,
            viewer: false
        },
        accounts: [Constants.DEFAULT_ACCOUNT],
        selectedAccount: Constants.DEFAULT_ACCOUNT,
        openOverlay: false,
        canvasPage: 2,
        canvasData: [],
        gridRows: [],
        trans: []
    }

    componentDidMount() {
        try {
            const { match: { params } } = this.props;
            if (params.accountId) {
                sessionStorage.setItem(SESSION_KEYS.ACTIVE_ACCOUNT, params.accountId);
            }
        } catch (error) {
            console.log(error)
        }
    }

    handleImportPDF = async () => {
        this.setState({ openOverlay: true });
        let ac = this.state.selectedAccount._id;
        if (ac === 'unknown') {
            this.props.enqueueSnackbar('Selected Account is invalid. Upload cannot be completed.', {
                variant: 'error'
            });
            return;
        }
        await getInstance()
            .post(Constants.URLs.TRANS + '/many', { account: this.state.selectedAccount, trans: this.state.gridRows })
            .then((response) => {
                //@////console.log(response)
                this.props.enqueueSnackbar(response.data.message, {
                    variant: 'success'
                });
                // _actionInProgress.disableActionButton = false;
                this.setState({ /* actionInProgress : _actionInProgress, */ openOverlay: false });
            })
            .catch((error) => {
                this.props.enqueueSnackbar('Error: An error occured while importing the PDF transactions', {
                    variant: 'error'
                });
            })
    }

    handleOpenModal(type) {
        let _open = this.state.open;
        _open.uploadOFX = false;
        _open.uploadCSV = false;
        _open.uploadPDF = false;
        _open.uploadQIF = false;
        switch (type) {
            case 'ofx':
                _open.uploadOFX = true;
                break;
            case 'csv':
                _open.uploadCSV = true;
                break;
            case 'pdf':
                _open.uploadPDF = true;
                break;
            case 'qif':
                _open.uploadQIF = true;
                break;
            default:
                break;
        }
        this.setState({ open: _open });

        let ac = this.state.selectedAccount._id;
        this._handler.getAccountTrans(ac, true).then((trans)=>{
            this.setState({
                trans
            })
        });
    }

    handleCloseModal() {
        sessionStorage.setItem('force_refresh', 'uploader');
        let _open = this.state.open;
        _open.uploadOFX = false;
        _open.uploadCSV = false;
        _open.uploadPDF = false;
        _open.uploadQIF = false;
        this.setState({ open: _open });
    }

    /**
     * Render UI Components
     */
    renderActions() {
        return (
            // <div className="row" style={{ height: '200px' }}>
            //     <div className="content">
                    <div className="content-fluid">
                        <div className="row">
                            {!this.state.roles.viewer && <div className="col-lg-3 col-sm-6">
                                {this.state.enabledUpload && <button className="btn btn-primary"
                                    onClick={() => this.handleOpenModal('ofx')}>
                                    <i className="pe-7s-cloud-upload pe-va"></i> Upload OFX
									</button>}
                            </div>}
                            {!this.state.roles.viewer && <div className="col-lg-3 col-sm-6">
                                {this.state.enabledUpload && <button className="btn btn-primary"
                                    onClick={() => this.handleOpenModal('qif')}>
                                    <i className="pe-7s-cloud-upload pe-va"></i> Upload QIF
									</button>}
                            </div>}
                            {!this.state.roles.viewer && <div className="col-lg-3 col-sm-6">
                                {this.state.enabledUpload && <button className="btn btn-primary"
                                    onClick={() => this.handleOpenModal('csv')}>
                                    <i className="pe-7s-cloud-upload pe-va"></i> Upload CSV
									</button>}
                            </div>}
                            {!this.state.roles.viewer && <div className="col-lg-3 col-sm-6">
                                {this.state.enabledUpload && <button className="btn btn-primary"
                                    onClick={() => this.handleOpenModal('pdf')}>
                                    <i className="pe-7s-cloud-upload pe-va"></i> Upload PDF
									</button>}
                            </div>}
                            <div className="col-lg-3 col-sm-6">
                                {this.state.enabledUpload && <button className="btn btn-info"
                                    onClick={() => this.props.history.push(`/transactions/${this.state.selectedAccount._id}`)}>
                                    <i className="pe-7s-back pe-va"></i> Back to Transactions
									</button>}
                            </div>
                        </div>
                    </div>
            //     </div>
            // </div>
        )
    }

    renderModalUploadOFX() {
        return (
            <UploadOFX
                asModal={false}
                display={this.state.open.uploadOFX}
                onCancel={() => {
                    this.handleCloseModal();
                }}
                onSubmit={() => {
                    this.handleCloseModal();
                }}
                account={this.state.selectedAccount}
                trans={this.state.trans}
            />
        )
    }

    renderModalUploadQIF() {
        return (
            <UploadQIF
                asModal={false}
                display={this.state.open.uploadQIF}
                onCancel={() => {
                    this.handleCloseModal();
                }}
                onSubmit={() => {
                    this.handleCloseModal();
                }}
                account={this.state.selectedAccount}
            />
        )
    }

    renderModalUploadCSV() {
        return (
            <UploadCSV
                display={this.state.open.uploadCSV}
                onCancel={() => {
                    this.handleCloseModal();
                }}
                onDataReady={(data) => {
                    // console.log(data);
                }}
                onSubmit={() => {
                    this.handleCloseModal();
                }}
                account={this.state.selectedAccount}
            />
        )
    }

    renderModalUploadPDF() {
        return (
            <PDFParserLines
                asModal={false}
                display={this.state.open.uploadPDF}
                onCancel={() => {
                    this.handleCloseModal();
                }}
                onDone={() => {
                    this.handleCloseModal();
                }}
                onErr={(error) => {
                    console.log('Error', error);
                    this.handleCloseModal();
                }}
                account={this.state.selectedAccount}
            />
        )
    }

    renderCardView() {
        return (
            <div className="content-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Upload Transactions to Account</h4>
                                <p className="card-category">Chose a file format and upload transactions to your account.</p>
                            </div>
                            <div className="card-body">
                                <SelectAccount
                                    referee="uploader"
                                    onReady={(accounts, ac, roles)=>{
                                        this.setState({
                                            enabledUpload: (ac.code !== 'unknown'),
                                            accounts,
                                            selectedAccount: ac,
                                            roles
                                        })
                                        if (ac._id !== 'unknown') {
                                            // perform any valid action on the 
                                        }
                                    }}
                                    onChange={(accounts, ac, roles)=>{
                                        if (ac._id !== 'unknown') {
                                            this.props.history.push(`/upload/${ac._id}`);
                                            window.location.reload();
                                        }
                                    }} />
                                {this.renderActions()}
                                {this.state.open.uploadCSV && this.renderModalUploadCSV()}
                                {this.state.open.uploadOFX && this.renderModalUploadOFX()}
                                {this.state.open.uploadQIF && this.renderModalUploadQIF()}
                                {this.state.open.uploadPDF && this.renderModalUploadPDF()}
                                {this.state.openOverlay && <LoadingOverlay />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return this.renderCardView();
    }
}

export default connect(null, mapDispatchToProps)(withStyles(TranStyle)(withSnackbar(Uploader)));