import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux"
import mapStateToProps from "../actions/stateToProps"
import { Checkbox, Grid, TextField, Typography } from "@material-ui/core";
import { validator } from "./validator";
import { withStyles } from '@material-ui/core/styles';
import {TranStyle} from '../tran/TranStyle';
let classes = null;

class UIHandler extends React.Component {

    constructor(props) {
        super(props)
        classes = this.props.classes;
		// this.handleFieldValidation = this.handleFieldValidation.bind(this)
    }

    // state = {
    //     field: this.props.field
    // };

	handleFieldValidation(e) {
		// let _schedule = this.state.schedule;
		console.log('e.target.name', e.target.name);
		// if (!e.target.name) return;
		// let _field = _schedule[e.target.name];
        let _field = this.props.field;
		// console.log('_field', _field);
		let rules = Object.keys(_field.rules);

        // console.log("e.target.value", e.target.value);
		let _value =  e.target.value;

        _field.value = _value;

        // _field.value = _value;
        // console.log('_field', _field);
		if (rules) {
			for (let rule of rules) {
				if (_field.rules[rule] && rule in validator) {
                    let validatorFn = validator[rule];
                    let checkAgainst = _field.rules[rule];
					let result = validatorFn(_value, checkAgainst);

					if (!result.passed) {
						_field.hasErrors.error = true;
						_field.hasErrors.message = result.message;

						break;
					}
					else {
						_field.hasErrors.error = false;
						_field.hasErrors.message = result.message;

                        //value is valid; let's re-format it
                        // if (_field.type === 'amount' || _field.type === 'percent') {
                        //     _field.value = parseFloat(_value);
                        // } 
                        // else if (_field.type === 'number') {
                        //     _field.value = parseInt(_value);
                        // } 
					}
				}
			}
		}
        this.props.onRefreshed(_field);

		// this.setState({
		// 	schedule: _schedule
		// });
	};


    header() {
        let field = this.props.field;
        return (
			<Grid item md={12} lg={12} xs={12} style={{marginBottom: "5px"}} data-cy="newTranTitle">
				<Typography align="center">
					{field.heading}
				</Typography>
                {field.subheading && 
                    <Typography variant="h5" align="center">
                        {field.subheading}
                    </Typography>
                }
			</Grid>
		)
    }

    textField() {
        let field = this.props.field;
        return (
            <TextField
                name={field.id}
                label={field.alias}
                className={classes.textField}
                margin="normal"
                value={field.value}
                error={field.hasErrors.error}
                required={true}
                onChange={(e) => this.handleFieldValidation(e)}
                variant="outlined" // opt: standard, outlined or filled
                helperText={field.hasErrors.message}
                style={this.props.fieldStyle}

                inputProps={{
                    readOnly: this.props.readOnly,
                }}
                InputProps={{
                    className: (this.props.readOnly) ? 'Mui-disabled' : undefined,
                }}
            />
        )
    }

    dateField() {
        let field = this.props.field;
        return (
            <TextField
                name={field.id}
                label={field.alias || 'missing'}
                className={classes.textField}
                margin="normal"
                type="date"
                value={field.value}
                error={field.hasErrors.error || false}
                required={field.rules.required}
                onChange={(e) => this.handleFieldValidation(e)}
                variant="standard" // opt: standard, outlined or filled
                helperText={field.hasErrors.message}
                style={this.props.fieldStyle}

                inputProps={{
                    readOnly: this.props.readOnly,
                }}
                InputProps={{
                    className: (this.props.readOnly) ? 'Mui-disabled' : undefined,
                }}
            />
        )
    }

    checkboxField() {
        return (
            <span><Checkbox 
                onClick={this.props.onClick}
                checked={this.props.checked}
                readOnly={this.props.readOnly}
                // onChange={(e)=>this.props.onChange(e)}
                />{this.props.field.alias}</span>
        )
    }



    getPlainObject() {
        let { type } = this.props;
        switch (type) {
            case "header":
                return this.header()
            case "date":
                return this.dateField()
            case "checkbox":
                return this.checkboxField()
            default:
                return this.textField()
        }
    }

    render() {
        if (this.props.noGrid) {
            return (
                this.getPlainObject()
            )
        } else {
            return (
                <Grid item md={12} lg={12} xs={12} style={this.props.gridStyle}>
                    {this.getPlainObject()}
                </Grid>
            )
        }
    }
}

UIHandler.propTypes = {
    classes : PropTypes.object,
    type: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    checked: PropTypes.bool,
    onRefreshed: PropTypes.func,
    noGrid: PropTypes.bool,
    gridStyle: PropTypes.object,
    fieldStyle: PropTypes.object,
    readOnly: PropTypes.bool
}

export default connect(mapStateToProps)(withStyles(TranStyle)(UIHandler));