export default class
Navigator {
    
    constructor(callerComponent) {
        this.comp = callerComponent;
    }
    
    showOverlay = () => this.comp.setState({ openOverlay: true });
    hideOverlay = () => this.comp.setState({ openOverlay: false });
    closeModal = () => this.comp.handleCloseModal("");
    reloadToAccounts = () => this.comp.props.history.push('/accounts');

    _actionInProgress = (flag) => {
        let _actionInProgress = {
            disableActionButton: flag,
            openLinearLoading: flag
        };
        this.comp.setState({
            actionInProgress: _actionInProgress
        })
    }
    setActionInProgress = () => this._actionInProgress(true);
    unsetActionInProgress = () => this._actionInProgress(false);
}