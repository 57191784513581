import React from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';

export default class DatePicker extends React.Component {

  componentDidMount() {
    let _props = this.props;
    let _ip = $(this.refs.input);
    _ip.datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: function (datesel) {
        alert(datesel);
        _props.onSetDate(datesel);
      }
    });
  }

  componentWillUnmount() {
    $(this.refs.input).datepicker('destroy');
  }

  render() {
    const props = this.props;
    return <input ref="input" type="date" {...props}
    />
  }
}
DatePicker.propTypes = {
  onSetDate: PropTypes.func.isRequired
}