import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import './AccountInfo.css';
import { AccountStyle } from './AccountStyle';
import mapStateToProps from '../actions/stateToProps';
import mapDispatchToProps from '../actions/dispatchProps';
import Constants from '../utils/constants';
import LoadingOverlay from '../common/loadingOverlay';
import { Button, Typography, Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import NewAccount from './NewAccount';
import AccountCard from './AccountCard';
import WidgetYearlyTrend from '../widgets/YearlyTrend';
import WidgetTranTypeSplits from '../widgets/TranTypeSplits';
import SelectAccount from '../common/selectAccount';
import ReceiptManager from '../common/ReceiptManager';
import SharingManager from './SharingManager';
import { AccountHandler } from '../api';
import NewAccountChitInfo from './NewAccountChitInfo';
import SubscribersManager from './SubscribersManager';
import moment from 'moment-timezone';
import AccountStmtSummary from './AccountStmtSummary';
import AccountStmtSchedule from './AccountStmtSchedule';

let SESSION_KEYS = Constants.SESSION_KEYS;

class AccountInfo extends React.Component {

    constructor(props) {
        super(props);
        this._isMounted = false; // prevents -> Warning: Can't perform a React state update on an unmounted component
        this._accountHandler = new AccountHandler(this);
    }

    state = {
        open: {
            widgetYearlyTrends: false,
            widgetTranTypeSplits: false,
        },
        roles: {
            owner: false,
            admin: false,
            editor: false,
            viewer: false,
            any: false,
        },
        showAccountInfo: true,
        accountInfoReadOnly: true,
        showAccountChitInfo: true,
        accountChitInfoReadOnly: true,
        openOverlay: false,
        accountLineUp: [Constants.DEFAULT_ACCOUNT],
        selectedAccount: Constants.DEFAULT_ACCOUNT,
        currentAction: "info",
        accountReceipts: [],
        termStarted: false,
        displayAccountSchedule: false,
        enableAccountSchedule: false,
        displayAccountSummary: false,
        enableAccountStmtSummary: false
    };

    componentDidMount = () => {
        this.props.changePageTitle('Account Information')

        try {
            const { match: { params } } = this.props;
            if (params.accountId) {
                sessionStorage.setItem(SESSION_KEYS.ACTIVE_ACCOUNT, params.accountId);
            }
        } catch (error) {
            console.log(error)
        }
    };

    // handleGetMembers = async () => {
    //     this.setState({
    //         openOverlay: true
    //     })
    //     let {_id, uid} = this.state.selectedAccount;
    //     this._accountHandler.getUserRoles(_id, uid).then((roles)=>{
    //         this.setState({
    //             roles,
    //             openOverlay: false
    //         })
    //     })
    // }

    _force_reload_account = () => {
        return (
            this._accountHandler.getAccountCached(this.state.selectedAccount._id, true).then((ac)=>{
                // console.log('AccountInfo->renderActionInfoForm->AFTER ac', ac);
                this.setState({ selectedAccount: ac });
                // force-refresh transactions on next visit to transactions page
                sessionStorage.setItem(this.state.selectedAccount._id + '_req_ts', moment().format('YYYYMMDDhhmmss'));
                // window.location.reload();
            })
        )
    }

    handleOpenModal = (modal = "contributor-add") => {
        let showModal = this.state.open;
        showModal.widgetYearlyTrends = false;
        showModal.widgetTranTypeSplits = false;

        this.setState({
            open: showModal,
        })
    }

    handleCloseModal = (modal = "contributor") => {
        let showModal = this.state.open;
        showModal.widgetYearlyTrends = false;
        showModal.widgetTranTypeSplits = false;
        this.setState({
            open: showModal
        });
    };

    renderAccounts() {
        let accountId = this.state.selectedAccount._id;
        return (
            <Grid container id="trans-account-select">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button onClick={() => this.props.history.push('/accounts')}
                        variant="contained"
                        size="medium"
                        style={{ float: "right" }}
                        color="primary">
                        Home
                    </Button>
                    {accountId !== 'unknown' && <Button onClick={() => this.props.history.push(`/transactions/${accountId}`)}
                        variant="contained"
                        size="medium"
                        style={{ float: "right", marginRight: '10px' }}
                        color="primary">
                        Transactions
                    </Button>}
                </Grid>
            </Grid>
        )
    }

    handleAction(currentAction) {
        if (currentAction)
            this.setState({
                currentAction
            })
        // switch (action) {
        //     case "info":
        //         this.setState({ currentAction: "info" })
        //         break;
        //     case "chitinfo":
        //         this.setState({ currentAction: "chitinfo" })
        //         break;
        //     case "sharing":
        //         this.setState({ currentAction: "sharing" })
        //     case "subscribers":
        //         this.setState({ currentAction: "subscribers" })
        //         break;
        //     case "trendYearly":
        //         this.setState({ currentAction: "trendYearly" })
        //         break;
        //     case "trendTranTypes":
        //         this.setState({ currentAction: "trendTranTypes" })
        //         break;
        //     case "receipts":
        //         this.setState({ currentAction: "receipts" })
        //         break;
        //     default:
        //         break;
        // }
    }

    renderActionInfoForm() {
        return (
            <NewAccount
                display={this.state.showAccountInfo}
                onCancel={() => {
                    this.setState({ /*showAccountInfo: false,*/ accountInfoReadOnly: true  });
                    window.location.reload();
                }}
                onSubmit={() => {

                    // console.log('AccountInfo->renderActionInfoForm->BEFORE ac', this.state.selectedAccount);
                    this.setState({ /*showAccountInfo: false, enabledUpdate: false*/accountInfoReadOnly: true });
                    this._force_reload_account();
                }}
                onEnableEdit={() => {
                    this.setState({ /*showAccountInfo: false,*/ accountInfoReadOnly: false });
                }}
                onBusy={(busy) => {
                    this.setState({ openOverlay: busy });
                }}
                onInitChitInfo={()=>{}}
                modeUpdate={true}
                asDialog={false}
                readOnly={this.state.accountInfoReadOnly}
                account={this.state.selectedAccount}
                hideActions={!(this.state.roles.owner || this.state.roles.admin)}
                hideArchive={!this.state.roles.owner}
            />
        )
    }

    renderActionInfoCard() {
        return (
            <AccountCard
                accounts={[this.state.selectedAccount]}
                showEditIcon={false}
                showViewTransIcon={true}
                onEdit={(accountId) => { }}
                onViewTrans={accountId => this.props.history.push(`/transactions/${accountId}`)}
            />
        )
    }

    renderActionInfo() {
        return (
            <div className="row">
                <div className="col-md-6">
                    {this.renderActionInfoForm()}
                </div>
                <div className="col-md-6">
                    {this.renderActionInfoCard()}
                </div>
            </div>
        )
    }

    renderActionChitInfo() {
        return (
            <div className="row">
                <div className="col-md-6">
                    {this.state.termStarted && <span>Note: Chit has been started. No further edits possible.</span>}
                    <NewAccountChitInfo
                        display={this.state.showAccountChitInfo}
                        onCancel={() => {
                            this.setState({ /*showAccountInfo: false,*/ accountChitInfoReadOnly: true });
                        }}
                        onSubmit={() => {
                            this.setState({ /*showAccountInfo: false, enabledUpdate: false*/accountChitInfoReadOnly: true });
                            this._force_reload_account();
                        }}
                        onEnableEdit={() => {
                            this.setState({ /*showAccountInfo: false,*/ accountChitInfoReadOnly: false });
                        }}
                        onBusy={(busy) => {
                            this.setState({ openOverlay: busy });
                        }}
                        modeUpdate={true}
                        asDialog={false}
                        readOnly={this.state.accountChitInfoReadOnly}
                        account={this.state.selectedAccount}
                        hideActions={!(this.state.roles.owner || this.state.roles.admin)}
                        hideArchive={!this.state.roles.owner}
                        termStarted={this.state.termStarted}
                    />
                </div>
            </div>
        )
    }

    renderActionWidgetYearlyTrend() {
        return (
            <WidgetYearlyTrend
                title={this.state.selectedAccount.name} />
        )
    }

    renderActionWidgetTranTypeSplits() {
        return (
            <WidgetTranTypeSplits
                title={this.state.selectedAccount.name} />
        )
    }

    renderModalReceiptManager() {
        return (
            <ReceiptManager
                display={true}
                entity={'ac'}
                entityId={this.state.selectedAccount._id}
                selectedAccount={this.state.selectedAccount}
                onChange={(accountReceipts)=>{
                    this.setState({
                        accountReceipts
                    })
                }}
                roles={this.state.roles} />
        )
    }

    renderModalSharingManager() {
        return (
            <SharingManager
                display={true}
                selectedAccount={this.state.selectedAccount}
                roles={this.state.roles} />
        )
    }

    renderModalSubscriberManager() {
        return (
            <div>
                {/*(!this.state.selectedAccount.subscribers || Object.keys(this.state.selectedAccount.subscribers).length === 0) &&*/
                <div className="row">
                    <div className="col-md-6">
                    {!this.state.termStarted && <Button
                        color="primary"
                        onClick={() => {
                            this._accountHandler.addDummySubscribers(this.state.selectedAccount._id).then(()=>{
                                this._force_reload_account().then(()=>{
                                    window.location.reload();
                                })
                            });
                        }}>
                        Re-publish Subscribers
                    </Button>}
                    {!this.state.termStarted && <Button
                        color="primary"
                        onClick={() => {
                            this._accountHandler.startTheChit(this.state.selectedAccount._id).then(()=>{
                                this._force_reload_account().then(()=>{
                                    window.location.reload();
                                })
                            });
                        }}>
                        Start The chit
                    </Button>}
                    {this.state.termStarted && <span>Note: Chit has been started. To Edit a subscriber name, double click, type and hit Enter.</span>}
                    </div>
                </div>}
                <div className="row">
                    <div className="col-md-6">
                    <SubscribersManager
                        display={true}
                        selectedAccount={this.state.selectedAccount}
                        roles={this.state.roles} />
                    </div>
                </div>
            </div>
        )
    }
    
    renderModalStmtSummary() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-9">
                        <AccountStmtSummary
                            display={this.state.displayAccountSummary}
                            account={this.state.selectedAccount}
                            transactions={[]}
                            onCancel={()=>{
                                this.setState({
                                    enableAccountStmtSummary: false
                                })
                            }}
                            onEnableEdit={()=>{
                                this.setState({
                                    enableAccountStmtSummary: true
                                })
                            }}
                            onSubmit={()=>{}}
                            actionUpdate={true}
                            isCredit={true}
                            roles={this.state.roles} 
                            readOnly={!this.state.enableAccountStmtSummary}
                            />
                    </div>
                </div>
            </div>
        )
    }

    renderModalStmtSchedule() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-9">
                        <AccountStmtSchedule
                            display={this.state.displayAccountSchedule}
                            account={this.state.selectedAccount}
                            onCancel={()=>{
                                this.setState({
                                    enableAccountSchedule: false
                                })
                            }}
                            onEnableEdit={()=>{
                                this.setState({
                                    enableAccountSchedule: true
                                })
                            }}
                            onSubmit={()=>{
                                this.setState({
                                    enableAccountSchedule: false
                                })
                                this._force_reload_account().then(()=>{
                                    window.location.reload();
                                })
                            }}
                            actionUpdate={true}
                            isCredit={true}
                            roles={this.state.roles} 
                            selectedAccount={this.state.selectedAccount}
                            readOnly={!this.state.enableAccountSchedule}
                            />
                    </div>
                </div>
            </div>
        )
    }

    renderAction() {
        if (this.state.selectedAccount.code === "unknown") {
            return <Typography>Please select a valid account.</Typography>
        }
        switch (this.state.currentAction) {
            case "info":
                return this.renderActionInfo()
            case "chitinfo":
                return this.renderActionChitInfo()
            case "sharing":
                return this.renderModalSharingManager()
            case "subscribers":
                return this.renderModalSubscriberManager()
            case "stmt_summary":
                return this.renderModalStmtSummary()
            case "stmt_schedule":
                return this.renderModalStmtSchedule()
            case "trendYearly":
                return this.renderActionWidgetYearlyTrend()
            case "trendTranTypes":
                return this.renderActionWidgetTranTypeSplits()
            case "receipts":
                return this.renderModalReceiptManager()
            default:
                break;
        }
    }

    render() {
        let isChit = this.state.selectedAccount.type === 'CHIT';
        let isCC = this.state.selectedAccount.type === 'CC';
        return (
            <div className="content-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <SelectAccount
                                    onReady={(accounts, ac, roles, trans) => {
                                        let _termStarted = false;
                                        try {
                                            if(ac.chit_info.nextTerm && ac.chit_info.nextTerm > 0) _termStarted = true
                                        } catch (error) {
                                            //
                                        }
                                        this.setState({
                                            accountLineUp: accounts,
                                            selectedAccount: ac,
                                            accountReceipts: ac.receipts,
                                            roles,
                                            termStarted: _termStarted
                                        });
                                        // if (ac._id !== 'unknown') {
                                        //     this.handleGetMembers();
                                        // }
                                    }}
                                    onChange={(accounts, ac, roles)=>{
                                        this.setState({
                                            accountLineUp: accounts,
                                            selectedAccount: ac,
                                            roles
                                        });
                                        if (ac._id !== 'unknown') {
                                            this.props.history.push(`/accounts/ac/${ac._id}`);
                                            window.location.reload();
                                        }
                                    }} />
                                {this.renderAccounts()}
                                <div className="row">
                                    <div className={"col-md-3"}>
                                        <div id="account-info-actions">
                                            <div className="row">
                                                <Button
                                                    color="secondary"
                                                    onClick={() => this.handleAction("info")}
                                                >
                                                    <i className="pe-7s-info" style={{ fontSize: '28px' }}></i> Info
                                                </Button>
                                            </div>
                                            {this.state.selectedAccount.type === 'CHIT' &&
                                            <div className="row">
                                                <Button
                                                    color="secondary"
                                                    onClick={() => this.handleAction("chitinfo")}
                                                >
                                                    <i className="pe-7s-notebook" style={{ fontSize: '28px' }}></i> Chit Info
                                                </Button>
                                            </div>}
                                            {this.state.selectedAccount.type === 'CHIT' &&
                                            <div className="row">
                                                <Button
                                                    color="secondary"
                                                    onClick={() => this.handleAction("subscribers")}
                                                >
                                                    <i className="pe-7s-id" style={{ fontSize: '28px' }}></i> Subscribers
                                                </Button>
                                            </div>}
                                            <div className="row">
                                                <Button
                                                    color="secondary"
                                                    onClick={() => this.handleAction("sharing")}
                                                >
                                                    <i className="pe-7s-users" style={{ fontSize: '28px' }}></i> Sharing
                                                </Button>
                                            </div>
                                            {!isChit && <div className="row">
                                                <Button
                                                    color="secondary"
                                                    onClick={() => this.handleAction("trendYearly")}
                                                >
                                                    <i className="pe-7s-graph2" style={{ fontSize: '28px' }}></i> Yearly Trend
                                                </Button>
                                            </div>}
                                            {!isChit && <div className="row">
                                                <Button
                                                    color="secondary"
                                                    onClick={() => this.handleAction("trendTranTypes")}
                                                >
                                                    <i className="pe-7s-graph" style={{ fontSize: '28px' }}></i> Tran Types Trend
                                                </Button>
                                            </div>}
                                            <div className="row">
                                                <Button
                                                    color="secondary"
                                                    onClick={() => this.handleAction("receipts")}
                                                >
                                                    <i className="pe-7s-print" style={{ fontSize: '28px' }}></i> Receipts 
                                                    {(Array.isArray(this.state.accountReceipts) && this.state.accountReceipts.length > 0) ?
                                                    ` (${this.state.accountReceipts.length})` : ``}
                                                </Button>
                                            </div>
                                            {false && isCC && <div className="row">
                                                <Button
                                                    color="secondary"
                                                    onClick={() => this.handleAction("stmt_summary")}
                                                >
                                                    <i className="pe-7s-print" style={{ fontSize: '28px' }}></i> Statement Summary 
                                                </Button>
                                            </div>}
                                            {isCC && <div className="row">
                                                <Button
                                                    color="secondary"
                                                    onClick={() => this.handleAction("stmt_schedule")}
                                                >
                                                    <i className="pe-7s-print" style={{ fontSize: '28px' }}></i> Statement Schedule 
                                                </Button>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        {this.renderAction()}
                                    </div>
                                </div>
                                {this.state.openOverlay && <LoadingOverlay />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AccountInfo.propTypes = {
    classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withStyles(AccountStyle)(AccountInfo)));
