import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep as _cloneDeep } from 'lodash';
import { withSnackbar } from 'notistack';

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, LinearProgress, Dialog } from '@material-ui/core';

import '../tran/Tran.css'
import {TranStyle} from '../tran/TranStyle';
import { accountCCStmtSummaryObject, mapAccount } from './acctObject';
import mapStateToProps from '../actions/stateToProps';
import UIHandler from '../utils/UIHandler';

let classes = null;

class AccountStmtSummary extends Component {
	constructor(props) {
		super(props)
		classes = this.props.classes;
	}

	state = {
		actionInProgress      : {
			openLinearLoading   : false,
			disableActionButton : false
		},
		sqlErrorMessage: '',
		account: mapAccount(this.props.account),
		summary: _cloneDeep(accountCCStmtSummaryObject)
	};

	preparePayload = (_data = null) => {
		let _payload = {};
		let _keys = Object.keys(_data);
		for (let key of _keys) {
			if (key !== '_id') {
				_payload[key] = _data[key].value;
			}
		}
		return _payload;
	};


	handleCreateTransaction = async () => {
		// let _account = this.state.account;
		// let _transaction = this.state.transaction;
		/* TODO: make this error disable the submit button in 'FieldsErrorFree' logic similar to new list item. */
		if (this.props.account.code === 'unknown') {
			this.props.enqueueSnackbar('Selected Account is invalid. Transaction cannot be added.', {
				variant : 'error'
			});
			return;
		}
		let _actionInProgress = this.state.actionInProgress;
		_actionInProgress.disableActionButton = true;
		_actionInProgress.openLinearLoading = true;
		this.setState({
			actionInProgress : _actionInProgress
		});
		
		// let preparedReleaseObject = this.preparePayload(_account);
        // let responseErr = '';
        // let isAfter = false;
        // try {
        //     isAfter = moment(this.state.freqencyEndDate).isAfter(this.state.transaction.date);
        // } catch (error) {
        //     console.error(error);
        // }
        // if (this.state.repeatFlag && isAfter) {
        //     //console.log('valid frequency end date found')
        //     preparedReleaseObject.repeat = "Y";
        //     preparedReleaseObject.schedule = [];
        //     let current = this.state.transaction.date;
        //     let max = 24;
        //     let index = 0;
        //     do {
        //         index++;
        //         let next = moment(current).add(1, this.state.frequencyInc).format("YYYY-MM-DD");
		// 		//console.log(`next ${next} current ${current}`)
        //         preparedReleaseObject.schedule.push(next);
        //         isAfter = moment(this.state.freqencyEndDate).isAfter(next);
		// 		//console.log(`isAfter ${isAfter}`)
        //         current = next;
		// 		//console.log(`current: ${current}`)
        //     } while (isAfter && index < max);
        //     if (isAfter) {
        //         this.props.enqueueSnackbar(`Max ${max} transactions can be added at once.`, {
		// 		    variant : 'info'
		// 	    });
        //     }
        //     //console.log("preparedReleaseObject.schedule: ", JSON.stringify(preparedReleaseObject.schedule));
        // } else {
        //     //console.log('invalid frequency end date found')
        // }

		// await getInstance()
		// 	.post(`${Constants.URLs.ACCOUNTS}/${_account._id}/schedule`, preparedReleaseObject)
		// 	.then(async (response) => {
		// 		if (response.status === 400) {
		// 			console.log(response)
		// 			this.props.enqueueSnackbar('Error: An internal error occured while saving transaction.', {
		// 				variant : 'error'
		// 			});
		// 			responseErr = response.data.message
		// 		}
		// 		else {
		// 			this.props.enqueueSnackbar('Transaction created successfully', {
		// 				variant : 'success'
		// 			});
		// 			// _transaction = _cloneDeep(tranObject)
		// 			// _account = _cloneDeep(acctObject)
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);
        //         responseErr = error.message;
        //         let msg = 'An error occurred from the server';
        //         try {
        //             msg = error.response.data.message
        //         } catch (error) {}

		// 		this.props.enqueueSnackbar(msg, {
		// 			variant : 'error'
		// 		});
		// 	})
		// 	.finally(()=>{
		// 		_actionInProgress.disableActionButton = false;
		// 		_actionInProgress.openLinearLoading = false;
		// 		this.setState({
		// 			sqlErrorMessage  : responseErr,
		// 			// transaction: _transaction,
		// 			// account: _account,
		// 			actionInProgress : _actionInProgress
		// 		});
		// 		if (responseErr === '') {
		// 			this.props.onSubmit();
		// 		}
		// 	})
	};

	handleUpdateTransaction = async () => {
		// this.props.enqueueSnackbar('This action is not ready yet', {
		// 	variant : 'warning'
		// });
		// return;
		// let _account = this.state.account;
		// let _transaction = this.state.transaction;
		/* TODO: make this error disable the submit button in 'FieldsErrorFree' logic similar to new list item. */
		if (this.props.account.code === 'unknown') {
			this.props.enqueueSnackbar('Selected Account is invalid. Transaction cannot be added.', {
				variant : 'error'
			});
			return;
		}
		// let _actionInProgress = this.state.actionInProgress;
		// _actionInProgress.disableActionButton = true;
		// _actionInProgress.openLinearLoading = true;
		// this.setState({
		// 	actionInProgress : _actionInProgress
		// });

		let _summary = this.state.summary;
		
		let preparedPayload = this.preparePayload(_summary);
		console.log('preparedPayload', preparedPayload);
		return;
		//inject chit_info
		// preparedPayload.chit_info = this.state.tranChitInfo;
		// // console.log('preparedReleaseObject', preparedReleaseObject)
		// let responseErr = '';
		// await getInstance()
		// 	.patch(`${Constants.URLs.ACCOUNTS}/${_account._id}/schedule`, preparedPayload)
		// 	.then(async (response) => {
		// 		if (response.status === 400) {
		// 			console.log(response)
		// 			this.props.enqueueSnackbar('Error: An internal error occured while saving transaction.', {
		// 				variant : 'error'
		// 			});
		// 			responseErr = response.data.message
		// 		}
		// 		else {
		// 			this.props.enqueueSnackbar('Transaction updated successfully', {
		// 				variant : 'success'
		// 			});
		// 			// _transaction = _cloneDeep(tranObject)
		// 			// _account = _cloneDeep(acctObject)
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);
        //         let msg = 'An error occurred from the server';
        //         try {
        //             if (error.response.data.message) msg = error.response.data.message
        //         } catch (error) {}
        //         responseErr = msg;
		// 		this.props.enqueueSnackbar(msg, {
		// 			variant : 'error'
		// 		});
		// 	})
		// 	.finally(()=>{
		// 		_actionInProgress.disableActionButton = false;
		// 		_actionInProgress.openLinearLoading = false;
		// 		this.setState({
		// 			sqlErrorMessage  : responseErr,
		// 			// transaction: _transaction,
		// 			// account: _account,
		// 			actionInProgress : _actionInProgress
		// 		});
		// 		if (responseErr === '') {
		// 			this.props.onSubmit();
		// 		}
		// 	})
	}

	/**
	 * Render UI components
	 */

	renderFormTitle() {
		return (
			<UIHandler
				type={"header"}
				field={{
					heading: this.props.account.name,
					subheading: "Statement Dashboard"
				}}
				/>
		)
	}

	renderSQLError() {
		return (
			<Grid item md={12} lg={12} xs={12}>
				<Typography 
					variant="caption" 
					align="center" 
					className={classes.sqlErrorMessage}>
					{this.state.sqlErrorMessage}
				</Typography>
			</Grid>
		)
	}

	renderActions() {
		return (
			<div className={classes.newAccountModalActions}>
				{!this.props.readOnly && <Button
					variant="contained"
                    color="primary"
                    data-cy="btnTranCancel"
					className={classes.button}
					onClick={() => this.props.onCancel()}>
					Cancel
				</Button>}
				{this.props.readOnly && this.props.actionUpdate && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableActionButton}
					onClick={()=>this.props.onEnableEdit()}>
					Edit
				</Button>}
				{!this.props.readOnly && this.props.actionUpdate && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableActionButton}
					onClick={this.handleUpdateTransaction}>
					Update
				</Button>}
			</div>
		)
	}

	renderLinearLoader() {
		return (
			this.state.actionInProgress.openLinearLoading &&
				<LinearProgress color="primary" variant="query" />
		)
	}

	genericField(field, type="text") {
		return (
			<UIHandler 
				field={field} 
				noGrid={true} 
				fieldStyle={{margin: '10px'}} 
				type={type} 
				onRefreshed={(_field)=>{
					let _summary = this.state.summary;
					_summary[field.id] = _field;
					this.setState({
						summary: _summary
					})
				}}
				readOnly={this.props.readOnly} />
		)
	}

	renderAsModal() {
		let {
			limit,
			available,
			last_statement_date,
			last_statement_due,
			last_statement_min,
			last_statement_bal,
			last_payment_date,
			last_payment_amnt
		} = this.state.summary;
		return (
			<div>
				<Grid container className={classes.newAccountModalActions} data-cy="mdlCreateNewTranGrid">
					{this.renderFormTitle()}
					{/* {this.renderFormFieldType()} */}
					<Grid item  md={12} lg={12} xs={12}>
						{this.genericField(limit)}
						{this.genericField(available)}
						</Grid>
					<Grid item  md={12} lg={12} xs={12}>
						{this.genericField(last_statement_date, "date")}
						{this.genericField(last_statement_due, "date")}
						{this.genericField(last_statement_min)}
						{this.genericField(last_statement_bal)}
						</Grid>
					<Grid item  md={12} lg={12} xs={12}>
						{this.genericField(last_payment_date, "date")}
						{this.genericField(last_payment_amnt)}
						</Grid>
					{this.renderSQLError()}
					{this.renderActions()}
					{this.renderLinearLoader()}
				</Grid>
			</div>
		);
	}

	render() {
		if (this.props.asDialog) {
			return (
				<Dialog
					open={this.props.display}
					onClose={() => {}}
					styles={{ modal: { padding: 0 } }} >
						{this.renderAsModal()}
				</Dialog>
			)
		}
		return this.renderAsModal();
	}
}

AccountStmtSummary.propTypes = {
	classes : PropTypes.object.isRequired,
    display: PropTypes.bool.isRequired,
	asDialog: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	account: PropTypes.object.isRequired,
	isCredit: PropTypes.bool.isRequired,
	//Needed for update transaction
	actionUpdate: PropTypes.bool.isRequired,
	// transaction: PropTypes.object,
	multiMode: PropTypes.bool,
	transactions: PropTypes.array.isRequired,
	readOnly: PropTypes.bool,
	onEnableEdit: PropTypes.func
};

export default connect(mapStateToProps)(withSnackbar(withStyles(TranStyle)(AccountStmtSummary)));
