import { getSession } from "../components/utils/Session";

// let initial = getSession() || '';

const authTokenReducer = (state = (getSession() || ''), action) => {
  switch (action.type) {
    case 'SET_SESSION': {
      return action.data;
    }
    default:
      return state;
  }
};

export default authTokenReducer;
