import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, TextField, LinearProgress, Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import {AccountStyle} from './AccountStyle';
import { cloneDeep } from 'lodash';
import {validator} from '../utils/validator';
import { acctObject, acctChitObject, mapAccountChit } from './acctObject';
import { getInstance } from '../utils/axiosLoader';
import Constants from '../utils/constants';
import mapStateToProps from '../actions/stateToProps';
import { AccountHandler } from '../api';

class NewAccountChitInfo extends Component {
    constructor(props) {
      super(props)
      this._handler = new AccountHandler(this);
    }
  
    state = {
		currencies: [],
		accountTypes: ['DEF'],
		sqlErrorMessage: '',
		actionInProgress      : {
			openLinearLoading   : false,
			disableActionButton : false
		},
		account : this.props.account,
        accountChit: mapAccountChit(this.props.account.chit_info),
        accountArchiveConfirmation: false,
        page : 0
    };

    handleAllChitAccountFieldValidation = () => {
		let _account = this.state.accountChit;
        let allPassed = true;
        Object.keys(_account).forEach((name)=>{
            let _field = _account[name];
            let rules = Object.keys(_field.rules);
    
            //_field.value = _field.value;
    
            if (rules) {
                for (let rule of rules) {
                    if (_field.rules[rule] && rule in validator) {
                        let validation = validator[rule](_field.value, _field.rules[rule]);
    
                        if (!validation.passed) {
                            _field.hasErrors.error = true;
                            _field.hasErrors.message = validation.message;
                            allPassed = false;
    
                            break;
                        }
                        else {
                            _field.hasErrors.error = false;
                            _field.hasErrors.message = validation.message;
                        }
                    }
                }
            }
        })

		this.setState({
			accountChit : _account,
		});

        if (allPassed) {
            this.handleCreateAccount();
        }
    }

    handleChitAccountFieldValidation  = (e) => {
		let _account = this.state.accountChit;
		let _field = _account[e.target.name];
		let rules = Object.keys(_field.rules);

		_field.value = e.target.value;

		if (rules) {
			for (let rule of rules) {
				if (_field.rules[rule] && rule in validator) {
					let validation = validator[rule](_field.value, _field.rules[rule]);

					if (!validation.passed) {
						_field.hasErrors.error = true;
						_field.hasErrors.message = validation.message;

						break;
					}
					else {
						_field.hasErrors.error = false;
						_field.hasErrors.message = validation.message;
					}
				}
			}
		}

		this.setState({
			accountChit : _account
		});

    }
    
    handlePrevPage = async () => {
        this.setState({
            page: 0
        })
    }

	handleCreateAccount = async () => {
		let _account = this.state.account;
        let _accountChit = this.state.accountChit;
		this._handler.nav.setActionInProgress();
		
		let preparedReleaseObject = this._handler.prepareObjectForSaving(_account);
        preparedReleaseObject['chit_info'] = this._handler.prepareObjectForSaving(_accountChit);
        console.log(`preparedReleaseObject: `, preparedReleaseObject);
		let responseErr = '';
		await getInstance()
			.post(`${Constants.URLs.ACCOUNTS}`, preparedReleaseObject)
			.then(async (response) => {
                this.props.enqueueSnackbar('Account created successfully', {
                    variant : 'success'
                });
                _account = cloneDeep(acctObject)
                _accountChit = cloneDeep(acctChitObject)
			})
			.catch((error) => {
                console.log(error.response);
                if (error.response.data && error.response.data.message.startsWith('E11000 duplicate key error')) {
                    this.props.enqueueSnackbar('Error: Account Code already exists. Please fix and try again.', {
                        variant : 'error'
                    });
                    responseErr = 'Account code should be unique.';
                } else {
                    responseErr = error.message;
                    this.props.enqueueSnackbar('An error occurred from the server', {
                        variant : 'error'
                    });
                }
			})
			.finally(()=>{
                this._handler.nav.unsetActionInProgress();
				this.setState({
					sqlErrorMessage  : responseErr,
					account: _account,
                    accountChit: _accountChit,
				});
				if (responseErr === '') {
					this.props.onSubmit();
				}
			})
	};

    handleUpdateAccount = async () => {
        let _id = this.props.account._id;
        if (!_id) {
            this.props.enqueueSnackbar('Account Id missing to update', {
                variant : 'warning'
            });
            return;
        }
        let chit_info = this._handler.prepareObjectForSaving(this.state.accountChit);
        let payloadAccount = {
            chit_info
        }
        this._handler.nav.setActionInProgress();
        await getInstance()
            .patch(`${Constants.URLs.ACCOUNTS}/` + _id, payloadAccount)
            .then((response) => {
                if (response.status === 400) {
                    console.log(response)
                    this.props.enqueueSnackbar('Error: An internal error occured while saving account..', {
                        variant : 'error'
                    });
                    this.setState({
                        sqlErrorMessage  : response.data.message,
                    });
                    // this.props.onSubmit();
                }
                else {
                    this.props.enqueueSnackbar('Account updated successfully', {
                        variant : 'success'
                    });
                    // this.setState({ 
                    //     openOverlay: true, 
                    // });
                    // this.props.onSubmit();
                }
            })
            .catch((error) => {
                console.log(error);
                this.props.enqueueSnackbar('Error: Could not update the account.', {
                    variant : 'error'
                });
            })
            .finally(async ()=>{
                this._handler.nav.unsetActionInProgress();
                
                this.props.onSubmit();
            })
    };

    getTextField(fieldName) {
        const {classes} = this.props;
        let field = this.state.accountChit[fieldName];
        if (!field) return;
        let {alias, value, hasErrors, type} = field;
        return (
            <TextField
                name={fieldName}
                label={alias}
                className={classes.textField}
                value={value ? value : "text"}
                type={type ? type : 'text'}
                required={true}
                onChange={this.handleChitAccountFieldValidation}
                variant="standard" // opt: standard, outlined or filled
                helperText={hasErrors.message}
                inputProps={{
                    readOnly: this.props.readOnly,
                }}
                InputProps={{
                    className: (this.props.readOnly) ? 'Mui-disabled' : undefined,
                }}
            />
        )
    }

    renderFields() {
        return (
            <Grid item md={12} lg={12} xs={12}>
                {this.getTextField('chit_amount')}
                {this.getTextField('chit_tenure')}
                {this.getTextField('chit_subscribers')}
                {this.getTextField('chit_term_amount')}
                {this.getTextField('chit_start_on')}
                {this.getTextField('chit_term_fee')}
                {this.getTextField('chit_draw_date')}
                {this.getTextField('chit_due')}
            </Grid>
        )
    }
    
    renderActions() {
        const {classes} = this.props;
        return (
            <Grid item md={12} lg={12} xs={12}>
                <div className={classes.newReleaseModalActions} data-cy="mdlCreateNewReleaseActions">
                    {!this.props.hideActions && !this.props.modeUpdate && <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        data-cy="mdlCreateNewAccountActionCancel"
                        onClick={() => this.handlePrevPage()}>
                        Back
                    </Button>}
                    {!this.props.hideActions && this.props.readOnly && !this.props.account.chit_info.nextTerm && <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        data-cy="mdlCreateNewAccountActionCancel"
                        onClick={() => this.props.onEnableEdit()}>
                        EDIT
                    </Button>}
                    {!this.props.hideActions && !this.props.readOnly && <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        data-cy="mdlCreateNewAccountActionCancel"
                        onClick={() => this.props.onCancel()}>
                        Cancel
                    </Button>}
                    {!this.props.hideActions && !this.props.readOnly && !this.props.modeUpdate && <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        data-cy="mdlCreateNewAccountActionSubmit"
                        disabled={this.state.actionInProgress.disableActionButton}
                        onClick={this.handleAllChitAccountFieldValidation}>
                        Submit
                    </Button>}
                    {!this.props.hideActions && !this.props.readOnly && this.props.modeUpdate && <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        data-cy="mdlCreateNewAccountActionSubmit"
                        disabled={this.state.actionInProgress.disableActionButton}
                        onClick={this.handleUpdateAccount}>
                        Update
                    </Button>}
                </div>
            </Grid>
        )
    }

    renderForm() {
        const {classes} = this.props;
        return (
            <Grid container className={classes.newReleaseModal} data-cy="mdlCreateNewAccountGrid">
                <Grid item md={12} lg={12} xs={12}>
                    <Typography variant="h6" align="center">
                        {this.props.modeUpdate ? '' : 'Chitfund Setup'}
                    </Typography>
                </Grid>

                {this.renderFields()}

                <Grid item md={12} lg={12} xs={12}>
                    <Typography variant="caption" align="center" className={classes.sqlErrorMessage}>
                        {this.state.sqlErrorMessage}
                    </Typography>
                </Grid>

                {this.renderActions()}
                
                {this.state.actionInProgress.openLinearLoading && (
                    <LinearProgress color="secondary" variant="query" />
                )}
            </Grid>
        )
    }

    renderAsGrid() {
        return (
            <div>
                {this.props.display && this.renderForm()}
            </div>
        )
    }

    renderAsDialog() {
        return (
            <Dialog
                open={this.props.display}
                // center
                // showCloseIcon={false}
                // closeOnOverlayClick={false}
                // closeOnEsc={false}
                onClose={() => {}}
                // styles={{ modal: { padding: 0, height } }}
                style={{margin: '10px'}}
                >
                {this.renderForm()}
            </Dialog>
        )
    }

    render() {
        if (this.props.asDialog) {
            return this.renderAsDialog();
        } 
        return this.renderAsGrid();
    }
}

NewAccountChitInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  display: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  modeUpdate:  PropTypes.bool.isRequired,
  onBusy: PropTypes.func,
  account: PropTypes.object,
  asDialog: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  onEnableEdit: PropTypes.func,
  hideActions: PropTypes.bool,
  hideArchive: PropTypes.bool,
  termStarted: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(withSnackbar(withStyles(AccountStyle)(NewAccountChitInfo)));
  