import React from "react";
import PropTypes from 'prop-types';
import './AccountCard.css';
import './AccountCardMobile.css';
import Constants from "../utils/constants";
import ListItemHandler from "../api/ListItemHandler";

class AccountCard extends React.Component {

    constructor(props) {
      super(props)
      this._handlerListItem = new ListItemHandler(this);
    }
    
	state = {
		typeMap: []
    };
    
    componentDidMount = () => {
        this.handleGetAcctTypes();
    }

    handleGetAcctTypes = async () => {
        let typeMap = [];
        this._handlerListItem.getAcctTypes()
		.then((data) => {
            // console.log(data);
            data.forEach((type, index) => {
                typeMap[type.code] = type.name;
            });
            this.setState({ typeMap });
		})
		.catch((err) => {
			console.log('error while fetching account types:' + err.message);
        });
    }
    
    render() {
        let _this = this;
        //console.log('accounts: ', this.props.accounts);
        return (
            <ul className={`cardGrid ${_this.props.zoomIn  ? 'zoomin' : ''}`}>
                {this.props.accounts.map(function (account, index) {
                    let icon = <img src="/assets/img/accounts/icons/DEF.png" alt="Default Account" width={100} />;
                    if (["CC","CHQ","DEF","FRN","SAV", "BILL", "INV", "CHIT", "SUB"].includes(account.type)) 
                        icon = <img src={`/assets/img/accounts/icons/${account.type}.png`} alt={account.type} width={100} />;
                    // let bal = account.curr + ' ' + Number(account.bal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                    let locale = account.curr === 'INR' ? 'en-IN' : 'en-US';
                    let bal = Number(account.bal);
                    let creditBal = '';
                    if (account.type === 'CC') {
                        if (account.bal <= 0) {
                            creditBal = 'credit-bal';
                            if (account.bal < 0) bal *= -1;
                        }
                    }
                    bal = bal.toLocaleString(locale, { style: 'currency', currency: account.curr });
                    if (creditBal === 'credit-bal' && account.bal < 0) {
                        bal += ' CR'
                    }
                    let updatedAt = '';
                    if (account.updated_at) updatedAt = account.updated_at;
                    let zoomInClass = '';
                    if (_this.props.zoomIn) {
                        zoomInClass = (!account.isOwner ? 'shared-account-card' : 'owner-account-card');
                    }
                    zoomInClass += (account.status === 'ac') ? ' active-account' : ' archive-account'
                    return (
                        // <a key={account._id} href={`/transactions/${account._id}`}>
                            <li key={account._id} className={`${account.type} card ${zoomInClass}`}>
                                {icon}
                                {/* account.type !== 'CC' &&   */
                                <span className="code">{account.code}</span> }
                                {!account.isOwner && <span className="shared-icon"><i className="pe-7s-users" style={{fontSize: 34}}></i></span>}
                                {/* <span className="action-edit" onClick={()=>_this.setState({ showUpdateModal: true, rowsSelected: account })}> */}
                                
                                {_this.props.showEditIcon && account.status === 'ac' && <span className="action-edit" onClick={()=>_this.props.onEdit(account._id)}>
                                    <i className="pe-7s-pen" style={{fontSize: 34}}></i>
                                </span>}
                                {_this.props.showViewTransIcon && account.status === 'ac' && <span className="action-view" onClick={()=>_this.props.onViewTrans(account._id)}>
                                    <i className="pe-7s-cash" style={{fontSize: 50}}></i>
                                </span>}
                                {account.status === 'cl' && 
                                    <span className="action-view" onClick={()=>{
                                        if (_this.props.onUnlock) _this.props.onUnlock(account._id)
                                    }}>
                                    <i className="pe-7s-unlock" style={{fontSize: 50}}></i>
                                </span>}
                                <span className="name">{Constants.getAccountName(account)}</span>
                                {account.desc && 
                                    <span className="desc">{account.desc}</span> }
                                <span className="type">{_this.state.typeMap[account.type]}</span>
                                <span className={`bal ${creditBal}`}>{bal}</span>
                                {/*TODO: convert timezones to client based*/}
                                {updatedAt && 
                                    <span className="updated-at">updated: {Constants.timeAgo(updatedAt)}</span>}
                            </li>
                        // </a>
                    )
                })}
            </ul>
        )
    }
}

AccountCard.propTypes = {
    accounts: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    onViewTrans: PropTypes.func.isRequired,
    onUnlock: PropTypes.func,
    showEditIcon: PropTypes.bool,
    showViewTransIcon: PropTypes.bool,
    zoomIn: PropTypes.bool
}

export default AccountCard;