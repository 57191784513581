import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Forgot from './components/auth/Forgot';
import ResetPassword from './components/auth/ResetPassword';
import './App.css';

export default class Auth extends Component {
  render() {
    return (
      <div>
        <Route exact path={`${this.props.match.path}`} component={Login} />
        <Route exact path={`${this.props.match.path}/login`} component={Login} />
        <Route exact path={`${this.props.match.path}/login/:email`} component={Login} />
        <Route exact path={`${this.props.match.path}/forgotpwd`} component={Forgot} />
        <Route exact path={`${this.props.match.path}/forgotpwd/:email`} component={Forgot} />
        <Route exact path={`${this.props.match.path}/reset`} component={ResetPassword} />
        <Route exact path={`${this.props.match.path}/register`} component={Register} />
      </div>
    );
  }
}