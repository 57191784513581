// import { PAGE_TITLE } from '../common/utils/constants';
const pageTitleReducer = (state = 'HOME PAGE', action) => {
  switch (action.type) {
    case 'SET_PAGE_TITLE': {
      return action.data;
    }
    default:
      return state;
  }
};

export default pageTitleReducer;
