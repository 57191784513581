import React from "react";
import PropTypes from 'prop-types'
import { useCanvasPDF } from './hooks/useCanvasPDF';
import moment from 'moment';
import './canvasPDF.css';

function CanvasPDF({
  label="Statement Layout", 
  data=[{ 
    x1: 124, 
    y1: 164,
    w: 200,
    h: 40,
    str: 'NO data received',
    color: 'blue'
  }], 
  onDataChange
}) {
  const [ coordinates, setMyCoordinates, canvasRef, canvasWidth, canvasHeight ] = useCanvasPDF();
  
  const handleCanvasClick=(event)=>{
    let elem = canvasRef.current;
    let  elemLeft = elem.offsetLeft + elem.clientLeft;
    let elemTop = elem.offsetTop + elem.clientTop;
    // console.log('elemLeft, elemTop', elemLeft, elemTop);
    // on each click get current mouse location 
    // const currentCoord = { 
    //   x1: event.clientX-124, 
    //   y1: event.clientY-164,
    //   w: 200,
    //   h: 40,
    //   str: label
    // };
    var x = event.pageX - elemLeft - 95,
        y = event.pageY - elemTop - 345;
    // console.log('x, y', x, y);
    // Collision detection between clicked offset and element.
    let line = 0;
    let selection = false;
    coordinates.forEach(function(element) {
        if (element.w > 800 && y > element.y1-6 && y < element.y1 + element.h + 6
            && x > element.x1 && x < element.x1 + element.w) {

            selection = element.selected;
            selection = !selection;
            element.selected = selection;
            line = element.line;
            // if (element.str.toString().endsWith('^')) {
            //   element.str = element.str.substr(0, element.str.length - 1);  
            // } else {
            //   element.str += '^';
            // }
            // console.log(element.str);
        }
    });
    coordinates.forEach(function(element) {
      if (element.line === line) {
        element.selected = selection;
      }
    });
    setMyCoordinates([...coordinates]);
    sendBackData();
  };

  const handleClearCanvas=(event)=>{
    setMyCoordinates([]);
  };

  const handleDrawCanvas=(event)=>{
    setMyCoordinates(data);
    sendBackData(data);
  }

  const sendBackData =(data=coordinates)=>{
    let selectedRows = [];
    let bayBloorDate = null;
    let bayBloorDesc = 'The Bay';
    data.forEach(function(element) {
      if (element.selected && element.w === 820) {
        if (element.parts[2] && element.parts[2].startsWith("The Bay")) {
          // remember the transaction date
          bayBloorDate = element.parts[1];
          bayBloorDesc = element.parts[2];
        } else if (element.parts[0] && element.parts[0].includes("Transaction Total")) {
          bayBloorDate = null;
          bayBloorDesc = 'The Bay'
        } else {
          let out = {
            date: moment().format('YYYY-MM-DDThh:mm:ss'),
            dr: 0,
            cr: 0,
            // curr: this.props.account.curr,
            type: 'unknown',
            desc: '',
            note: '',
            // account: this.props.account._id
          };
          let type = 'unknown';
          let amnt = 0; 
          let datePosted = (bayBloorDate) ? bayBloorDate : element.parts[1];
          if (datePosted) datePosted = datePosted.trim();
          let date = '';
          try {
            date = moment(datePosted, 'MM/DD/YY').format('YYYY-MM-DD');  
          } catch (error) {}                  
          finally {
            if (date === 'Invalid date') {
              date = moment().format('YYYY-MM-DD');
            }
          }
          out.date = date;
          
          let desc = bayBloorDate ? bayBloorDesc + ' - ' + element.parts[0] : element.parts[2];
          if (desc) desc = desc.trim();
          out.desc = desc;
  
          if ("PAYMENT - THANK YOU" === desc) {
            amnt = element.parts[3];
            if (amnt) amnt = amnt.trim().replace('$', '').replace(',', '').replace('-', '');
            out.type = 'payment';
            out.cr = Number(amnt);
          } else if ("OVER LIMIT FEE" === desc || "INTEREST CHARGE ON PURCHASES" === desc) {
            amnt = element.parts[3];
            if (amnt) amnt = amnt.trim().replace('$', '').replace(',', '').replace('-', '');
            out.type = 'fee';
            out.dr = Number(amnt);
          } else if (desc !== '' && element.parts[3] && element.parts[3].startsWith('-$')) {
            amnt = element.parts[3];
            if (amnt) amnt = amnt.trim().replace('$', '').replace(',', '').replace('-', '');
            out.type = 'refund';
            out.cr = Number(amnt);
          } else {
            amnt = bayBloorDate ? element.parts[2] : element.parts[4];
            if (amnt) amnt = amnt.trim().replace('$', '').replace(',', '');
            type = bayBloorDate ? element.parts[1] :  element.parts[3];
            if (type && type.includes('Purchase')) {
              out.type = 'purchase';
              out.dr = Number(amnt);
            }
          }
          
          selectedRows.push(out);
        }
      }
    })
    // add the newest mouse location to an array in state 
    onDataChange(selectedRows);
  }

  return (
    <main className="App-main" >
      <div className="button" >
        <button id="pdf-canvas-draw"
        onClick={handleDrawCanvas} > DRAW </button> <button onClick={handleClearCanvas} > CLEAR </button>
      </div>
      <canvas 
        className="App-canvas"
        ref={canvasRef}
        width={canvasWidth}
        height={canvasHeight}
        onClick={handleCanvasClick} />
    </main>
  );
}

CanvasPDF.prototype = {
  label: PropTypes.string,
  data: PropTypes.array,
  onDataChange: PropTypes.object,
  gridRows: PropTypes.array
}

export default CanvasPDF;