import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import './AccountInfo.css';
import { AccountStyle } from './AccountStyle';
import mapStateToProps from '../actions/stateToProps';
import mapDispatchToProps from '../actions/dispatchProps';
import LoadingOverlay from '../common/loadingOverlay';
import { Button, Typography, Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AccountHandler } from '../api';
import Constants from '../utils/constants';

class SubscribersManager extends React.Component {

  constructor(props) {
    super(props);
    this._isMounted = false; // prevents -> Warning: Can't perform a React state update on an unmounted component
    this._accountHandler = new AccountHandler(this);
  }

  state = {
    open: {
      openNewSubscriber: false,
      // subscriberDeleteConfirmation: false,
    },
    roles: this.props.roles,
    openOverlay: false,
    selectedAccount: this.props.selectedAccount,
    subscribers: [],
    enableAssignSubscriber: false,
    enableEditSubscriber: false,
    // enableDeleteSubscriber: false,
    subscriberEditMode: false,
    subscriberRowsSelected: [],
  };

  componentDidMount() {
    this.handleGetSubscribers();
  }

  handleGetSubscribers = async () => {
    this.setState({
      openOverlay: true
    })
    let { _id } = this.state.selectedAccount;
    this._accountHandler.getAccountSubscribers(_id).then((subscribers) => {
      this.setState({
        subscribers,
        openOverlay: false,
        enableAssignSubscriber: this.state.roles.owner || this.state.roles.admin,
        // enableDeleteSubscriber: false,
        enableEditSubscriber: false,
      })
    })
  }

  handleDeleteSubscriber = async () => {
    let subscriber = this.state.subscriberRowsSelected[0];
    this.setState({ openOverlay: true });
    this._accountHandler.deleteAccountMember(this.state.selectedAccount._id, subscriber.uid).then(()=>{
      this.setState({
        openOverlay: false,
      });
      this.handleCloseModal("subscriber");
    })
  };

  handleOpenModal = (modal = "subscriber-add") => {
    let showModal = this.state.open;
    // showModal.subscriberDeleteConfirmation = (modal === "subscriber-delete");
    showModal.openNewSubscriber = (modal === "subscriber-assign" || modal === "subscriber-edit");

    this.setState({
      open: showModal,
      subscriberEditMode: (modal === "subscriber-edit")
    })
  }

  handleCloseModal = (modal = "subscriber") => {
    let showModal = this.state.open;
    // showModal.subscriberDeleteConfirmation = false;
    showModal.openNewSubscriber = false;
    this.setState({
      open: showModal
    });
    if (modal === "subscriber") {
      this.handleGetSubscribers()
    }
  };

  onSharingSelectionChanged() {
    let selectedRows = this.sharingGridApi.getSelectedRows();
    this.setState({
      subscriberRowsSelected: selectedRows,
      enableAssignSubscriber: (this.state.roles.owner || this.state.roles.admin),
      // enableDeleteSubscriber: (selectedRows.length === 1 && (this.state.roles.owner || this.state.roles.admin)),
      // enableEditSubscriber: (selectedRows.length === 1 && (this.state.roles.owner || this.state.roles.admin))
    })
  }

  handleOnSharingGridReady = params => {
    this.sharingGridApi = params.api;
    this.sharingGridColumnApi = params.columnApi;
    this.sharingGridApi.sizeColumnsToFit();
  };

  // renderSubscriberModal() {
  //   let subscriber = this.state.subscriberRowsSelected[0];
  //   // let subscriber = Constants.mapMemberToSubscriber(subscriber);
  //   return (
  //     <NewSubscriber
  //       display={this.state.open.openNewSubscriber}
  //       account={this.state.selectedAccount}
  //       onCancel={() => this.handleCloseModal("subscriber")}
  //       onSubmit={() => this.handleCloseModal("subscriber")}
  //       update={this.state.subscriberEditMode}
  //       subscriber={subscriber}
  //     />
  //   )
  // }

  // handlePatchSubName = () => {
  //   let row = this.state.subscriberRowsSelected[0];
  //   let ac = this.state.selectedAccount._id;
  //   if (row && row.uid) {
  //     console.log(`Selected sub:`, row.uid);
  //     getInstance().patch(Constants.URLs.ACCOUNT_SUBSCRIBERS + '/ac/' + ac  + '/subscriber/id/' + row.uid, {
  //       subId: row.uid,
  //       name: row.name
  //     }).then((result)=>{
  //       this.handleGetSubscribers()
  //     }).catch((err)=>{
  //       console.error(err);
  //     })
  //   }
  // }

  handleOnCellValueChanged = (params) => {
    let _ac = this.state.selectedAccount._id;
    var colId = params.column.getId();
    console.log('selected column: ' + colId);
    if (colId === 'name') {
        let { index, name } = params.data;
        console.log("index: " + index + " new name: " + name);
        this._accountHandler.updateSubscriberDetails( _ac, index, { index, name })
        .then((result)=>{
          //refreshed; now request for a force refresh of transactions
          Constants.requestTranRefresh();
        })
        .catch((error)=>{
          console.error(error)
          let msg = error.message;
          try {
            if (error.response.data.message) msg = error.response.data.message;
          } catch (err) {}
          try {
              if (error.response.data.error) msg = error.response.data.error;
          } catch (err) {}
          this.props.enqueueSnackbar(msg, {variant: 'error'});
        })
        .finally(()=>this.handleGetSubscribers())
    }
}

  renderSharingActions() {
    return (
      <Grid container style={{ margin: "4px" }}>
        <Grid item md={12} sm={12} xs={12}>
          {!this.state.enableAssignSubscriber && <Button disabled={true || !this.state.enableAssignSubscriber}
            onClick={() => this.handleOpenModal("subscriber-assign")}
            variant="contained"
            size="small"
            color="default">
            <i className="pe-7s-add-user"></i> Assign
                      </Button>}
        &nbsp;
        {this.state.enableEditSubscriber && <Button
            onClick={() => this.handlePatchSubName()}
            variant="contained"
            size="small"
            color="primary">Edit</Button>}
        &nbsp;
        {/* {this.state.enableDeleteSubscriber && <Button disabled={!this.state.enableDeleteSubscriber}
            onClick={() => this.handleOpenModal("subscriber-delete")}
            variant="contained"
            size="small"
            color="secondary">Delete</Button>}
        &nbsp; */}
      </Grid>
      </Grid>
    )
  }

  renderSubscribersGrid() {
    const { classes } = this.props;
    const agStyles = `ag-theme-balham ${classes.agThemeModified}`;
    return (
      <Grid container id='accountinfo-members-grid' /* style={{"height": "70%", width: "96%", marginLeft: "2%", marginTop: "10px"}} */ >
        <Grid item md={12} sm={12} xs={12}>
          {this.renderSharingActions()}
          {(this.state.subscribers.length === 0) &&
            <Typography>Subscribers are not setup yet on this account.</Typography>}
          {(this.state.subscribers.length > 0) && <div className={agStyles}>
            <AgGridReact
              gridOptions={{
                defaultColDef: {
                  resizable: false,
                  sortable: false,
                },
                onCellValueChanged: this.handleOnCellValueChanged
              }}
              // onRowDragEnter={this.onRowDragEnter}
              // onRowDragEnd={this.onRowDragEnd}
              onGridReady={this.handleOnSharingGridReady}
              // onRowDragLeave={this.onRowDragLeave}
              columnDefs={[{
                headerName: "Name",
                field: "name",
                width: 120,
                editable: true,
                // cellEditor: 'popupTextEditor',
                pinned: 'left'
              },{
                headerName: "Index",
                field: "index",
                width: 60
              }, {
                headerName: "Subscriber Id",
                field: "sid",
                width: 80
              }, {
                headerName: "Chit Lifted?",
                field: "isBid",
                width: 80,
                cellStyle:(params)=>{
                  if (params.value)
                    return {
                      color: '#125d12',
                      'background-color': '#2eca2e',
                      'font-size': 'medium',
                      'text-align': 'center'
                    }
                  else 
                    return {
                      color: '#710000',
                      'background-color': '#d48590',
                      'font-size': 'medium',
                      'text-align': 'center'
                    }
                }
              // },{
              //   header: "Account",
              //   field: "ac",
              //   width: 180,
              //   cellRenderer: (params) => {
              //     // return params.value;
              //     return `<a href="${"/transactions/" + params.value}" target="_blank">${params.value}</a>`;
              //   }
              }]}
              rowData={this.state.subscribers}
              // getRowClass={this.handleRowHighlights}
              rowSelection="single"
              onSelectionChanged={this.onSharingSelectionChanged.bind(this)}
              // rowDragManaged={true}
              animateRows
              // pagination={!this.state.gridEditMode}
              // paginationPageSize={25}
              enableCellTextSelection="true"
              ensureDomOrder="true"
            />
          </div>}
        </Grid>
      </Grid>
    )
  }

  render() {
    return (
      <div>
        {this.renderSubscribersGrid()}
        {/* <Dialog
          open={this.state.open.subscriberDeleteConfirmation}><Typography>
            {`Are you sure to delete the member?`}
          </Typography><Button
            onClick={() => this.handleDeleteSubscriber()}
          >Yes</Button><Button onClick={() => this.handleCloseModal("")}>No</Button></Dialog> */}

        {/* {this.state.open.openNewSubscriber && this.renderSubscriberModal()} */}

        {this.state.openOverlay && <LoadingOverlay />}
      </div>
    )
  }
}

SubscribersManager.propTypes = {
  classes: PropTypes.object.isRequired,
  display: PropTypes.bool.isRequired,
  selectedAccount: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withStyles(AccountStyle)(SubscribersManager)));
