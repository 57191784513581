import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';

import { Grid, withStyles } from '@material-ui/core';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import {AdminPanelStyle} from './AdminPanelStyle';

// import Constants from '../utils/constants';
import LoadingOverlay from '../common/loadingOverlay';
import './AdminPanel.css';
import mapDispatchToProps from '../actions/dispatchProps';
import mapStateToProps from "../actions/stateToProps";
import { AdminPanelGrid } from './AdminPanelGrid';
import { WidgetHandler } from '../api';

class AdminPanel extends Component {

    constructor(props) {
        super(props);
        this._handler = new WidgetHandler(this);
    }

    state = {
        openOverlay: false,
        userCounts: []
    }

    componentDidMount() {
        this._handler.getUserCounts().then((userCounts)=>{
            this.setState({
                userCounts
            })
        });
    }

	handleOnGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	onSelectionChanged() {
		// let selectedRows = this.gridApi.getSelectedRows();
		// if (this.state.selected.code === 'account_type' && selectedRows[0].code) {
		// 	this.handleGetTranTypes(selectedRows[0].code)
		// }
		// this.setState({ 
		// 	rowsSelected: selectedRows, 
        //     enabledDelete: ((selectedRows.length > 1) || (selectedRows.length === 1 && selectedRows[0].status === 'ac')),
        //     enabledEnable: ((selectedRows.length > 1) || (selectedRows.length === 1 && selectedRows[0].status === 'in')),
		// 	showTranTypeGrid: this.state.selected.code === 'account_type' && selectedRows.length === 1,
		// 	postTranTypes: {none: false, cr: false, dr: false, both: false}
		// })
	}

    renderUserCountsGrid() {
		const { classes } = this.props;
		const agStyles = `ag-theme-balham ${classes.agThemeModified}`;
		return (
			<Grid container style={{"height": "60vh"/* , width: "96%", marginLeft: "2%", marginTop: "10px" */}} >
				<Grid item  md={12} sm={12} xs={12}>
					<div className={agStyles}>
						<AgGridReact
                            defaultColDef={AdminPanelGrid.defaultColDef}
							onGridReady={this.handleOnGridReady}
							columnDefs={AdminPanelGrid.columns}
							className="my-custom-header"
							rowData={this.state.userCounts}
							rowSelection="multiple"
							// getRowClass={this.handleMainRowHighlights}
							onSelectionChanged={this.onSelectionChanged.bind(this)}
							animateRows
						/>
					</div>
				</Grid>
			</Grid>
		)
    }

    render() {
        return (            
			<div className="content-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">User Counts</h4>
                            <p className="card-category">No.of accounts &amp; transactions by User.</p>
                        </div>
                        <div className="card-body">
                            {this.renderUserCountsGrid()}    
                            
                            {/* <Dialog
                            open={this.state.open.disableConfirmation || this.state.open.enableConfirmation}><Typography>
                                {`Are you sure to ${this.state.open.disableConfirmation ? 'disable' : 'enable'} the list item?`}
                            </Typography><Button
                                onClick={()=>{
                                    if (this.state.open.disableConfirmation) {
                                        this.handleDeleteSelection("in")
                                    } else {
                                        this.handleDeleteSelection("ac")
                                    }
                                }}
                            >Yes</Button><Button onClick={()=>this.handleCloseModal("")}>No</Button></Dialog> */}
                            

                            {this.state.openOverlay && <LoadingOverlay />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}


AdminPanel.propTypes = {
	classes : PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withStyles(AdminPanelStyle)(AdminPanel)));
