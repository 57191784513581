import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import './Upload.css'
import {TranStyle} from '../tran/TranStyle';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { withStyles, /* Checkbox,  */Button } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import CSVParser from './csv-parser/index';
import {cloneDeep as _cloneDeep, concat as _concat} from 'lodash';
import { getInstance, getTranTypesByAc } from '../utils/axiosLoader';
import Constants from '../utils/constants';
import { withSnackbar } from 'notistack';
import LoadingOverlay from '../common/loadingOverlay';
import DatePicker from './components/date-picker';
import NumericEditor from './components/numeric-editor';
import mapStateToProps from '../actions/stateToProps';

// let SESSION_KEYS = Constants.SESSION_KEYS;
class UploadCSV extends Component {
  constructor(props) {
    super(props);
    this.handleMappingChange = this.handleMappingChange.bind(this);
  }

  state = {
    files: [],
    uploading: false,
    uploadProgress: {},
    successfullUploaded: false,
    defaultHeader: [],
    header: [],
    sourceData: [],
    csvUploadRows: [],
    gridRows: [],
    csvUploadError: '',
    firstRowIsHeader: true,
    actionInProgress      : {
      disableActionButton : false
    },
    rowsSelected: [],
    enabledDelete: false,
    colDefs: [],
    ddTranTypes: ["unknown"],
    tranTypes: [{"status":"ac","_id":"5e5a00f099332e69a8a0fe29","name":"Unknown","code":"unknown","list":"card_tran_type"}],
    openOverlay: false,
    mapping: {
      source: Constants.EMPTY_CSV_HEADERS,
      target: ['date', 'type', 'dr', 'cr', 'desc', 'note'],
      map: {
        date: -1,
        dr: -1,
        cr: -1,
        desc: -1,
        type: -1,
        note: -1,
      }
    }
  };

  componentDidMount() {
    let header = [];
    for (let i = 0; i<=12; i++) header.push(`${i}`);
    // //@////console.log(header);
    this.setState({ defaultHeader: header})
    this.handleGetListItems();
  }

  handleOnGridReady = params => {
    //@////console.log('handleOnGridReady')
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const colIds = this.gridColumnApi
      .getAllDisplayedColumns()
      .map(col => col.getColId());
    this.gridColumnApi.autoSizeColumns(colIds)
  }

  handleGridSizeChanged = params => {
    params.columnApi.autoSizeColumns();
    const colIds = this.gridColumnApi
      .getAllDisplayedColumns()
      .map(col => col.getColId() + "-------");
    this.gridColumnApi.autoSizeColumns(colIds);
  }

  handleRowHighlights = params => {
    if (params.data.CR > 0) return 'ofx-credit-row';
  }


	handleGetListItems = async () => {
    this.setState({ openOverlay: true });
		await getTranTypesByAc(this.props.account.type)
      .then((response)=>{
          let ddTranTypes = response.reduce((arr, type)=>{
            if (type.tag !== 'N')
              arr.push(type.code);
            return arr;
          },[]);
          ddTranTypes.sort();
          this.setState({ 
            openOverlay: false, 
            tranTypes: response, 
            ddTranTypes: ddTranTypes
          });	
      })
		.catch((err)=>console.log('error', err.message))
	}

  // //TODO: add this method to axiosloader and access it.
	// handleGetListItems = async () => {
	// 	let retrieveFrom = Constants.URLs.LIST_ITEMS_LIST.BASE + '/';
	// 	let accType = 'bank'; //default value
	// 	if (sessionStorage.getItem(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE)) {
	// 		let type = sessionStorage.getItem(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE);
	// 		if (type === 'CC') accType = 'card';
	// 		else if (type === 'SAV' || type === 'CHQ' || type === 'DEF') accType = 'bank';
	// 		// retrieveFrom += `${type}`;	
	// 	}
	// 	// if (CrDr !== null) {
	// 	// 	accType += `_${CrDr}`;
	// 	// }
	// 	retrieveFrom += accType + '_tran_type';
	// 	this.setState({ openOverlay: true });
	// 	await getInstance()
	// 		.get(retrieveFrom)
	// 		.then((response) => {
  //       // //console.log('list items:' + JSON.stringify(response.data));
  //       let ddTranTypes = response.data.reduce((arr, type)=>{
  //         arr.push(type.code);
  //         return arr;
  //       },[]);
  //       ddTranTypes.sort();
	// 			this.setState({ openOverlay: false, tranTypes: response.data, ddTranTypes: ddTranTypes});
	// 		})
	// 		.catch((error) => {
	// 			if (error.response)
	// 			//@////console.log(error);
	// 			this.props.enqueueSnackbar('Error: Could not be able to retrieve list items', {
	// 				variant : 'error'
	// 			});
	// 		});
	// }

  handleCheckboxChange = (e) => {
    let checked = e.target.checked;
    this.populateSourceGrid(checked);
  }
  
  handleResetImportCSV() {
    this.setState({
      gridRows: [],
      firstRowIsHeader: true
    })
  }

  populateSourceGrid(headerPresent) {
    // let headerPresent = this.state.firstRowIsHeader;
    let data = this.state.sourceData;
    // //console.log('source data:', data);
    if (data.length === 0) return;
    let colDefs = [];
    let widths = {};
    let index = 0;
    let headers = [];
    // let data2 = data;
    let joinedArr = _cloneDeep(data);
    let shiftedData = joinedArr;
    if (!headerPresent) {
      let newRow = Object.keys(data[0]);
      for (let i=0; i< newRow.length; i++) {
        headers.push(i);
        colDefs.push({
          headerName: `Column ${i}`,
          field: `${i}`,
          width: 40  //minimum width
        });
        widths[i]=4;
      }
      let newRowObj = newRow.reduce((obj, elem)=>{
        obj[index++] = elem;
        return obj;
      }, {});
      joinedArr = _concat(newRowObj,_cloneDeep(data));
      shiftedData = joinedArr.reduce((arr, row)=>{
        let row1 = {};
        index = 0;
        Object.values(row).forEach((val)=>row1[index++] = val)
        arr.push(row1);
        return arr;
      },[]);
    } else {
      headers = Object.keys(data[0])
      index = 0;
      headers.forEach((header)=>{
        colDefs.push({
          headerName: header,
          field: `${index}`,
          width: 40  //minimum width
        });
        widths[index++]=4;
      })

      // headers = Constants.EMPTY_CSV_HEADERS;
      shiftedData = joinedArr.reduce((arr, row)=>{
        let row1 = {};
        index = 0;
        Object.values(row).forEach((val)=>row1[index++] = val);
        arr.push(row1);
        return arr;
      },[]);
    }
    //console.log('headers before:', headers);
    //console.log('shifted Data:', shiftedData);
    
    //console.log('colDefs:', colDefs);
    //console.log('widths:', _cloneDeep(widths));
    for (let item of shiftedData) {
      for (let [key, val] of Object.entries(item)) {
        let len = val.toString().length;
        if (len > widths[key]) {
          if (len > Constants.GRID_ROW_MAX_LENGTH) len = Constants.GRID_ROW_MAX_LENGTH;
          widths[key] = len;
        }
      }
    }
    //console.log('widths:', widths);
    colDefs.forEach((colDef)=>colDef.width = widths[colDef.field] * 10);

    let _mapping = this.state.mapping;
    _mapping.source = [];
    colDefs.forEach((colDef)=>{
      let h = colDef.headerName;
      if (h) {
        h = h.trim().toLowerCase();
      } else h = '';
      let index = colDefs.indexOf(colDef);
      if (h.includes('description') || h.includes('desc') || h.includes('remarks')) _mapping.map.desc = index;
      else if (h.includes('type')) _mapping.map.type = index;
      else if (h.includes('date') || h.includes('posted on')) _mapping.map.date = index;
      else if (h.includes('notes') || h.includes('note')) _mapping.map.note = index;
      else if (h.includes('credit') || h.startsWith('cr') || h.includes('deposit')) _mapping.map.cr = index;
      else if (h.includes('debit') || h.startsWith('dr') || h.includes('withdrawal') || h.includes('amount') || h.includes('amnt')) _mapping.map.dr = index;
      _mapping.source.push(colDef.headerName);
    })

    //console.log('widths:after', colDefs);
    //console.log('mapping:', _mapping);
    this.setState({ 
      colDefs: colDefs, 
      gridRows: shiftedData, 
      firstRowIsHeader: headerPresent,
      mapping: _mapping
    });
  }

  guessDateFormat() {
    let {gridRows, mapping} = this.state;
    let hitMiss = {}
    let formats = [
        'MMDDYYYY', 'DDMMYYYY', 'YYYYMMDD', 
        'MM-DD-YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD', 
        "DD MMM YYYY", "YYYY MMM DD",
        'MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD',
        'MMM. DD,YYYY', 'MMM. DD,YY', 
        'YYYYMMDDhhmmss'
    ];
    // if (val.length === 8) {
    //   formats = ['MMDDYYYY', 'DDMMYYYY', 'YYYYMMDD'];
    // } else if (val.length === 10) {
    //   //TODO: by scanning throught the first or second portion, detect which is month (1<=12) or date (1<=31)
    //   formats = ['MM/DD/YYYY', 'MM-DD-YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD',  'DD/MM/YYYY'];
    // } else {
    //   formats = ['YYYYMMDDhhmmss'];
    // }
    formats.forEach(format => {
      hitMiss[format] = 0;
      gridRows.forEach((row)=>{
        if (mapping.map.date > -1) {
          let val = row[mapping.map.date];
          if (val) {
              let dateNew = null;
              try {
                dateNew = moment(val, format).format('YYYY-MM-DD');
              } catch (error) {}
              finally {
                if (dateNew !== null && dateNew !== undefined && dateNew !== 'Invalid date' && dateNew.length === 10) {
                  hitMiss[format]++;
                }
              }
          }
        }
      })
    })
    let hitCnt = 0;
    let hitFormat = '';
    for (let [f,h] of Object.entries(hitMiss)) {
      if (h > hitCnt) {
        hitCnt = h;
        hitFormat = f;
      }
    }
    return hitFormat;
  }

  handleExtractData() {
    let {gridRows, mapping} = this.state;
    console.log('mapping', mapping);
    let extract = [];
    let format = this.guessDateFormat();
    console.log('guessed format', format);
    let isCC = this.props.account.type === 'CC';
    gridRows.forEach((row)=>{
      let out = {
        date: moment().format('YYYY-MM-DDThh:mm:ss'),
        dr: 0,
        cr: 0,
        curr: this.props.account.curr,
        type: isCC ? 'purchase' : 'unknown',
        desc: '',
        note: '',
        account: this.props.account._id
      };
      if (format !== '' && mapping.map.date > -1) {
        let val = row[mapping.map.date];
        if (val) {
          // let formats = ['YYYY-MM-DD'];
          // if (val.length === 8) {
          //   formats = ['MMDDYYYY', 'DDMMYYYY', 'YYYYMMDD'];
          // } else if (val.length === 10) {
          //   //TODO: by scanning throught the first or second portion, detect which is month (1<=12) or date (1<=31)
          //   formats = ['MM/DD/YYYY', 'MM-DD-YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD',  'DD/MM/YYYY'];
          // } else {
          //   formats = ['YYYYMMDDhhmmss'];
          // }
          // formats.forEach(format => {
            let dateNew = null;
            try {
              dateNew = moment(val, format).format('YYYY-MM-DD');
            } catch (error) {}
            finally {
              if (dateNew !== null && dateNew !== undefined && dateNew !== 'Invalid date') {
                out.date = dateNew;
              }
            }
          // })
        }
      }
      let isCr = false; //default debit
      if (mapping.map.dr > -1) {
        let dr = row[mapping.map.dr];
        // if (isFinite(dr)) {
        //   out.dr = dr;
        // } else {
        let dr2 = Constants.extractAmnt(dr);
        if (dr2 < 0) {
            out.cr = dr2 * -1;
            out.type = isCC ? 'payment' : 'transfer_in';
            isCr = true;
        } else {
            out.dr = dr2;
            out.type = isCC ? 'purchase' : 'transfer_out';
        }
        // }
      }
      if (mapping.map.cr > -1) {
        let cr = row[mapping.map.cr];
        // if (isFinite(cr)) {
        //   out.cr = cr;
        // } else {
          let cr2 = Constants.extractAmnt(cr);
          if (cr2 < 0) {
              out.dr = cr2 * -1;
              out.type = isCC ? 'purchase' : 'transfer_out';
          } else {
              out.cr = cr2;
              out.type = isCC ? 'payment' : 'transfer_in';
              isCr = true;
          }
        // }
      }
      if (mapping.map.type > -1) {
        let type = row[mapping.map.type].toLowerCase();
        if (type) {
          let found = this.state.tranTypes.filter((item, index)=>{
            return item.code.toLowerCase() === type.trim().toLowerCase() || 
                  item.name.toLowerCase() === type.trim().toLowerCase();
          })[0];
          if (found !== undefined)  {
            out.type = found.code;
          } else {
            if (type.includes('interac e-transfer')) out.type = isCr ? 'transfer_in' : 'transfer_out';
            else if (type.includes('trsf from')) out.type = isCC ? 'payment' : 'transfer_in';
            else if (type.includes('interest deposit')) out.type = 'interest';
            else if (type.includes('interest purchases') || type.includes('interest advances')) out.type = 'fee';
            else if (type === 'pr') out.type = 'purchase';
            else if (type === 'sc') out.type = 'fee';
            else {
                let approx = this.state.tranTypes.filter((item, index)=>{
                  return type.includes(item.code.toLowerCase()) || 
                        type.includes(item.name.toLowerCase());
                })[0];
                if (approx !== undefined)  {
                  out.type = approx.code;
                }
            }
          }
        }
      }
      if (mapping.map.desc > -1) {
        let desc = row[mapping.map.desc];
        if (desc) {
          out.desc = desc.substr(0, 100);
        }
      }
      if (mapping.map.note > -1) {
        let note = row[mapping.map.note];
        if (note) {
          out.note = note;
        }
      }
      //TODO: Add others
      extract.push(out);
    })
    this.setState({
      csvUploadRows: extract
    })
  }

	handleImportCSV = async () => {
    this.setState({ openOverlay: true });
    let ac = this.props.account._id;
    if (ac === 'unknown') {
      this.props.enqueueSnackbar('Selected Account is invalid. Upload cannot be completed.', {
        variant : 'error'
      });
      return;
    }
    let _actionInProgress = this.state.actionInProgress;
    _actionInProgress.disableActionButton = true;
    this.setState({ actionInProgress : _actionInProgress });
    //console.log('upload:',this.state.csvUploadRows )
		await getInstance()
			.post(Constants.URLs.TRANS  + '/many', {account: this.props.account, trans: this.state.csvUploadRows})
			.then((response)=>{
        //@////console.log(response)
				this.props.enqueueSnackbar(response.data.message, {
					variant : 'success'
        });
        _actionInProgress.disableActionButton = false;
        this.setState({ actionInProgress : _actionInProgress, openOverlay: false });
        this.props.onSubmit();
			})
			.catch((error)=>{
				this.props.enqueueSnackbar('Error: An error occured while importing the CSV transactions', {
					variant : 'error'
        });
        this.props.onSubmit();
			})
  }

  handleMappingChange(e) {
    //console.log('mapping selected:', e.target.value);
    let index = e.target.value.split('@')[0];
    let option = e.target.value.split('@')[1];
    let _mapping = this.state.mapping;
    _mapping.map[_mapping.target[index]] = Number(option);
    //console.log('mapping:', _mapping);
    this.setState({
      mapping: _mapping
    })
  }


  
  render() {
    return this.renderNewView();
  }
  
  renderNewView() {
    return (
      <div className="content">
        <div className="content-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Upload a CSV file</h4>
                </div>
                <div className="card-body">
                  {this.renderFeedUploader()}
                  {this.state.gridRows.length > 0 && 
                    <div className="row">
                      <div className="col-sm-5">
                        {this.renderFeedGrid()}
                      </div>
                      <div className="col-sm-2">
                        {this.renderMappingGrid()}
                      </div>
                      <div className="col-sm-5">
                        {this.renderProcessedGrid()}
                      </div>
                    </div>}
                  {this.state.openOverlay && <LoadingOverlay />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderFeedUploader() {
    const {classes} = this.props;
    return (
      <div className="Upload">
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => this.props.onCancel()}>
          Cancel
        </Button>
        {/* <span className="Title">Upload Files</span> */}
        {this.state.gridRows.length === 0 && <div className="content">
            <CSVParser label="Upload CSV file: " 
              headerPresent={this.state.firstRowIsHeader}
              onFileLoaded={data => {
                console.dir(data)
                if (data.length > 0) {
                  this.setState({sourceData: data});
                  this.populateSourceGrid(this.state.firstRowIsHeader);
                  // this.handleCSVParserData(data)
                } else {
                  this.props.enqueueSnackbar('Empty CSV data set parsed. Please retry.', {
                    variant : 'warning'
                  });
                }
              }} 
              onError={msg => {
                this.setState({ csvUploadRows: [], csvUploadError: msg })
                this.props.enqueueSnackbar('An error occured. Error: ' + msg, {
                  variant : 'error'
                });
              }}/>
            {/* {(this.state.csvUploadError !== '') && <span>There was an error occured while parsing the supplied file:{this.state.csvUploadError}</span>} */}
          </div>}
        <div className="Actions" >{this.renderActions()}</div>
      </div>
    )
  }

  renderActions() {
    const {classes} = this.props;
    return (
      <div className="grid">
        <div className="mdl-row">
          {this.state.gridRows.length > 0 &&
            <input id="chkFirstRowHeader" 
              onChange={this.handleCheckboxChange} 
              type="checkbox" 
              checked={this.state.firstRowIsHeader} />}
          {this.state.gridRows.length > 0 &&
            <label htmlFor="chkFirstRowHeader">Data Includes Header</label>}
          {this.state.gridRows.length > 0 &&
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={this.state.actionInProgress.disableActionButton}
              onClick={() => this.handleResetImportCSV()}>
              Re-upload
            </Button>}
          {this.state.gridRows.length > 0 &&
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={this.state.actionInProgress.disableActionButton}
              onClick={() => this.handleExtractData()}>
              Extract
            </Button>}
          {this.state.gridRows.length > 0 &&
            <Button
              variant="contained"
              color="inherit"
              className="btn btn-success btn-fill"
              disabled={this.state.actionInProgress.disableActionButton}
              onClick={() => this.handleImportCSV()}>
              Upload
            </Button>}
        </div>
      </div>
    )
  }

  renderFeedGrid() {
    return (
        <div className="ag-theme-balham" style={{height: '500px'}} >
            {Array.isArray(this.state.gridRows) && this.state.gridRows.length > 0 && 
              <AgGridReact
                gridOptions={{
                  defaultColDef: {
                    resizable: true
                  }
                }}
                columnDefs={this.state.colDefs}
                rowData={this.state.gridRows}
                getRowClass={this.handleRowHighlights}
                onGridReady={this.handleOnGridReady}
                // onGridSizeChanged={this.handleGridSizeChanged}
                rowSelection="multiple"
                animateRows
                suppressFieldDotNotation={true}
              />}
        </div>
    )
  }

  renderMappingGrid() {
    let source = this.state.mapping.source;
    let target = this.state.mapping.target;
    let map = this.state.mapping.map;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="header">Source-Target Mapping</div>
              <div className="content">
                { target.map((t)=>{
                  return <div key={`source-${target.indexOf(t)}`}
                                className="form-group">
                    <label htmlFor={`map-${t}`}>{t}:</label>
                    <Select id={`map-${t}`} key={`map-${t}`} 
                            value={`${target.indexOf(t)}@${map[t]}`} 
                            className="form-control"
                            onChange={this.handleMappingChange}>
                              <option value={`${target.indexOf(t)}@-1`}>None</option>
                              {source.map((s) => {
                                return <option key={source.indexOf(s)} value={`${target.indexOf(t)}@${source.indexOf(s)}`}>{s}</option>
                              })}
                    </Select>
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderProcessedGrid() {
    let processedColDefs = [/* {
      headerName: 'Account', field: 'account', width: 180,
    }, */{
      headerName: 'Date', field: 'date', width: 100, editable: true, cellEditor: 'datePicker'
    },{
      headerName: 'Credit', field: 'cr', width: 80, editable: true,
      valueFormatter: (data) => {
        let amt = Constants.extractAmnt(data.value);
        if (amt < 0) {
            return amt * -1;
        } else {
            return amt;
        }
      }
       /*, cellEditor: 'numericEditor'*/
    },{
      headerName: 'Debit', field: 'dr', width: 80, editable: true, /* cellEditor: 'numericEditor' */
    },{
      headerName: 'Type', field: 'type', width: 100, editable: true, 
      cellRenderer: (params) => {
        let code = params.value;
        let type = this.state.tranTypes.filter((type)=>{
          return type.code === code;
        })[0];
        if (type !== undefined) {
          return type.name;
        } else {
          return code;
        }
      },
      // keyCreator: function (type) {
      //   return type.code;
      // },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
          // cellRenderer: (params) => {}
          values: this.state.ddTranTypes 
      }
    },{
      headerName: 'Description', field: 'desc', width: 180, editable: true
    },{
      headerName: 'Note', field: 'note', width: 180, editable: true
    }]
    return (
        <div className="ag-theme-balham" style={{height: '500px'}} >
            {Array.isArray(this.state.csvUploadRows) && this.state.csvUploadRows.length > 0 && 
              <AgGridReact
                gridOptions={ 
                  {
                    // editType: 'fullRow',
                    components: {
                      datePicker: DatePicker(),
                      numericEditor: NumericEditor(),
                      typeCellRenderer: (params) => params.value.name
                    },
                    defaultColDef: {
                      resizable: true,
                      filter: true
                    }
                  }
                }
                columnDefs={processedColDefs}
                rowData={this.state.csvUploadRows}
                // getRowClass={this.handleRowHighlights}
                // onGridReady={this.handleOnGridReady}
                // onGridSizeChanged={this.handleGridSizeChanged}
                rowSelection="multiple"
                animateRows
                // suppressFieldDotNotation={true}
              />}
        </div>
    )
  }

}

UploadCSV.propTypes = {
    classes: PropTypes.object.isRequired,
    display: PropTypes.bool.isRequired,
    onDataReady: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired
  };
  
  export default connect(mapStateToProps)(withSnackbar(withStyles(TranStyle)(UploadCSV)));
  