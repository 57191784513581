import React, { Component } from 'react'
import Modal from 'react-responsive-modal';
// import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { withSnackbar } from 'notistack';
import {cloneDeep as _cloneDeep} from 'lodash';

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

import './Upload.css'
import QIFParser from './qif-parser/qif-parser';
import { TranStyle } from '../tran/TranStyle';
import Constants from '../utils/constants';
import { getInstance } from '../utils/axiosLoader';
import mapStateToProps from '../actions/stateToProps';

class UploadOFX extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
        files: [],
        uploading: false,
        uploadProgress: {},
        successfullUploaded: false,
        qifUploadRows: [],
        qifUploadError: '',
        showActions: false,
        actionInProgress      : {
          disableActionButton : false
        },
        rowsSelected: [],
        enabledDelete: false,
        processedData: [],
        processedColDefs: [/* {
          headerName: 'Account', field: 'account', width: 180,
        }, */{
          headerName: 'Date', field: 'date', width: 110,
        },{
          headerName: 'Credit', field: 'cr', width: 90,
        },{
          headerName: 'Debit', field: 'dr', width: 90,
        },{
          headerName: 'Type', field: 'type', width: 100,
        },{
          headerName: 'Description', field: 'desc', width: 200,
        },{
          headerName: 'Note', field: 'note', width: 100,
        }],
      };
  }

  handleOnGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  handleRowHighlights = params => {
    if (params.data.CR > 0) return 'ofx-credit-row';
    if (params.data.DR > 0) return 'ofx-debit-row';
  }

	onSelectionChanged() {
		let selectedRows = this.gridApi.getSelectedRows();
		this.setState({ rowsSelected: selectedRows });
		this.setState({ enabledDelete: (selectedRows.length > 0) })
  }
  
  
	handleDeleteSelection = async () => {
    let allRows = this.state.qifUploadRows;
    let rowsToDelete = this.state.rowsSelected;
    console.dir(allRows)
    rowsToDelete.forEach(function (item, index) {
      allRows.splice(allRows.indexOf(item),1);
    })
    console.dir(_cloneDeep(allRows))
    this.setState({ enabledDelete: false, rowsSelected: [], qifUploadRows: allRows });
    this.gridApi.setRowData(allRows);
	}

  handleProcessData() {
		let trans = this.state.qifUploadRows;
    let ac = this.props.account._id;
    if (ac === 'unknown') {
      this.props.enqueueSnackbar('Selected Account is invalid. Upload cannot be completed.', {
        variant : 'error'
      });
      return;
    }

		// let processed = trans.reduce((arr, tran) => {
		// 	let obj = {
		// 		date: moment(tran.DTPOSTED, 'YYYYMMDDhhmmss').format('YYYY-MM-DDThh:mm:ss'),
		// 		dr: tran.DR,
		// 		cr: tran.CR,
		// 		curr: tran.TRNCURR,
		// 		type: tran.type,
		// 		desc: tran.NAME,
		// 		account: ac
		// 	}
		// 	arr.push(obj);
		// 	return arr;
    // },[]);
    let processed = trans.reduce((outArr, tran) => {
      let out = {
        date: tran.date,
        dr: tran.dr,
        cr: tran.cr,
        curr: this.props.account.curr,
        type: 'unknown',
        desc: tran.payee,
        note: '',
        account: this.props.account._id
      };

      //TODO: Create description rendering rules for each bank type

      // tran.TRNCURR = data.currency;
      // if (tran.TRNTYPE === 'DEBIT' && tran.TRNAMT.includes('-')) {
      //   if (type === 'cc') {
      //     tran.type = 'Purchase';
      //   }
      //   tran.DR = parseFloat(tran.TRNAMT) * -1;
      //   tran.CR = 0
      // }else if (['CREDIT', 'INT', 'XFER'].includes(tran.TRNTYPE)) {
      //   if (type === 'cc') {
      //     if (tran.NAME.includes('CASHBACK')) tran.type = 'Cashback';
      //     else tran.type = 'Payment';
      //   } else if (type === 'bank') {
      //     if (tran.TRNTYPE === 'INT') tran.type = 'Interest'
      //     if (tran.TRNTYPE === 'XFER') tran.type = 'Transfer In'
      //     if (tran.TRNTYPE === 'CREDIT') tran.type = 'Credit In'
      //   }
      //   tran.CR = parseFloat(tran.TRNAMT);
      //   tran.DR = 0
      // }
      outArr.push(out);
      return outArr;
    }, []);
    this.setState({ processedData: processed });
  }

	handleImportQIF = async () => {
    if (this.state.processedData.length <= 0) {
      this.props.enqueueSnackbar('Date is yet to processed. Please retry.', {
        variant: 'warning'
      })
      return;
    }
    let _actionInProgress = this.state.actionInProgress;
    _actionInProgress.disableActionButton = true;
    this.setState({ actionInProgress : _actionInProgress });

		await getInstance()
			.post(Constants.URLs.TRANS  + '/many', {account: this.props.account, trans: this.state.processedData})
			.then((response)=>{
        console.log(response)
				this.props.enqueueSnackbar(response.data.message, {
					variant : 'success'
        });
        _actionInProgress.disableActionButton = false;
        this.setState({ actionInProgress : _actionInProgress });
        this.props.onSubmit();
			})
			.catch((error)=>{
				console.log('An error occured while importing the OFX transactions. Error:' + error.message);
				this.props.enqueueSnackbar('Error: An error occured while importing the OFX transactions', {
					variant : 'error'
        });
        this.props.onSubmit();
			})
	}


  handleFileUpload(data) {
    console.dir(data);
    // if (data.parsed) {
      // let type = data.type;
      let parsed = data.transactions.reduce((arr, tran)=>{
        
        let cr = 0;
        let dr = 0;
        if (tran.amount < 0) {
          dr = tran.amount * -1;
        } else {
          cr = tran.amount;
        }

        if (data.type === 'CCard') {
          tran.dr = cr;
          tran.cr = dr;
        } else {
          tran.cr = cr;
          tran.dr = dr;
        }
        
        arr.push(tran);
        return arr;
      },[]);
      console.log('parsed', parsed);
      this.props.enqueueSnackbar('OFX content loaded successfully.', {
        variant : 'success'
      });
      this.setState({ qifUploadRows: parsed, qifUploadError: '', showActions: true })
    // } else {
    //   console.dir(data);
    //   this.props.enqueueSnackbar('Unidentifyable QIF content', {
    //     variant : 'warning'
    //   });
    //   this.setState({ qifUploadRows: [], qifUploadError: 'Unidentifyable OFX content', showActions: false })
    // }
  }




  /**
   * Render UI Components
   */

  render() {
    return (this.props.asModal) ?
       this.renderAsModal()
       :
       this.renderAsGrid()
  }

  
  renderAsModal() {
    const {classes} = this.props;
    return (
      <Modal
        open={this.props.display}
        center
        showCloseIcon={false}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        onClose={() => {}}
        styles={{ modal: { padding: 0, width: '1100px', paddingLeft: '0.5rem' } }}>
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => this.props.onCancel()}>
              Cancel
            </Button>
            {this.renderFileSelector()}
            {/* <div className="row"> */}
              {/* <div className="col-sm-5"> */}
                {this.renderGrid()}
              {/* </div> */}
              {/* <div className="col-sm-7"> */}
                {this.state.processedData.length > 0 && this.renderProcessedGrid()}
              {/* </div> */}
            {/* </div> */}
            {this.renderActions()/* TODO: Render actions only when imported file is successfully processed */} 
          </div>
      </Modal>
    )
  }

  renderAsGrid() {
    const {classes} = this.props;
    return (
      <div className="content">
        <div className="content-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Upload an QIF file</h4>
                </div>
                <div className="card-body">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => this.props.onCancel()}>
                    Cancel
                  </Button>
                  {this.renderFileSelector()}
                  {this.renderActions()}
                  <div className="row">
                    <div className="col-sm-5">
                      {this.renderGrid()}
                    </div>
                    <div className="col-sm-7">
                      {this.state.processedData.length > 0 && this.renderProcessedGrid()}
                    </div>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  renderFileSelector() {
    const {classes} = this.props;
    return (
      <Grid item md={6} sm={6} xs={6} /* style={{ backgroundColor: "#ff0000" }} */>
        <div className={classes.ofxFileSelectionContainer}>
          <QIFParser 
              label="Upload QIF file: "
              onFileLoaded={data => {
                this.handleFileUpload(data)
              }}
              onError={msg => {
                console.dir(msg)
                this.props.enqueueSnackbar(msg, {
                  variant : 'error'
                });
                this.setState({ 
                  qifUploadRows: [], 
                  qifUploadError: 'There was an error occured while parsing the supplied file.',
                  showActions: false
                })
              }}
            />
        </div>
      </Grid>
    )
  }

  renderActions() {
    const {classes} = this.props;
    return (
      this.state.showActions && 
        <Grid item md={12} sm={12} xs={12} style={{ /* backgroundColor: "#ff0000", */ justifyItems: "end" }}>
          <div className={classes.ofxUploadActions}>
              <Button
                variant="contained"
                className="btn btn-info"
                disabled={this.state.actionInProgress.disableActionButton}
                onClick={() => this.handleProcessData()}>
                Extract
              </Button>
              <Button
                variant="contained"
                className="btn btn-success"
                disabled={this.state.processedData.length <= 0}
                onClick={() => this.handleImportQIF()}>
                Upload
              </Button>
              <Button
                variant="contained"
                className="btn btn-error"
                hidden={!this.state.enabledDelete}
                onClick={() => this.handleDeleteSelection()}>
                Delete
              </Button>
          </div>
        </Grid>
    )
  }

  renderGrid() {
    return (
      // <Grid item md={4} sm={4} xs={4}>
        <div className="ag-theme-balham" style={{ height: `${this.props.asModal ? '40vh' : '70vh'}`, padding: '1%'}}>
          {Array.isArray(this.state.qifUploadRows) && this.state.qifUploadRows.length > 0 && 
            <AgGridReact
              columnDefs={[
                {headerName: 'Date', field: 'date', minWidth: 74, editable: true, 
                            /* cellRenderer: (params) => { return params.value.substr(0,8); } */ },
                // {headerName: 'Amount', field: 'amount', minWidth: 64, editable: true},
                // {headerName: 'Type', field: 'TRNTYPE', minWidth: 64, editable: true},
                {headerName: 'Desc', field: 'payee', editable: true}
                /* {headerName: 'Curr', field: 'TRNCURR', minWidth: 50} */
                /* {headerName: 'type', field: 'type', minWidth: 100}*/,
                {headerName: 'Credit', field: 'cr', minWidth: 80},
                {headerName: 'Debit', field: 'dr', minWidth: 80} 
              ]}
              getRowClass={this.handleRowHighlights}
              onGridReady={this.handleOnGridReady}
              rowData={this.state.qifUploadRows}
              rowSelection="multiple"
							onSelectionChanged={this.onSelectionChanged.bind(this)}
              animateRows
              resizable={true}
          />}
        </div>
      // </Grid>
    )
  }
  
  renderProcessedGrid() {
    return (
      // <Grid item md={4} sm={4} xs={4}>
        <div className="ag-theme-balham" style={{height: `${this.props.asModal ? '40vh' : '70vh'}`}} >
            {Array.isArray(this.state.processedData) && this.state.processedData.length > 0 && 
              <AgGridReact
                columnDefs={this.state.processedColDefs}
                rowData={this.state.processedData}
                // getRowClass={this.handleRowHighlights}
                // onGridReady={this.handleOnGridReady}
                // onGridSizeChanged={this.handleGridSizeChanged}
                rowSelection="multiple"
                animateRows
                resizable={true}
                // suppressFieldDotNotation={true}
              />}
        </div>
      // </Grid>
    )
  }


}

UploadOFX.propTypes = {
  classes: PropTypes.object.isRequired,
  display: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  asModal: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(withSnackbar(withStyles(TranStyle)(UploadOFX)));
  