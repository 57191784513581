import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import qs from 'qs';
import {getInstance} from '../utils/axiosLoader';
import Constants from '../utils/constants'
import mapDispatchToProps from '../actions/dispatchProps';
import LoadingOverlay from '../common/loadingOverlay';
import './authstyle.css';

let _this=null;
let host = window.location.hostname;
let port = window.location.port;
let _domain=`https://${host}`;
if (host.includes('localhost') || host.includes('192.168')) {
    _domain = `http://${host}:${port}`;
} else if(host.includes('codecubers.lab')) {
    _domain = `http://${host}`;
} 
class ResetPassword extends Component {
    constructor(props) { 
        super(props) 
        this.handleFieldChange = this.handleFieldChange.bind(this);
        _this = this;
    };

    state = {
        openOverlay: false,
        form: {
            id: '',
            token: '',
            newPassword: '',
            verifyPassword: '',
            domain: _domain
        }
    };

    handleFieldChange = (e) => {
        let _form = this.state.form;
        _form[e.target.name] = e.target.value;
        this.setState({ form: _form });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        // let instance = getInstance();
        // instance.defaults.headers['Content-Type'] = 'application/json';
        //TODO: handle it as part of form error
        let _form = this.state.form;
        if (_form.newPassword === '' || (_form.newPassword !== _form.verifyPassword)) {
            this.props.enqueueSnackbar('Password is missing or do not match. Please correct.', {
                variant : 'warning'
            });
            return;
        }
        try {
            //console.log(this.props)
            
            // let query = useQuery();
            _form.token = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token;
			// const {match: {params}} = this.props;
			// if (params) {
                console.log('parameter received:' + JSON.stringify(_form.token));
            //     // _form.id = params.id;
            //     _form.token = params.token;
			// }	
		} catch (error) {
			this.props.enqueueSnackbar('Error: ' + error.message, {
                variant : 'error'
            });
            return;
		}
        this.setState({ openOverlay: true })
        await getInstance()
            .post(Constants.URLs.AUTH.RESET, _form)
            .then(async (response) => {
                this.setState({ openOverlay: false })
                // console.log(response.data);
                if (response.status !== 200) {
                    this.props.enqueueSnackbar('Error: ' + response.data.msg, {
                        variant : 'error'
                    });
                }
                else {
                    this.props.enqueueSnackbar(response.data.msg, {
                        variant : 'success'
                    });
                    _this.props.history.push("/auth/login");
                }
            })
            .catch((err) => {
                this.setState({ openOverlay: false })
                console.log(err);
				this.props.enqueueSnackbar('Error: An internal error occured', {
					variant : 'error'
				});
            });
    }

    renderResetForm() {
        return (
            <form className="form" method="" /* action="" */ /* onSubmit={this.handleSubmit} */>
                <div className="card card-reset text-center">
                    <div className="card-header ">
                        <div className="row  justify-content-center">
                            <div className="header-text">
                                <h3 className="card-title">Gumasta</h3>
                                <h6 className="card-subtitle">Free ledger to care your monies</h6>
                                <hr />
                                <h3 className="header text-center">Reset Password</h3>
                            </div>
                        </div>
                    </div>
                    <div className="card-body ">
                        <div className="card-body"> 
                            <div className="form-group">
                                    <label>New Password</label>
                                    <input name="newPassword" type="password" placeholder="New Password" className="form-control"  onChange={this.handleFieldChange} />
                                </div>
                                <div className="form-group">
                                    <label>Confirm Password</label>
                                    <input name="verifyPassword" type="password" placeholder="Confirm Password" className="form-control"  onChange={this.handleFieldChange} />
                                </div>
                        </div>
                    </div>
                    <div className="card-footer ml-auto mr-auto">
                        <button type="submit" onClick={this.handleSubmit} className="btn btn-warning btn-wd">Submit</button>
                        <hr />
                        <a href="/auth/register">Not a member? Register here</a>
                        <hr />
                        <a href="/auth/forgotpwd">Forgot Password? Reset here</a>
                        <hr />
                        <a href="/auth/login">Already a member? Login here</a>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        return (
          <div className="container auth-form-container">
            <div className="row">
              <div className="col-md-6 mr-auto ml-auto">
                {this.renderResetForm()}
              </div>
            </div>
            {this.state.openOverlay && <LoadingOverlay />}
          </div>
        )
    }
}

ResetPassword.propTypes = {};

export default connect(null, mapDispatchToProps)(withSnackbar(ResetPassword));