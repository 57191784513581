export const TranStyle = theme => ({
  /**
   * modified ag grid default theme
   */

  agThemeModified: {
    height: '100%'
  },

  /**
   * if browser width is less or equal to 1800px
   */

  // '@media screen and (max-width: 1800px)': {
  //   tranContainer: {
  //     height: '72vh'
  //   }
  // },

  /**
   *  release dropdown select container
   */

  tranAccountContainer: {
    marginTop: '2px',
    padding: '4px',
    minWidth: '40vw',
    maxWidth: '50vw'
  },

  /**
   *  release select field
   */
  tranAccountSelect: {
    display: 'flex'
  },


  // tranAccountSelectWidth: {
  //   width: 'auto', 
  //   minWidth: '300px'
  // },

  
	// newReleaseButton            : {
	// 	margin : theme.spacing(2)
	// },

	button            : {
		margin : theme.spacing(0)
	},
	newAccountModal             : {
		paddingTop    : '10px',
		paddingBottom : '10px',
		maxWidth      : '470px',
		minHeight     : '270px',
		maxHeight     : '470px',
		paddingRight  : '1.2rem',
		paddingLeft   : '1.2rem'
  },
  
	newAccountModalActions      : {
		display        : 'flex',
		justifyContent : 'space-evenly',
		marginTop      : '10px',
		marginBottom   : '15px',
		paddingRight   : '1.2rem',
		paddingLeft    : '1.2rem'
  },
  
  
	textField                   : {
		paddingTop : '5px',
		textAlign  : 'left',
		width      : '100%'
	},
	sqlErrorMessage             : {
		marginTo : '5px',
		color    : 'red'
  },

});

