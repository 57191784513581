import { skeletonTran } from './skeletonTran';
import Constants from '../utils/constants';
import { isArray } from 'util';
// cell renderer class
function MedalCellRenderer() {}

// init method gets the details of the cell to be rendere
MedalCellRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('span');
    var text = '';
    // one star for each medal
    for (var i = 0; i<params.value; i++) {
        text += '#';
    }
    this.eGui.innerHTML = text;
};

MedalCellRenderer.prototype.getGui = function() {
    return this.eGui;
};

export const TranGrid = (roles={owner:true, admin:false, editor: false, viewer: false}) => {
    return {
  defaultColDef: {
    resizable: true,
    filter: true,
    sortable: true,
    rowDrag: true,
    // tooltipComponent: 'customTooltip'
  },
  components:{
    'boldRenderer': function(params) {
        return '<b>' + params.value.name + '</b>';
    },
    'dateFormatter': function(params) {
      return '<b>' + params.value.name + '</b>';
    },
    'MedalCellRenderer': MedalCellRenderer
  },
  columns: [{
      headerCheckboxSelection: true,
      width: 60,
      checkboxSelection: true
    },{
      headerName: skeletonTran.aliases['sno'],
      field: 'sno',
      width: 70,
      sortable: true
    },/* {
      headerName: skeletonTran.aliases['account'],
      width: 140,
      field: 'account',
      sortable: true
    }, */{
      headerName: skeletonTran.aliases['date'],
      width: 120,
      field: 'date',
      sortable: true,
      filter: 'date',
      cellRenderer: function(params) {
        try {
          return params.value.slice(0,10);  
        } catch (error) {
          return params.value;
        }
      },
      filterParams: {
        comparator: Constants.agGridComparatorDate
      }
    },
    {
      width: 60,
      rowDrag: true
    }, {
      headerName: skeletonTran.aliases['desc'],
      width: 360,
      field: 'desc',
      sortable: true,
      filter: true
    },/* {
      headerName: skeletonTran.aliases['type'],
      width: 100,
      field: 'type',
      sortable: true,
      filter: true
    }, */{
      headerName: 'Type Name',
      width: 140,
      field: 'typeName',
      sortable: true,
      filter: true
    }/* ,{
      headerName: skeletonTran.aliases['curr'],
      width: 90,
      field: 'curr',
      sortable: true,
      filter: true
    } */,{
      headerName: skeletonTran.aliases['cr'],
      width: 120,
      field: 'cr',
      sortable: true,
      filter: true,
      cellStyle: {textAlign: "right"},
      cellRenderer: function(params) {
        if (params.value === 0 || params.value === '0.00') {
          return '-';
        }
        return parseFloat(params.value).toFixed(2);
      }
      // cellRenderer: 'MedalCellRenderer'
    },{
      headerName: skeletonTran.aliases['dr'],
      width: 120,
      field: 'dr',
      sortable: true,
      filter: true,
      cellStyle: {textAlign: "right"},
      cellRenderer: function(params) {
        if (params.value === 0 || params.value === '0.00') {
          return '-';
        }
        return parseFloat(params.value).toFixed(2);
      }
    },{
      headerName: skeletonTran.aliases['bal'],
      width: 150,
      field: 'bal',
      filter: true,
      cellStyle: {textAlign: "right"},
      cellRenderer: function(params) {
        return Constants.toCurrency(Number(params.value), params.data.curr);
        // return parseFloat(params.value).toFixed(2);
      }
    },{
      headerName: skeletonTran.aliases['qtr'],
      width: 100,
      field: 'qtr',
      sortable: true,
      filter: true
    },{
        headerName: 'R',
        field: 'receipts',
        width: 70,
        sortable: true,
        cellRenderer: function(params) {
            try {
                if (isArray(params.value) && params.value.length > 0)
                    return '📎';
                else return ''
            } catch (error) {
                return '';
            }
        },
    },{
      headerName: skeletonTran.aliases['note'],
      width: 260,
      field: 'note',
      sortable: true,
      filter: true
    },{
      headerName: 'Link',
      field: 'link_io',
      width: 80,
      sortable: true,
      filter: true,
      hide: !roles.owner,
      cellRenderer: function(params) {
        if (params.value === 'i') {
          return 'In';
        } else if (params.value === 'o') {
          return 'Out';
        } else {
          return '';
        }
      }
    },{
      headerName: 'Beneficiary',
      width: 180,
      field: 'link_ac.code',
      sortable: true,
      filter: true,
      hide: !roles.owner,
      cellRenderer: function(params) {
        if (params.data.link_ac && params.data.link_ac._id) {
          return `<a target="ac_${params.data.link_ac._id}" href="/transactions/ac/${params.data.link_ac._id}/link/${params.data.link}">${params.value}</>`;
        } else {
          return params.value;
        }
      }
    }
  ],
  
  columnsLocked: [{
      headerName: skeletonTran.aliases['sno'],
      field: 'sno',
      width: 70,
      sortable: true
    },{
      headerName: skeletonTran.aliases['date'],
      width: 120,
      field: 'date',
      sortable: true,
      filter: 'date',
      cellRenderer: function(params) {
        try {
          return params.value.slice(0,10);  
        } catch (error) {
          return params.value;
        }
      },
      filterParams: {
        comparator: Constants.agGridComparatorDate
      }
    },{
      headerName: skeletonTran.aliases['desc'],
      width: 360,
      field: 'desc',
      sortable: true,
      filter: true
    }/* ,{
      headerName: skeletonTran.aliases['type'],
      width: 100,
      field: 'type',
      sortable: true,
      filter: true
    } */,{
      headerName: 'Type Name',
      width: 140,
      field: 'typeName',
      sortable: true,
      filter: true
    },/* {
      headerName: skeletonTran.aliases['curr'],
      width: 90,
      field: 'curr',
      sortable: true,
      filter: true
    }, */{
      headerName: skeletonTran.aliases['cr'],
      width: 120,
      field: 'cr',
      sortable: true,
      filter: true,
      cellStyle: {textAlign: "right"},
      cellRenderer: function(params) {
        if (params.value === 0 || params.value === '0.00') {
          return '-';
        }
        return parseFloat(params.value).toFixed(2);
      }
    },{
      headerName: skeletonTran.aliases['dr'],
      width: 120,
      field: 'dr',
      sortable: true,
      filter: true,
      cellStyle: {textAlign: "right"},
      cellRenderer: function(params) {
        if (params.value === 0 || params.value === '0.00') {
          return '-';
        }
        return parseFloat(params.value).toFixed(2);
      }
    },{
      headerName: skeletonTran.aliases['bal'],
      width: 150,
      field: 'bal',
      filter: true,
      cellStyle: {textAlign: "right"},
      cellRenderer: function(params) {
        return Constants.toCurrencyES6(Number(params.value), params.data.curr);
        // let locale = Constants.getCurrencyLocale(params.data.curr);
        // let bal = Number(params.value);
        // bal = bal.toLocaleString(locale, { style: 'currency', currency: curr });
        // return bal;
        // return parseFloat(params.value).toFixed(2);
      }
    }/* ,{
      headerName: skeletonTran.aliases['qtr'],
      width: 100,
      field: 'qtr',
      sortable: true,
      filter: true
    } */,{
        headerName: 'R',
        field: 'receipts',
        width: 70,
        sortable: true,
        cellRenderer: function(params) {
            try {
                if (isArray(params.value) && params.value.length > 0)
                    return '📎';
                else return ''
            } catch (error) {
                return '';
            }
        },
    },{
      headerName: skeletonTran.aliases['note'],
      width: 260,
      field: 'note',
      sortable: true,
      filter: true
    },{
      headerName: 'Link',
      field: 'link_io',
      width: 80,
      sortable: true,
      filter: true,
      hide: !roles.owner,
      cellRenderer: function(params) {
        if (params.value === 'i') {
          return 'In';
        } else if (params.value === 'o') {
          return 'Out';
        } else {
          return '';
        }
      }
    },{
      headerName: 'Beneficiary',
      width: 180,
      field: 'link_ac.code',
      sortable: true,
      filter: true,
      hide: !roles.owner,
      cellRenderer: function(params) {
        if (params.data.link_ac && params.data.link_ac._id) {
          return `<a target="ac_${params.data.link_ac._id}" href="/transactions/ac/${params.data.link_ac._id}/link/${params.data.link}">${params.value}</>`;
        } else {
          return params.value;
        }
      }
    }
  ],

  
  columnsLockedChit: [{
    headerName: skeletonTran.aliases['sno'],
    field: 'sno',
    width: 70,
    sortable: true
  },{
    headerName: skeletonTran.aliases['date'],
    width: 110,
    field: 'date',
    sortable: true,
    filter: 'date',
    cellRenderer: function(params) {
      try {
        return params.value.slice(0,10);  
      } catch (error) {
        return params.value;
      }
    },
    filterParams: {
      comparator: Constants.agGridComparatorDate
    }
  },{
    headerName: skeletonTran.aliases['desc'],
    width: 320,
    field: 'desc',
    sortable: true,
    filter: true
  }/* ,{
    headerName: skeletonTran.aliases['type'],
    width: 100,
    field: 'type',
    sortable: true,
    filter: true
  } */,{
    headerName: 'Type Name',
    width: 140,
    field: 'typeName',
    sortable: true,
    filter: true
  },{
    headerName: 'Term #',
    field: 'chit_info.term',
    width: 80,
    filter: 'number',
    sortable: true
  },{
    headerName: 'Term Date',
    field: 'chit_info.date',
    width: 140,
    filter: 'date',
    sortable: true
  },{
    headerName: 'Subscriber',
    field: 'chit_info.sub.name',
    width: 140,
    filter: true,
    sortable: true
  },{
    headerName: 'Pay Status',
    field: 'chit_info.status',
    width: 140,
    filter: true,
    sortable: true,
    cellStyle:(params)=>{
      if (params.value === 'paid') 
        return {
          color: '#125d12',
          'background-color': '#2eca2e',
          'font-size': 'medium',
          'text-align': 'center'
        }
      else
        return {
          color: '#710000',
          'background-color': '#d48590',
          'font-size': 'medium',
          'text-align': 'center'
        }
    }
  },/* {
    headerName: skeletonTran.aliases['curr'],
    width: 90,
    field: 'curr',
    sortable: true,
    filter: true
  }, */{
    headerName: skeletonTran.aliases['cr'],
    width: 120,
    field: 'cr',
    sortable: true,
    filter: true,
    cellStyle: {textAlign: "right"},
    cellRenderer: function(params) {
      if (params.value === 0 || params.value === '0.00') {
        return '-';
      }
      return parseFloat(params.value).toFixed(2);
    }
  },{
    headerName: skeletonTran.aliases['dr'],
    width: 120,
    field: 'dr',
    sortable: true,
    filter: true,
    cellStyle: {textAlign: "right"},
    cellRenderer: function(params) {
      if (params.value === 0 || params.value === '0.00') {
        return '-';
      }
      return parseFloat(params.value).toFixed(2);
    }
  },{
    headerName: skeletonTran.aliases['bal'],
    width: 150,
    field: 'bal',
    filter: true,
    cellStyle: {textAlign: "right"},
    cellRenderer: function(params) {
      return Constants.toCurrencyES6(Number(params.value), params.data.curr);
      // let locale = Constants.getCurrencyLocale(params.data.curr);
      // let bal = Number(params.value);
      // bal = bal.toLocaleString(locale, { style: 'currency', currency: curr });
      // return bal;
      // return parseFloat(params.value).toFixed(2);
    }
  }/* ,{
    headerName: skeletonTran.aliases['qtr'],
    width: 100,
    field: 'qtr',
    sortable: true,
    filter: true
  } */,{
      headerName: 'R',
      field: 'receipts',
      width: 70,
      sortable: true,
      cellRenderer: function(params) {
          try {
              if (isArray(params.value) && params.value.length > 0)
                  return '📎';
              else return ''
          } catch (error) {
              return '';
          }
      },
  },{
    headerName: skeletonTran.aliases['note'],
    width: 260,
    field: 'note',
    sortable: true,
    filter: true
  },{
    headerName: 'Link',
    field: 'link_io',
    width: 80,
    sortable: true,
    filter: true,
    hide: !roles.owner,
    cellRenderer: function(params) {
      if (params.value === 'i') {
        return 'In';
      } else if (params.value === 'o') {
        return 'Out';
      } else {
        return '';
      }
    }
  },{
    headerName: 'Beneficiary',
    width: 180,
    field: 'link_ac.code',
    sortable: true,
    filter: true,
    hide: !roles.owner,
    cellRenderer: function(params) {
      if (params.data.link_ac && params.data.link_ac._id) {
        return `<a target="ac_${params.data.link_ac._id}" href="/transactions/ac/${params.data.link_ac._id}/link/${params.data.link}">${params.value}</>`;
      } else {
        return params.value;
      }
    }
  }
],

  
  columnsCompact: [{
      headerName: '#',
      field: 'sno',
      width: 46,
      sortable: true
    },{
      headerName: skeletonTran.aliases['date'],
      width: 100,
      field: 'date',
      sortable: true,
      filter: 'date',
      cellRenderer: function(params) {
        try {
          return params.value.slice(0,10);  
        } catch (error) {
          return params.value;
        }
      },
      filterParams: {
        comparator: Constants.agGridComparatorDate
      }
    },{
      headerName: skeletonTran.aliases['desc'],
      width: 260,
      field: 'desc',
      sortable: true,
      filter: true
    },{
      headerName: 'Type Name',
      width: 140,
      field: 'typeName',
      sortable: true,
      filter: true
    },{
      headerName: skeletonTran.aliases['cr'],
      width: 120,
      field: 'cr',
      sortable: true,
      filter: true,
      cellStyle: {textAlign: "right"},
      cellRenderer: function(params) {
        if (params.value === 0 || params.value === '0.00') {
          return '-';
        }
        return parseFloat(params.value).toFixed(2);
      }
    },{
      headerName: skeletonTran.aliases['dr'],
      width: 120,
      field: 'dr',
      sortable: true,
      filter: true,
      cellStyle: {textAlign: "right"},
      cellRenderer: function(params) {
        if (params.value === 0 || params.value === '0.00') {
          return '-';
        }
        return parseFloat(params.value).toFixed(2);
      }
    },{
      headerName: skeletonTran.aliases['bal'],
      width: 150,
      field: 'bal',
      filter: true,
      cellStyle: {textAlign: "right"},
      cellRenderer: function(params) {
        return Constants.toCurrency(Number(params.value), params.data.curr);
      }
    },{
      headerName: skeletonTran.aliases['qtr'],
      width: 100,
      field: 'qtr',
      sortable: true,
      filter: true
    },{
        headerName: 'R',
        field: 'receipts',
        width: 70,
        sortable: true,
        cellRenderer: function(params) {
            try {
                if (isArray(params.value) && params.value.length > 0)
                    return '📎';
                else return ''
            } catch (error) {
                return '';
            }
        },
    },{
      headerName: skeletonTran.aliases['note'],
      width: 200,
      field: 'note',
      sortable: true,
      filter: true
    },{
      headerName: 'Link',
      field: 'link_io',
      width: 80,
      sortable: true,
      filter: true,
      hide: !roles.owner,
      cellRenderer: function(params) {
        if (params.value === 'i') {
          return 'In';
        } else if (params.value === 'o') {
          return 'Out';
        } else {
          return '';
        }
      }
    },{
      headerName: 'Beneficiary',
      width: 180,
      field: 'link_ac.code',
      sortable: true,
      filter: true,
      hide: !roles.owner,
      cellRenderer: function(params) {
        if (params.data.link_ac && params.data.link_ac._id) {
          return `<a target="ac_${params.data.link_ac._id}" href="/transactions/${params.data.link_ac._id}">${params.value}</>`;
        } else {
          return params.value;
        }
      }
    }
  ],

  
  columnsCompactUnlocked: [{
    headerCheckboxSelection: true,
    width: 40,
    checkboxSelection: true
  },{
    headerName: '#',
    field: 'sno',
    width: 46,
    sortable: true
  },{
    headerName: skeletonTran.aliases['date'],
    width: 100,
    field: 'date',
    sortable: true,
    filter: 'date',
    cellRenderer: function(params) {
      try {
        return params.value.slice(0,10);  
      } catch (error) {
        return params.value;
      }
    },
    filterParams: {
      comparator: Constants.agGridComparatorDate
    }
  },{
    width: 40,
    rowDrag: true
  }, {
    headerName: skeletonTran.aliases['desc'],
    width: 260,
    field: 'desc',
    sortable: true,
    filter: true
  },{
    headerName: 'Type Name',
    width: 140,
    field: 'typeName',
    sortable: true,
    filter: true
  },{
    headerName: skeletonTran.aliases['cr'],
    width: 120,
    field: 'cr',
    sortable: true,
    filter: true,
    cellStyle: {textAlign: "right"},
    cellRenderer: function(params) {
      if (params.value === 0 || params.value === '0.00') {
        return '-';
      }
      return parseFloat(params.value).toFixed(2);
    }
  },{
    headerName: skeletonTran.aliases['dr'],
    width: 120,
    field: 'dr',
    sortable: true,
    filter: true,
    cellStyle: {textAlign: "right"},
    cellRenderer: function(params) {
      if (params.value === 0 || params.value === '0.00') {
        return '-';
      }
      return parseFloat(params.value).toFixed(2);
    }
  },{
    headerName: skeletonTran.aliases['bal'],
    width: 150,
    field: 'bal',
    filter: true,
    cellStyle: {textAlign: "right"},
    cellRenderer: function(params) {
      return Constants.toCurrency(Number(params.value), params.data.curr);
    }
  },{
    headerName: skeletonTran.aliases['qtr'],
    width: 100,
    field: 'qtr',
    sortable: true,
    filter: true
  },{
        headerName: 'R',
        field: 'receipts',
        width: 70,
        sortable: true,
        cellRenderer: function(params) {
            try {
                if (isArray(params.value) && params.value.length > 0)
                    return '📎';
                else return ''
            } catch (error) {
                return '';
            }
        },
    },{
    headerName: skeletonTran.aliases['note'],
    width: 200,
    field: 'note',
    sortable: true,
    filter: true
  },{
    headerName: 'Link',
    field: 'link_io',
    width: 80,
    sortable: true,
    filter: true,
      hide: !roles.owner,
    cellRenderer: function(params) {
      if (params.value === 'i') {
        return 'In';
      } else if (params.value === 'o') {
        return 'Out';
      } else {
        return '';
      }
    }
  },{
    headerName: 'Beneficiary',
    width: 180,
    field: 'link_ac.code',
    sortable: true,
    filter: true,
      hide: !roles.owner,
    cellRenderer: function(params) {
      if (params.data.link_ac && params.data.link_ac._id) {
        return `<a target="_blank" href="/transactions/${params.data.link_ac._id}">${params.value}</>`;
      } else {
        return params.value;
      }
    }
  }],
}
};

// TranGrid.columns = ([{
//     width: 40,
//     rowDrag: true
//   },{
//     headerCheckboxSelection: true,
//     width: 40,
//     checkboxSelection: true
//   }]).concat(TranGrid.columnsLocked);
