export const ContributorStyle = theme => ({
  /**
   * modified ag grid default theme
   */

  agThemeModified: {
    height: '100%'
  },

  /**
   * ag grid tran container
   */

  tranContainer: {
    height: '80vh',
    marginTop: '3vh',
    marginLeft: '2vw',
    width: '94%'
  },

  /**
   * if browser width is less or equal to 1800px
   */

  '@media screen and (max-width: 1800px)': {
    tranContainer: {
      height: '72vh'
    }
  },

  /**
   *  release dropdown select container
   */

  tranReleaseContainer: {
    padding: '15px',
    minWidth: '270px',
    maxWidth: '270px'
  },

  /**
   *  release select field
   */
  tranReleaseSelect: {
    display: 'flex'
  },

  // tranReleaseSelectWidth: {
  //   width: 'auto', 
  //   minWidth: '300px'
  // },

  '@media screen and (max-width: 400px)': {
    tranReleaseSelectWidth: {
      width: 'auto', 
      maxWidth: '90vw'
    },
  },
  '@media screen and (min-width: 401px)': {
    tranReleaseSelectWidth: {
      width: 'auto', 
      minWidth: '300px',
      maxWidth: '90vw'
    },
  },
  
	newReleaseButton            : {
		margin : theme.spacing(2)
	},

	button            : {
		margin : theme.spacing(2)
	},
	newReleaseModal             : {
		paddingTop    : '10px',
		paddingBottom : '10px',
		maxWidth      : '470px',
		minHeight     : '340px',
		maxHeight     : '340px',
		paddingRight  : '1.2rem',
		paddingLeft   : '1.2rem'
  },
  
	newReleaseModalActions      : {
		display        : 'flex',
		justifyContent : 'space-evenly',
		marginTop      : '10px',
		marginBottom   : '15px',
		paddingRight   : '1.2rem',
		paddingLeft    : '1.2rem'
  },
  
  
	textField                   : {
		paddingTop : '5px',
		textAlign  : 'left',
		width      : '100%'
	},
	sqlErrorMessage             : {
		marginTo : '5px',
		color    : 'red'
	},
});
