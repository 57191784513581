import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep as _cloneDeep } from 'lodash';
import { withSnackbar } from 'notistack';

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, LinearProgress, Dialog } from '@material-ui/core';

import '../tran/Tran.css'
import {TranStyle} from '../tran/TranStyle';
import LoadingOverlay from '../common/loadingOverlay';
import { accountCCStmtScheduleObj, mapAccount, mapAccountSchedule } from './acctObject';
import { getInstance } from '../utils/axiosLoader';
import Constants from '../utils/constants';
import mapStateToProps from '../actions/stateToProps';
import moment from 'moment-timezone';
import UIHandler from '../utils/UIHandler';
import { TransactionHandler } from '../api';

let classes = null;

class AccountStmtSchedule extends Component {
	constructor(props) {
		super(props)
		classes = this.props.classes;
		this._handler = new TransactionHandler(this);
	}

	state = {
		actionInProgress      : {
			openLinearLoading   : false,
			disableActionButton : false
		},
		openOverlay: false,
		open: {
			openNewScheduleDialog: false
		},
		roles: this.props.roles,
		readOnly: this.props.readOnly,
		hasSchedule: (this.props.selectedAccount.schedule && Object.keys(this.props.selectedAccount.schedule).length > 0),
		enableAddSchedule: false,
		enableDeleteSchedule: false,
		contributorEditMode: false,
		enableEditSchedule: false,
		sqlErrorMessage: '',
		account: mapAccount(this.props.selectedAccount),
		multiUpdateField: 'type',
        annualFeeFlag: false,
		selectedAccount: this.props.selectedAccount,
        selectedFrequency: 'monthly',
        selectedFrequencyInc: 'months',
		schedule: _cloneDeep(accountCCStmtScheduleObj)
	};

	componentDidMount() {
		console.log('this.props.selectedAccount', this.props.selectedAccount)
	  this.handleCheckPermissions();
	}
  
	handleCheckPermissions = async () => {
	//   this.setState({
	// 	openOverlay: true
	//   })
	//   let { _id } = this.state.selectedAccount;
	//   this._accountHandler.getAccountMembers(_id).then((members) => {
		let stateUpdates = {
		//   members,
		//   openOverlay: false,
		  enableAddSchedule: !this.state.hasSchedule && (this.state.roles.owner || this.state.roles.admin),
		  enableDeleteSchedule: false,
		  enableEditSchedule: false,
		};
		if (this.state.hasSchedule) { 
			stateUpdates.schedule = mapAccountSchedule(this.props.selectedAccount.schedule)
		}
		this.setState(stateUpdates);
	//   })
	}

	performBusinessChecks = (_schedule) => {
		//check if available credit is less than or equal to 'limit'
		// let _schedule = this.state.schedule;
		let {available, limit} = _schedule;
		if (parseFloat(available.value) > parseFloat(limit.value)) {
			available.hasErrors.error = true;
			available.hasErrors.message = `Available credit cannot be more than Credit limit`;
		} else {
			available.hasErrors.error = false;
			available.hasErrors.message = '';
		}
		this.setState({
			schedule: _schedule
		})
	}

	preparePayload = (_data = null) => {
		let _payload = {};
		let _keys = Object.keys(_data);
		for (let key of _keys) {
			if (key !== '_id') {
				_payload[key] = _data[key].value;
			}
		}
		if (!_payload['annual_fee_flag']) {
			delete _payload['annual_fee'];
			delete _payload['annual_fee_on'];
		}
		return _payload;
	};

	handleUpdateAccount = async () => {
		// this.props.enqueueSnackbar('This action is not ready yet', {
		// 	variant : 'warning'
		// });
		// return;
		// let _account = this.state.account;
		/* TODO: make this error disable the submit button in 'FieldsErrorFree' logic similar to new list item. */
		if (this.props.account.code === 'unknown') {
			this.props.enqueueSnackbar('Selected Account is invalid. Transaction cannot be added.', {
				variant : 'error'
			});
			return;
		}
		let _schedule = this.state.schedule;
		let _actionInProgress = this.state.actionInProgress;
		// _actionInProgress.disableActionButton = true;
		// _actionInProgress.openLinearLoading = true;
		// this.setState({
		// 	actionInProgress : _actionInProgress
		// });
		
		let preparedPayload = this.preparePayload(_schedule);
		console.log('preparedPayload', preparedPayload);
		// return;	
		//inject chit_info
		// preparedPayload.chit_info = this.state.tranChitInfo;
		// console.log('preparedReleaseObject', preparedReleaseObject)
		let responseErr = '';
		await getInstance()
			.post(`${Constants.URLs.ACCOUNT_SCHEDULE}/ac/${this.state.selectedAccount._id}`, preparedPayload)
			.then(async (response) => {
				if (response.status === 400) {
					console.log(response)
					this.props.enqueueSnackbar('Error: An internal error occured while saving account schedule.', {
						variant : 'error'
					});
					responseErr = response.data.message
				}
				else {
					this.props.enqueueSnackbar('Account schedule updated successfully', {
						variant : 'success'
					});
					// _transaction = _cloneDeep(tranObject)
					// _schedule = _cloneDeep(accountCCStmtScheduleObj)
				}
			})
			.catch((error) => {
				console.log(error);
                let msg = 'An error occurred from the server';
                try {
                    if (error.response.data.message) msg = error.response.data.message
                } catch (error) {}
                responseErr = msg;
				this.props.enqueueSnackbar(msg, {
					variant : 'error'
				});
			})
			.finally(()=>{
				_actionInProgress.disableActionButton = false;
				_actionInProgress.openLinearLoading = false;
				this.setState({
					sqlErrorMessage  : responseErr,
					// transaction: _transaction,
					// schedule: _schedule,
					actionInProgress : _actionInProgress
				});
				if (responseErr === '') {
					this.props.onSubmit();
				}
			})
	}


	handleOpenModal = (modal = "schedule-add") => {
		let showModal = this.state.open;
		// showModal.contributorDeleteConfirmation = (modal === "schedule-delete");
		showModal.openNewScheduleDialog = (modal === "schedule-add" || modal === "schedule-edit");

		this.setState({
			open: showModal,
			readOnly: false,
			contributorEditMode: (modal === "schedule-edit")
		})
	}

	handleCloseModal = (modal = "schedule") => {
		let showModal = this.state.open;
		// showModal.contributorDeleteConfirmation = false;
		showModal.openNewScheduleDialog = false;
		this.setState({
			open: showModal,
			readOnly: true
		});
		// if (modal === "schedule") {
		// 	this.handleGetMembers()
		// }
	};

	/**
	 * Render UI components
	 */

	renderFormTitle() {
		return (
			<UIHandler
				type={"header"}
				field={{
					heading: this.props.account.name,
					subheading: "Statement Schedule"
				}}
				/>
		)
	}

    // handleFrequencyChange(e, id) {
    //     let f = e.target.value;
	// 	let _schedule = this.state.schedule;
	// 	let _frequency = _schedule[id];
	// 	let _inList = _frequency.rules.inList;
	// 	let _selected = _inList.filter(item => item.code === f)[0];
	// 	// _frequency.value = f;

    //     console.log("setting freqency: ", f, _selected);
    //     this.setState({ selectedFrequency: f, selectedFrequencyInc: _selected.inc /*, schedule: _schedule*/  })
    // }
    // renderFormFieldFrequency(field, items, defaultItem) {
	// 	// let _date = this.state.account.date
	// 	// _date.id = "freq_end_date"
	// 	// _date.alias = "End Date:"
    //     return (
    //         // <Grid item md={12} lg={12} xs={12}>
	// 			<FormControl
	// 				readOnly={this.state.readOnly}>
	// 			<InputLabel>Frequency:</InputLabel>
	// 			<Select
	// 				label={field.alias}
	// 				name={field.id}
	// 				defaultValue={defaultItem}
	// 				// value={this.state.schedule.frequency.code}
	// 				readOnly={this.state.readOnly}
	// 				onChange={(e) => this.handleFrequencyChange(e, field.id)}>
	// 				{items.map((item, index) => {
	// 					return (
	// 						<MenuItem
	// 							key={`freq-${item.code}`}
	// 							value={item.code}>
	// 							{`${item.name}`}
	// 						</MenuItem>
	// 					);
	// 				})}
	// 			</Select>
	// 			</FormControl>
    //         // </Grid>
    //     )
    // }

	genericField(field, _readOnly=false) {
		return (
			<UIHandler 
				field={field} 
				noGrid={true} 
				fieldStyle={{marginTop: '10px'}} 
				type={field.type || "text"} 
				onRefreshed={(_field)=>{
					let _schedule = this.state.schedule;
					_schedule[field.id] = _field;
					// this.setState({schedule: _schedule})
					this.performBusinessChecks(_schedule);
				}}
				readOnly={this.state.readOnly || _readOnly} />
		)
	}


    handleAnnualFeeCheckbox() {
		let _flag = !this.state.annualFeeFlag;
		let _schedule = this.state.schedule;
		_schedule.annual_fee_flag.value = _flag;
        this.setState({ annualFeeFlag: _flag, schedule: _schedule });
    }
	renderAnnualFeeCheckbox() {
		return (
			<UIHandler
				type={"checkbox"}
				field={{alias: "Annual Fee?"}}
				onClick={()=>this.handleAnnualFeeCheckbox()}
				checked={this.state.schedule.annual_fee_flag.value}
				noGrid={true}
				readOnly={this.state.readOnly}
				/>
		)
	}
	
	renderSQLError() {
		return (
			<Grid item md={12} lg={12} xs={12}>
				<Typography 
					variant="caption" 
					align="center" 
					className={classes.sqlErrorMessage}>
					{this.state.sqlErrorMessage}
				</Typography>
			</Grid>
		)
	}

	handlePreviewAction() {
		this.setState({
			openOverlay: true
		})
		this._handler.reorderAccount().finally(()=>{
			getInstance().get(`${Constants.URLs.STATEMENT}/preview/ac/${this.state.selectedAccount._id}`)
			.then((response)=>{
				let html = response.data;
				let previewTab = window.open(`/statement-preview/ac/${this.state.selectedAccount._id}`);
				previewTab.document.write(html);
	
				//to inject pdf instead use	
				// previewTab.document.write("<html<head><title>"+fileName+"</title><style>body{margin: 0px;}</style></head>");
				// previewTab.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(base64Data)+"#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>");
				previewTab.document.close();
			}).finally(()=>{
				this.setState({
					openOverlay: false
				})
			})
		})
	}
	
	renderActions() {
		return (
			<div className={classes.newAccountModalActions}>
				{!this.state.readOnly && <Button
					variant="contained"
                    color="primary"
                    data-cy="btnTranCancel"
					className={classes.button}
					onClick={() => this.handleCloseModal()}>
					Cancel
				</Button>}
				{this.state.readOnly && this.props.actionUpdate && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableActionButton}
					onClick={()=>this.setState({readOnly: false})}>
					Edit
				</Button>}
				{this.state.readOnly && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableActionButton}
					onClick={()=>this.handlePreviewAction()}>
					Preview Statement
				</Button>}
				{!this.state.readOnly && this.props.actionUpdate && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableActionButton}
					onClick={this.handleUpdateAccount}>
					Update
				</Button>}
			</div>
		)
	}

	renderLinearLoader() {
		return (
			this.state.actionInProgress.openLinearLoading &&
				<LinearProgress color="primary" variant="query" />
		)
	}


	renderAsModal() {
		let _schedule = this.state.schedule
		console.log('render->_schedule', _schedule);
		let {
			limit,
			available,
			stmt_start_date,
			// frequency,
			statement_date,
			next_statement_date,
			payment_due_date,
			next_payment_due_date,
			pu_rate,
			bt_rate,
			ca_rate,
			apr_rate,
			annual_fee_flag,
			annual_fee,
			annual_fee_on
		} = _schedule;

		// let _frequencyList = frequency.rules.inList;
		// let _defaultFrequency = _frequencyList.filter((item)=>item.default === true)[0].code;
		// console.log('_defaultFrequency', _defaultFrequency);
		let {
			annualFeeFlag,
			selectedFrequencyInc
		} = this.state;
		
		let _start_date = moment(stmt_start_date.value, "YYYY-MM-DD");
		let _next_statement_date = _start_date.clone().date(statement_date.value);
		if (_start_date.isAfter(_next_statement_date)) {
			_next_statement_date = _next_statement_date.clone().add(1, selectedFrequencyInc);
		}
		let _next_payment_due_date = _next_statement_date.clone().add(payment_due_date.value, "days");
		next_statement_date.value = _next_statement_date.format("YYYY-MM-DD");
		next_payment_due_date.value = _next_payment_due_date.format("YYYY-MM-DD");

		return (
			<div>
				<Grid container /*className={classes.newAccountModalActions}*/ data-cy="mdlCreateNewTranGrid">
					{this.renderFormTitle()}
					{/* {!this.state.readOnly && this.renderFormFieldFrequency(frequency, _frequencyList, frequency.value)} */}
					{!this.state.readOnly && this.genericField(stmt_start_date)}
					<Grid item  md={12} lg={12} xs={12}>
						{!this.state.readOnly && this.genericField(statement_date)}
						{this.genericField(next_statement_date, true)}
						</Grid>
					<Grid item  md={12} lg={12} xs={12}>
						{!this.state.readOnly && this.genericField(payment_due_date)}
						{this.genericField(next_payment_due_date, true)}
						</Grid>
					<Grid item  md={12} lg={12} xs={12}>
						{this.genericField(limit)}
						{this.genericField(available)}
						</Grid>
					<Grid item  md={12} lg={12} xs={12}>
						{this.genericField(pu_rate)}
						{this.genericField(bt_rate)}
						{this.genericField(ca_rate)}
						{this.genericField(apr_rate)}
						</Grid>
					<Grid item  md={12} lg={12} xs={12}>
						{/* <span><Checkbox onClick={()=>this.handleAnnualFeeCheckbox()}/>Repeat</span> */}
						{!this.state.readOnly && this.renderAnnualFeeCheckbox()}
						</Grid>
					<Grid item  md={12} lg={12} xs={12}>
						{annual_fee_flag.value && this.genericField(annual_fee, !annualFeeFlag)}
						{annual_fee_flag.value && this.genericField(annual_fee_on, !annualFeeFlag)}
						</Grid>
					{this.renderSQLError()}
					{this.renderActions()}
					{this.renderLinearLoader()}

					{this.state.openOverlay && <LoadingOverlay text={`Generating statement preview...`} />}
				</Grid>
			</div>
		);
	}

	renderAsDialog() {
		return (
			<Dialog
				open={true}
				onClose={() => {}}
				styles={{ modal: { padding: 0 } }} >
					{this.renderAsModal()}
			</Dialog>
		)
	}

	renderScheduleGridActions() {
		return (
		  <Grid container style={{ margin: "4px" }}>
			<Grid item md={12} sm={12} xs={12}>
			  {this.state.enableAddSchedule && <Button disabled={!this.state.enableAddSchedule}
				onClick={() => this.handleOpenModal("schedule-add")}
				variant="contained"
				size="small"
				color="default">
				<i className="pe-7s-add-user"></i> Setup Schedule
						  </Button>}
			&nbsp;
			{this.state.enableEditSchedule && <Button disabled={!this.state.enableEditSchedule}
				onClick={() => this.handleOpenModal("schedule-edit")}
				variant="contained"
				size="small"
				color="primary">Edit</Button>}
			&nbsp;
			{/* {this.state.enableDeleteSchedule && <Button disabled={!this.state.enableDeleteSchedule}
				onClick={() => this.handleOpenModal("contributor-delete")}
				variant="contained"
				size="small"
				color="secondary">Delete</Button>} */}
			&nbsp;
		  </Grid>
		  </Grid>
		)
	  }
	
	  renderSetupSection() {
		// const { classes } = this.props;
		// const agStyles = `ag-theme-balham ${classes.agThemeModified}`;
		return (
		  <Grid container id='accountinfo-members-grid' /* style={{"height": "70%", width: "96%", marginLeft: "2%", marginTop: "10px"}} */ >
			<Grid item md={12} sm={12} xs={12}>
			  {this.renderScheduleGridActions()}
			  {(!this.state.hasSchedule) && <Typography>This account schedule yet to be setup.</Typography>}
			  {this.state.openOverlay && <LoadingOverlay />}
			  {this.state.open.openNewScheduleDialog && this.renderAsDialog()}
			  {/* {(this.state.selectedAccount.schedule) && 
			  	// <div className={agStyles}>
					this.renderAsModal()
			  	// </div>
				} */}
			</Grid>
		  </Grid>
		)
	  }

	  renderScheduleForm() {
		if (this.props.asDialog) {
			return this.renderAsDialog()
		} else {
			return this.renderAsModal()
		}
	  }
	
	  render() {
		if (this.state.hasSchedule) {
			console.log('Account schedule is found')
			return this.renderScheduleForm()
		} else {
			return this.renderSetupSection()
		}
		// return (
		//   <div>
		// 	{}
		// 	{/* <Dialog
		// 	  open={this.state.open.contributorDeleteConfirmation}><Typography>
		// 		{`Are you sure to delete the member?`}
		// 	  </Typography><Button
		// 		onClick={() => this.handleDeleteContributor()}
		// 	  >Yes</Button><Button onClick={() => this.handleCloseModal("")}>No</Button></Dialog> */}
	
	
		//   </div>
		// )
	  }
}

AccountStmtSchedule.propTypes = {
	classes : PropTypes.object.isRequired,
    display: PropTypes.bool.isRequired,
	asDialog: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	account: PropTypes.object.isRequired,
	isCredit: PropTypes.bool.isRequired,
	actionUpdate: PropTypes.bool.isRequired,
	multiMode: PropTypes.bool,
	readOnly: PropTypes.bool,
	onEnableEdit: PropTypes.func,
	roles: PropTypes.object.isRequired,
	selectedAccount: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(withSnackbar(withStyles(TranStyle)(AccountStmtSchedule)));
