import Storage from "./Storage";

const _isChached = (key) => {
    let check = Storage.getLocalItem(key);
    // console.log(`check cache key ${key}`, check);
    return check ? check : false;
};

const Cache = {
    
    isChached: _isChached,
    
    getCacheAge: (key) => {
        let _val = _isChached(key);
        if (!_val) return -2;
        let _ageSec = -1;
        try {
            _ageSec = Math.floor(((new Date().getTime()) - _val) / 1000);
        } catch (error) {
            console.warn(`Error while determining cached age of the key ${key} from value ${_val}`);
            console.error(error);
        }
        // if (_ageSec > 0) {
        //     _ageSec = Math.floor(_ageSec / 60)
        // }
        return  _ageSec;
    },

    getCachedItem: (key) => {
        // console.log('getCachedItem: ', key);
        return new Promise((resolve, reject)=>{
            if (!_isChached(key)) {
                reject(`key ${key} not cached yet.`);
            } else {
                return Storage.getIndexedItemPromise(key).then((value)=>{
                    if (value) {
                        // console.log(`cached value found for key ${key}`, value)
                        resolve(value);
                    } else {
                        reject(`empty cache value found for the key ${key}`)
                    }
                }).catch(err=>reject(err))
            }
        });
    },

    setCacheItem: (key, val) => {
        // console.log(`about to cache key ${key}`, val)
        return new Promise((resolve, reject)=>{
            return Storage.setIndexedItemPromise(key, val).then((value)=>{

                // console.log(`caching of key ${key} done.`, value)
                Storage.setLocalItem(key, new Date().getTime()); //TODO: set current timestamp
                resolve(value)
            }).catch(err=>reject(err))
        });
    },

    clear: (key) => {
        return new Promise((resolve, reject)=>{
            return Storage.removeIndexedItemPromise(key).then(()=>{
                Storage.removeLocalItem(key);
                resolve(true)
            }).catch(err=>reject(err))
        });
    }
}

export default Cache;