import localforage from 'localforage';

/**
 * @deprecated use Storage.js module for these implementations.
 */
const IndexDb = {
    setItem: function (key, val) {
        return new Promise((resolve, reject) => {
            localforage.setItem(key, val).then(function () {
                return localforage.getItem(key);
            }).then(function (value) {
                resolve(value);
            }).catch(function (err) {
                reject(`An error occured while storing the key ${key} to IndexDb. Error: ${err.message}`);
            });
        });
    },
    getItem: function (key) {
        return localforage.getItem(key);
    },
    removeItem: function(key) {
        return localforage.removeItem(key);
    },
    KEYS: {
        CURRENCIES: 'KEY_CURRENCIES',
        ACCT_TYPES: 'KEY_ACCOUNT_TYPES',
        PROFILE: 'KEY_PROFILE',
        ACCOUNTS: 'KEY_ACCOUNTS',
        TRAN_TYPES: 'KEY_TRAN_TYPES',
        USER_INFO: 'KEY_USER_INFO'
    },
}

export default IndexDb;