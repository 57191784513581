import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import {getInstance} from '../utils/axiosLoader';
import Constants from '../utils/constants'
import mapDispatchToProps from '../actions/dispatchProps';
import LoadingOverlay from '../common/loadingOverlay';
import './authstyle.css';

let _this=null;
let host = window.location.hostname;
let port = window.location.port;
let _domain=`https://${host}`;
if (host.includes('localhost') || host.includes('192.168')) {
    _domain = `http://${host}:${port}`;
} else if(host.includes('codecubers.lab')) {
    _domain = `http://${host}`;
} 
class Forgot extends Component {
    constructor(props) { 
        super(props) 
        this.handleFieldChange = this.handleFieldChange.bind(this);
        _this = this;
    };

    state = {
        openOverlay: false,
        form: {
            email: '',
            domain: _domain
        }
    };

    componentDidMount = () => {
		try {
			const {match: {params}} = this.props;
			if (params.email) {
                console.log("setting param email:" + params.email)
                let _form = this.state.form;
                _form.email = params.email
				this.setState({ form: _form })
			}	
		} catch (error) {
			console.log(error)
		}
	};

    handleFieldChange = (e) => {
        let _form = this.state.form;
        _form[e.target.name] = e.target.value;
        this.setState({ form: _form });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let instance = getInstance();
        // instance.defaults.headers['Content-Type'] = 'application/json';
        this.setState({ openOverlay: true })
        await instance
            .post(Constants.URLs.AUTH.FORGOT, this.state.form)
            .then(async (response) => {
                this.setState({ openOverlay: false })
                // console.log(response.data);
                if (response.status !== 200) {
                    this.props.enqueueSnackbar('Error: ' + response.data.msg, {
                        variant : 'error'
                    });
                }
                else {
                    this.props.enqueueSnackbar(response.data.msg, {
                        variant : 'success'
                    });
                    _this.props.history.push('/auth/login');
                }
            })
            .catch((err) => {
                this.setState({ openOverlay: false })
                console.log(err);
                let msg = 'Error: An internal error occured';
                if (err.response && err.response.data && err.response.data.message) {
                    msg = err.response.data.message;
                }
				this.props.enqueueSnackbar(msg, {
					variant : 'error'
				});
            })
    }

    renderForgotForm() {
        return (
            <form className="form" method="" /* action="" */ /* onSubmit={this.handleSubmit} */>
                <div className="card card-forgot text-center">
                    <div className="card-header ">
                        <div className="row  justify-content-center">
                            <div className="header-text">
                                <h3 className="card-title">Gumasta</h3>
                                <h6 className="card-subtitle">Free ledger to care your monies</h6>
                                <hr />
                                <h3 className="header text-center">Forgot Password</h3>
                            </div>
                        </div>
                    </div>
                    <div className="card-body ">
                        <div className="card-body">
                            <div className="form-group">
                                    <label>Email address</label>
                                    <input name="email" type="email" placeholder="Enter email" className="form-control" defaultValue={this.state.form.email}  onChange={this.handleFieldChange} />
                                </div>
                        </div>
                    </div>
                    {/* <br /> */}
                    <div className="card-footer ml-auto mr-auto">
                        <button type="submit" onClick={this.handleSubmit} className="btn btn-warning btn-wd">Send Reset Email</button>
                        <hr />
                        <a href="/auth/register">Not a member? Register here</a>
                        <br />
                        <a href="/auth/login">Already a member? Login here</a>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        return (
          <div className="container auth-form-container">
            <div className="row">
              <div className="col-md-6 mr-auto ml-auto">
                {this.renderForgotForm()}
              </div>
            </div>
            {this.state.openOverlay && <LoadingOverlay />}
          </div>
        )
    }
}

Forgot.propTypes = {};

export default connect(null, mapDispatchToProps)(withSnackbar(Forgot));