export default function mapStateToProps (state) {
  const { 
    pageTitle, 
    appState 
  } = state;
  
  return { 
    pageTitle, 
    appState 
  };
}