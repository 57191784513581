import React, { Component } from 'react'
import Modal from 'react-responsive-modal';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { withSnackbar } from 'notistack';
import {cloneDeep as _cloneDeep} from 'lodash';

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

import './Upload.css'
import OFXParser from './ofx-parser/index';
import { TranStyle } from '../tran/TranStyle';
import mapStateToProps from '../actions/stateToProps';
import DupCheckGrid from './components/DupCheckGrid';

class UploadOFX extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
        files: [],
        uploading: false,
        uploadProgress: {},
        successfullUploaded: false,
        ofxUploadRows: [],
        ofxUploadError: '',
        showActions: false,
        openSelector: true,
        actionInProgress      : {
          disableActionButton : false
        },
        rowsSelected: [],
        enabledDelete: false,
        processedData: [],
        preProcessedData: [],
        processedColDefs: [/* {
          headerName: 'Account', field: 'account', width: 180,
        }, */{
          headerName: 'Date', field: 'date', width: 110, tooltipField: 'dupData', tooltipComponentParams: { color: '#ececec' }
        },{
          headerName: 'Credit', field: 'cr', width: 90, tooltipField: 'dupData'
        },{
          headerName: 'Debit', field: 'dr', width: 90, tooltipField: 'dupData'
        },{
          headerName: 'Type', field: 'type', width: 100, tooltipField: 'dupData'
        },{
          headerName: 'Description', field: 'desc', width: 200,  tooltipField: 'dupData' 
        },{
          headerName: 'Note', field: 'note', width: 100, tooltipField: 'dupData'
        }],
      };
  }

  handleOnGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  handleRowHighlights = params => {
    if (params.data.CR > 0) return 'ofx-credit-row';
    if (params.data.DR > 0) return 'ofx-debit-row';
  }  

	onSelectionChanged() {
		let selectedRows = this.gridApi.getSelectedRows();
		this.setState({ rowsSelected: selectedRows });
		this.setState({ enabledDelete: (selectedRows.length > 0) })
  }
  
	handleDeleteSelection = async () => {
    let allRows = this.state.ofxUploadRows;
    let rowsToDelete = this.state.rowsSelected;
    //console.dir(allRows)
    rowsToDelete.forEach(function (item, index) {
      allRows.splice(allRows.indexOf(item),1);
    })
    console.dir(_cloneDeep(allRows))
    this.setState({ 
      enabledDelete: false, 
      rowsSelected: [], 
      ofxUploadRows: allRows 
    });
    this.gridApi.setRowData(allRows);

    //since rows changed; re-extract
    this.handleProcessData();
	}

  handleProcessData() {
		let trans = this.state.ofxUploadRows;
    //console.log('trans to process', trans);
    if (trans) {
      let ac = this.props.account._id;
      if (ac === 'unknown') {
        this.props.enqueueSnackbar('Selected Account is invalid. Upload cannot be completed.', {
          variant : 'error'
        });
        return;
      }

      let preProcessed = trans.reduce((arr, tran) => {
        let obj = {
          date: moment(tran.DTPOSTED, 'YYYYMMDDhhmmss').format('YYYY-MM-DDThh:mm:ss'),
          dr: parseFloat(tran.DR),
          cr: parseFloat(tran.CR),
          curr: tran.TRNCURR,
          type: tran.type,
          desc: tran.NAME,
          account: ac,
          duplicate: 0
        }
        //console.log("------> ", obj)
        arr.push(obj);
        return arr;
      },[]);

      this.setState({ 
        preProcessedData: preProcessed, 
        displayDupCheckGrid: true,
        openSelector: this.state.ofxUploadRows.length === 0
      });
    }
  }



  handleFileUpload(data) {
    if (!data.parsed) {
      console.dir(data);
      this.props.enqueueSnackbar('Unidentifyable OFX content', {
        variant : 'warning'
      });
      this.setState({ ofxUploadRows: [], ofxUploadError: 'Unidentifyable OFX content', showActions: false })
      return;
    }
    //console.dir(data);
    let type = data.type;
    let transactions = data.transactions.reduce((out, tran, index) => {
      tran.TRNCURR = data.currency;
      if (tran.TRNTYPE === 'DEBIT' && tran.TRNAMT.includes('-')) {
        if (type === 'cc') {
          tran.type = 'Purchase';
        }
        tran.DR = parseFloat(tran.TRNAMT) * -1;
        tran.CR = 0
      } else if (['CREDIT', 'INT', 'XFER'].includes(tran.TRNTYPE)) {
        if (type === 'cc') {
          if (tran.NAME.includes('CASHBACK')) tran.type = 'Cashback';
          else tran.type = 'Payment';
        } else if (type === 'bank') {
          if (tran.TRNTYPE === 'INT') tran.type = 'Interest'
          if (tran.TRNTYPE === 'XFER') tran.type = 'Transfer In'
          if (tran.TRNTYPE === 'CREDIT') tran.type = 'Credit In'
        }
        tran.CR = parseFloat(tran.TRNAMT);
        tran.DR = 0
      }
      tran.index = index;
      out.push(tran);
      return out;
    }, []);
    this.props.enqueueSnackbar('OFX content processed successfully.', {
      variant : 'success'
    });
    this.setState({ 
      ofxUploadRows: transactions, 
      ofxUploadError: '', 
      showActions: true,
      openSelector: false
    })
    this.handleProcessData(transactions);
  }


  /**
   * Render UI Components
   */

  render() {
    return (this.props.asModal) ?
       this.renderAsModal()
       :
       this.renderAsGrid()
  }

  
  renderAsModal() {
    const {classes} = this.props;
    return (
      <Modal
        open={this.props.display}
        center
        showCloseIcon={false}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        onClose={() => {}}
        styles={{ modal: { padding: 0, width: '1100px', paddingLeft: '0.5rem' } }}>
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => this.props.onCancel()}>
              Cancel
            </Button>
            {this.state.openSelector && this.renderFileSelector()}
            {/* <div className="row"> */}
              {/* <div className="col-sm-5"> */}
                {this.renderGrid()}
              {/* </div> */}
              {/* <div className="col-sm-7"> */}
                {this.state.preProcessedData.length > 0 && this.renderProcessedGrid()}
              {/* </div> */}
            {/* </div> */}
            {this.renderActions()/* TODO: Render actions only when imported file is successfully processed */} 
          </div>
      </Modal>
    )
  }

  renderAsGrid() {
    const {classes} = this.props;
    return (
      <div className="content">
        <div className="content-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Upload an OFX file</h4>
                </div>
                <div className="card-body">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => this.props.onCancel()}>
                    Cancel
                  </Button>
                  {this.state.openSelector && this.renderFileSelector()}
                  {this.renderActions()}
                  <div className="row">
                    <div className="col-sm-5">
                      {this.renderGrid()}
                    </div>
                    <div className="col-sm-7">
                      {this.state.preProcessedData.length > 0 && this.renderProcessedGrid()}
                    </div>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  renderFileSelector() {
    const {classes} = this.props;
    return (
      <Grid item md={6} sm={6} xs={6} /* style={{ backgroundColor: "#ff0000" }} */>
        <div className={classes.ofxFileSelectionContainer}>
          <OFXParser 
              label="Upload OFX file: "
              onFileLoaded={data => this.handleFileUpload(data)}
              onError={msg => {
                console.dir(msg)
                this.props.enqueueSnackbar('There was an error occured while parsing the supplied file.', {
                  variant : 'error'
                });
                this.setState({ 
                  ofxUploadRows: [], 
                  ofxUploadError: 'There was an error occured while parsing the supplied file.',
                  showActions: false
                })
              }}
            />
        </div>
      </Grid>
    )
  }

  renderActions() {
    const {classes} = this.props;
    return (
      this.state.showActions && 
        <Grid item md={12} sm={12} xs={12} style={{ /* backgroundColor: "#ff0000", */ justifyItems: "end" }}>
          <div className={classes.ofxUploadActions}>
              <Button
                variant="contained"
                className="btn btn-error"
                hidden={!this.state.enabledDelete}
                onClick={() => this.handleDeleteSelection()}>
                Delete
              </Button>
          </div>
        </Grid>
    )
  }

  renderGrid() {
    return (
      // <Grid item md={4} sm={4} xs={4}>
        <div className="ag-theme-balham" style={{ height: `${this.props.asModal ? '40vh' : '70vh'}`, padding: '1%'}}>
          {Array.isArray(this.state.ofxUploadRows) && this.state.ofxUploadRows.length > 0 && 
            <AgGridReact
              columnDefs={[
                {headerName: 'Date', field: 'DTPOSTED', minWidth: 74, editable: true, 
                            cellRenderer: (params) => { return params.value.substr(0,8); } },
                /*{headerName: 'Curr', field: 'TRNCURR', minWidth: 30},
                *//*{headerName: 'Amount', field: 'TRNAMT', minWidth: 64, editable: true},
                */{headerName: 'Type', field: 'TRNTYPE', minWidth: 64, editable: true},
                {headerName: 'Desc', field: 'NAME', minWidth: 80, editable: true},
                /*{headerName: 'type', field: 'type', minWidth: 100},
                */{headerName: 'CR', field: 'CR', minWidth: 64, editable: true},
                {headerName: 'DR', field: 'DR', minWidth: 64, editable: true}
              ]}
              getRowClass={this.handleRowHighlights}
              onGridReady={this.handleOnGridReady}
              rowData={this.state.ofxUploadRows}
              rowSelection="multiple"
							onSelectionChanged={this.onSelectionChanged.bind(this)}
              onCellValueChanged={()=>this.handleProcessData()}
              animateRows
              resizable={true}
              getRowNodeId={(data)=>data.index}
          />}
        </div>
      // </Grid>
    )
  }
  
  renderProcessedGrid() {
    return (
      <DupCheckGrid
          asModal={false}
          display={true}
          onCancel={() => {
              this.props.onCancel()
          }}
          onSubmit={() => {
              this.props.onCancel()
          }}
          account={this.props.account}
          trans={this.props.trans}
          preProcessed={this.state.preProcessedData}
          onProcessed={(data) => {
            console.log('data', data)
            this.setState({processedData : data})
          }}
        />
    )
  }

}

UploadOFX.propTypes = {
  classes: PropTypes.object.isRequired,
  display: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  asModal: PropTypes.bool.isRequired,
  trans: PropTypes.array.isRequired
};

export default connect(mapStateToProps)(withSnackbar(withStyles(TranStyle)(UploadOFX)));
  