import { getRefreshToken } from "../components/utils/Session";

// let initial = getSession() || '';

const authRefreshTokenReducer = (state = (getRefreshToken() || ''), action) => {
  switch (action.type) {
    case 'SET_REFRESH_TOKEN': {
      return action.data;
    }
    default:
      return state;
  }
};

export default authRefreshTokenReducer;
