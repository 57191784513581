export const AccountStyle = theme => ({
  /**
   * modified ag grid default theme
   */

  agThemeModified: {
    height: '90%'
  },

  /**
   * ag grid tran container
   */

  tranContainer: {
    height: '80vh',
    marginTop: '3vh',
    marginLeft: '2vw',
    width: '96vw'
  },

  /**
   * if browser width is less or equal to 1800px
   */

  '@media screen and (max-width: 1800px)': {
    tranContainer: {
      height: '72vh'
    }
  },

  /**
   *  release dropdown select container
   */

  tranReleaseContainer: {
    padding: '15px',
    minWidth: '270px',
    maxWidth: '270px'
  },

  /**
   *  release select field
   */
  tranReleaseSelect: {
    display: 'flex'
  },

  
	// newReleaseButton            : {
	// 	margin : theme.spacing.unit(2)
	// },

	// button            : {
	// 	margin : theme.spacing.unit(2)
	// },
	newReleaseModal             : {
		paddingTop    : '10px',
		paddingBottom : '10px',
		// maxWidth      : '470px',
		minHeight     : '270px',
		// maxHeight     : '270px',
		paddingRight  : '1.2rem',
		paddingLeft   : '1.2rem'
  },
  
	newReleaseModalActions      : {
		display        : 'flex',
		justifyContent : 'space-evenly',
		marginTop      : '10px',
		marginBottom   : '15px',
		paddingRight   : '1.2rem',
		paddingLeft    : '1.2rem'
  },
  
  
	textField                   : {
		paddingTop : '10px',
		textAlign  : 'left',
        width      : '100%'
	},
	sqlErrorMessage             : {
		marginTo : '5px',
		color    : 'red'
  }
  
});

