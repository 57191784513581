import React from 'react';
import PropTypes from 'prop-types';
import '../../../node_modules/react-vis/dist/style.css';
import './widgetstyles.css';
import {
  Hint,
  RadialChart,
} from 'react-vis';
import Constants from '../utils/constants';
import LoadingOverlay from '../common/loadingOverlay';
import { Checkbox } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { WidgetHandler } from '../api';
const ARC_COLOR_TYPES = ["linear", "ordinal", "category", "literal", "log", "time", "time-utc"]
export default class TranTypeSplits extends React.Component {

  constructor(props) {
      super(props);
      this._isMounted = false; // prevents -> Warning: Can't perform a React state update on an unmounted component
      this._handler = new WidgetHandler(this);
  }

  state = {

    data: {
      validFound: false,
      valid: [],
      all: [{
        pct: 100,
        key: 'unknown',
        label: 'unknown (100%)'
      }]
    },
    includeUnknown: true,
    activeCr: -1,
    activeDr: -1,
    balValue: null,
    curr: sessionStorage.getItem(Constants.SESSION_KEYS.ACTIVE_ACCOUNT_CURR) || 'USD',
    openOverlay: false
  };

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this._handler.getAccountTranTypeSplits().then((data)=>this.setState({ data: data}));
    }
  }

  _forgetValue = () => {
    this.setState({
      balValue: null
    });
  };

  _rememberValue = balValue => {
    this.setState({ balValue });
  };

  render() {
    const { data, balValue, includeUnknown } = this.state;
    let { validFound, all, valid } = data;
    let pieData = includeUnknown ? all : (validFound ? valid : all);
    // console.log('pieData', pieData);
    let toolTipStyle = { background: '#C3D93A', color: 'black', fontSize: 14 };
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-7 col-md-7">
            <div style={{ background: Constants.COLORs.VANKAYA_250 }}>
              <div style={{ marginLeft: 20, padding: 10 }}>
                <h4>{`Transaction Type Split`}</h4>
                {this.props.title && <i>{this.props.title}</i>}
              </div>
              <RadialChart
                className={'donut-chart-example'}
                innerRadius={10}
                radius={200}
                colorType={ARC_COLOR_TYPES[2]}
                const getAngle={d => d.pct}
                getLabel={d => d.key}
                data={pieData}
                onValueMouseOver={this._rememberValue}
                onSeriesMouseOut={this._forgetValue}
                width={500}
                height={500}
                padAngle={0.01}
                showLabels={true}
                labelsStyle={{ fontSize: 12, color: Constants.COLORs.JAAJI }}
              >
                {balValue && <Hint value={balValue}>
                  <div>
                    <span style={{ color: 'white' }}><strong>{balValue.key}</strong></span><br />
                    <span style={toolTipStyle}>{`Count:${balValue.cnt} Percentage:${balValue.pct} %`}</span>
                  </div>
                </Hint>}
              </RadialChart>
              {validFound && <div>
                <span><Checkbox
                  onClick={() => this.setState({ includeUnknown: !includeUnknown })}
                  checked={includeUnknown} />Include Invalid types?</span>
              </div>}
              {this.state.openOverlay && <LoadingOverlay />}
            </div>
          </div>
          <div className="col-5 col-md-5">
            <div className='ag-theme-balham' style={{ height: '100%', padding: '6px' }}>
              <AgGridReact
                gridOptions={{
                  defaultColDef: {
                    resizable: true
                  }
                }}
                onGridReady={params => params.api.sizeColumnsToFit()}
                columnDefs={[{
                  headerName: 'Type',
                  field: 'key',
                }, {
                  headerName: 'Count',
                  field: 'cnt',
                }, {
                  headerName: 'Usage %',
                  field: 'pct',
                  cellRenderer: (params) => {
                    return params.value + ' %'
                  }
                }]}
                className="my-custom-header"
                rowData={pieData.sort((a, b) => (a.cnt < b.cnt) ? 1 : -1)}
                rowSelection="none"
                animateRows
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TranTypeSplits.propTypes = {
  title: PropTypes.string
};