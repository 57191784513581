import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LoadingOverlay from './loadingOverlay';
import UploadAttach from "../upload/UploadAttach";
import { withStyles } from '@material-ui/core/styles';
import '../account/AccountInfo.css';
import {AccountStyle} from '../account/AccountStyle';
import mapStateToProps from '../actions/stateToProps';
import mapDispatchToProps from '../actions/dispatchProps';
import { Button, Typography, Dialog, Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import {AccountHandler, ReceiptHandler} from "../api";
import Constants from "../utils/constants";

class ReceiptManager extends Component {
    
    constructor(props) {
		super(props);
		this._isMounted = false; // prevents -> Warning: Can't perform a React state update on an unmounted component
        this._handler = new ReceiptHandler(this);
        this._accountHandler = new AccountHandler(this);
	}

    state = {
        openModal: this.props.display,
		open: {
            openNewReceipt: false,
            receiptDeleteConfirmation: false,
            updateReceipt: false
        },
        roles: this.props.roles,
        openOverlay: false,
        receipts: [],
        enableAddReceipt: false,
        enableViewReceipt: false,
        enableDeleteReceipt: false,
        enableUpdateReceipt: false,
        receiptRowsSelected: [],
        refreshPending: false
	};

    componentDidMount() {
        this.handleGetReceipts();
    }

	handleGetReceipts = async () => {
		this.setState({ openOverlay: true });
        let {entityId, entity} = this.props;
        this._handler.getEntityReceipts(entity, entityId).then((_receipts)=>{
            // let {roles} = this.state;
            this.setState({
                openOverlay: false,
                receipts: _receipts,
                // enableAddReceipt: (roles.owner || roles.admin),  
            })
            if (this.props.onChange)
            this.props.onChange(_receipts);
        }).then(()=>{
            this._handler.getReceiptQuotaBal().then((bal)=>{
                let {roles} = this.state;
                this.setState({
                    enableAddReceipt: (roles.owner || roles.admin) && bal > 0,  
                })
                
            })
        })
    }
    
	handleDeleteReceipt = async () => {
		this.setState({ openOverlay: true });
        let receipt = this.state.receiptRowsSelected[0];
        if (receipt && receipt._id) {
            // proceed
        } else {
            this.props.enqueueSnackbar('Please select a receipt to delete.', {
                variant : 'warning'
            });
        }
        this._handler.deleteReceipt(receipt._id).then(()=>{
            //refresh on success
            this.handleGetReceipts();
        }).finally(()=>{
            this.setState({
                openOverlay : false,
            });
            this.handleCloseModal("receipt");
        })
	};

    handleViewReceipt = () => {
        let row = this.receiptGridApi.getSelectedRows();
        if (row[0] && row[0].expires && row[0].expires > Date.now() && row[0].url) {
            window.open(row[0].url, "_blank");  
        } else {
            console.log('Receipt link expired. Refreshing now.')
            this._handler.getReceiptSignedUrl(row[0]._id).then((receipt)=>{
                // console.log(`receipt refreshed: ${receipt}`)
                window.open(receipt, "_blank");    
            });
        }
    }

    handleOpenModal = (modal="contributor-add") => {
		let showModal = this.state.open;
        showModal.disableConfirmation = false;
        showModal.receiptDeleteConfirmation = (modal === "receipt-delete");
        showModal.enableConfirmation = false;
        showModal.openNewReceipt = (modal === "receipt-add");
        showModal.updateReceipt = (modal === "receipt-update")
        
        this.setState({
            open: showModal,
            contributorEditMode: (modal === "contributor-edit")
        })

        if (modal==="receipt-view") {
            this.handleViewReceipt();
        }
    }

	handleCloseModal = (modal="contributor") => {
		let showModal = this.state.open;
        showModal.disableConfirmation = false;
        showModal.receiptDeleteConfirmation = false;
        showModal.openNewContributor = false;
        showModal.openNewReceipt = false;
        showModal.updateReceipt = false;
		this.setState({
			open             : showModal
        });
        if (modal === "receipt") {
            this.handleGetReceipts()
        }
    };

    renderReceiptModal() {
		return (
			<UploadAttach
				display={this.state.open.openNewReceipt}
				onCancel={() => {
					this.handleCloseModal("receipt");
				}} 
				onSubmit={() => {
                    this.handleCloseModal("receipt");
                    this.setState({ refreshPending: true })
                }}
                onError={(msg)=>{
                    this.props.enqueueSnackbar(msg, {variant: 'error'})
                }}
                entity={this.props.entity}
                ids={[this.props.entityId]}
			/>
        )
    }
    
    onReceiptSelectionChanged() {
        console.log('roles:' + JSON.stringify(this.state.roles, 2, null))
        let selectedRows = this.receiptGridApi.getSelectedRows();
		this.setState({ 
			receiptRowsSelected: selectedRows, 
            enableAddReceipt: (this.state.roles.owner || this.state.roles.admin),
            enableDeleteReceipt: (selectedRows.length === 1 && (this.state.roles.owner || this.state.roles.admin)),
            enableViewReceipt: (selectedRows.length === 1 && this.state.roles.any)
		})
    }

    renderReceiptActions() {
        return (
			<Grid container style={{margin: "4px"}}>
				<Grid item md={12} sm={12} xs={12}>
					{<Button disabled={!this.state.enableAddReceipt}
						onClick={() => this.handleOpenModal("receipt-add")}
						variant="contained"
						size="small"
						color="default">
                        <i className="pe-7s-add-user"></i> Add
                        </Button>}
					&nbsp;
					{this.state.enableViewReceipt && <Button disabled={!this.state.enableViewReceipt}
						onClick={() => this.handleOpenModal("receipt-view")}
						variant="contained"
						size="small"
						color="primary">View</Button>}
					&nbsp;
                    {this.state.enableUpdateReceipt && <Button disabled={!this.state.enableUpdateReceipt}
                        onClick={() => this.handleOpenModal("receipt-update")}
                        variant="contained"
                        size="small"
                        color="default">Update</Button>}
					&nbsp;
					{this.state.enableDeleteReceipt && <Button disabled={!this.state.enableDeleteReceipt}
						onClick={() => this.handleOpenModal("receipt-delete")}
						variant="contained"
						size="small"
						color="secondary">Delete</Button>}
                    &nbsp;
					<Button 
						onClick={() => {
                            this.setState({ refreshPending: false })
                            this.handleGetReceipts()
                        }}
						variant="contained"
						size="small"
						color="inherit">Refresh {(this.state.refreshPending ? <i className="pe-7s-refresh"></i> : `` )}</Button>
				</Grid>
			</Grid>
		)
    }

    handleOnReceiptsGridReady = params => {
		this.receiptGridApi = params.api;
		this.receiptGridColumnApi = params.columnApi;
		this.receiptGridApi.sizeColumnsToFit();
    };

    handleOnCellValueChanged = (params) => {
        var colId = params.column.getId();
        console.log('selected column: ' + colId);
        if (colId === 'name') {
            let { _id, name } = params.data;
            console.log("id: " + _id + " new name: " + name);
            this._handler.updateReceiptDetails(_id, { name });
        }
    }

	renderActionReceipts() {
		const { classes } = this.props;
        const agStyles = `ag-theme-balham ${classes.agThemeModified}`;
        return (
            <Grid container id='accountinfo-members-grid' /* style={{"height": "70%", width: "96%", marginLeft: "2%", marginTop: "10px"}} */ >
				<Grid item  md={12} sm={12} xs={12}>
                    {this.renderReceiptActions()}
                    {(this.state.receipts.length === 0) && 
                            <Typography>There are no attachments uploaded to this account yet.</Typography>}
					{(this.state.receipts.length > 0) && <div className={agStyles}>
                        <AgGridReact
                                gridOptions={{
                                    defaultColDef: {
                                        resizable: true,
                                        sortable: true,
                                    },
                                    onCellValueChanged: this.handleOnCellValueChanged
                                }}
                                // onRowDragEnter={this.onRowDragEnter}
                                // onRowDragEnd={this.onRowDragEnd}
                                onGridReady={this.handleOnReceiptsGridReady}
                                // onRowDragLeave={this.onRowDragLeave}
                                columnDefs={[{
                                    header: "name",
                                    field: "name",
                                    width: 180,
                                    editable: true
                                },{
                                    header: "size",
                                    field: "size",
                                    width: 120,
                                    cellRenderer: (params) => (params.value).toFixed(0) + " (" + Constants.formatBytes(params.value) + ")"
                                },{
                                    header: "Uploaded At",
                                    field: "created_at",
                                    width: 180,
                                    cellRenderer: (params) => {
                                        let date = '';
                                        try {
                                            date = params.value.substring(0,16).replace('T', ' ') + " GMT";
                                        } catch (error) {
                                            //
                                        }
                                        return date;
                                    }
                                // },{
                                //     header: "link status",
                                //     field: "expires",
                                //     width: 100,
                                //     cellRenderer: (params) => {
                                //         return (params.value > Date.now() ? "Active" : "Expired")
                                }]}
                                rowData={this.state.receipts}
                                // getRowClass={this.handleRowHighlights}
                                rowSelection="single"
                                onSelectionChanged={this.onReceiptSelectionChanged.bind(this)}
                                // rowDragManaged={true}
                                animateRows
                                // pagination={!this.state.gridEditMode}
                                // paginationPageSize={25}
                                enableCellTextSelection="true"
                                ensureDomOrder="true"
                            />
					</div>}
				</Grid>
			</Grid>
        )
	}

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.open.receiptDeleteConfirmation}>
                    <Typography>
                        {`Are you sure to delete the receipt?`}</Typography>
                    <Button
                        onClick={() => this.handleDeleteReceipt()}>Yes</Button>
                    <Button
                        onClick={() => this.handleCloseModal("")}>No</Button>
                </Dialog>
                {this.renderActionReceipts()}
                {this.state.open.openNewReceipt && this.renderReceiptModal()}
                {this.state.openOverlay && <LoadingOverlay />}
            </div>
        )
    }
}

ReceiptManager.propTypes = {
    display: PropTypes.bool.isRequired,
    entity: PropTypes.string.isRequired,
    entityId: PropTypes.string.isRequired,
    selectedAccount: PropTypes.object.isRequired,
    roles: PropTypes.object.isRequired,
    onChange: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withStyles(AccountStyle)(ReceiptManager)));
