import React from 'react';
import Constants from '../utils/constants';
import { getInstance } from '../utils/axiosLoader';
import { withSnackbar } from 'notistack';

class Profile extends React.Component {

    state = {
        profile: {
            _id: 'xyz',
            name: 'Unknown',
            email: 'unknown@unknown.com',
        }
    }

    componentDidMount() {
        this.handleGetAccounts();
    }

    handleGetAccounts = async () => {
		let retrieveFrom = Constants.URLs.USERS + '/me';
		// this.setState({ openOverlay: true });
		await getInstance()
			.get(retrieveFrom)
			.then((response) => {
				this.setState({ 
					// openOverlay: false, 
					profile: response.data
				});
			})
			.catch((error) => {
				console.log(error);
				this.props.enqueueSnackbar('Error: Could not be able to retrieve profile information', {
					variant : 'error'
				});
			});
    }

    render() {
        let size = `-- MB`;
        let quota = `-- %`;
        try { 
            if (this.state.profile.receipts_size) {
                size = `${(this.state.profile.receipts_size / 1024 / 1024).toFixed(3)} MB / ${(Constants.FREE_USER_RECEIPT_SIZE_QUOTA / 1024).toFixed(0)} MB`;
                quota = `${(((this.state.profile.receipts_size / 1024) / Constants.FREE_USER_RECEIPT_SIZE_QUOTA) * 100).toFixed(1)} %`
            }   
        } catch (error) {
            //
        }
        return (
        <div className="container-fluid">
            {/* <div className="section-image" data-image="./assets/img/bg5.jpg"
                                                        style={{backgroundImage: `url('/assets/img/full-screen-image-3.jpg')`}}> */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-6">
                            <form className="form" method="" action="">
                                <div className="card ">
                                    <div className="card-header ">
                                        <div className="card-header">
                                            <h4 className="card-title">Profile</h4>
                                        </div>
                                    </div>
                                    <div className="card-body ">
                                        <div className="row">
                                            {/* <div className="col-md-5 pr-1">
                                                <div className="form-group">
                                                    <label>Company (disabled)</label>
                                                    <input type="text" className="form-control" disabled="" placeholder="Company" value=""  readOnly/>
                                                </div>
                                            </div> */}
                                            <div className="col-md-6 pr-1">
                                                <div className="form-group">
                                                    <label>Username</label>
                                                    <input type="text" className="form-control" placeholder="Username" value={this.state.profile._id} readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-6 pl-1">
                                                <div className="form-group">
                                                    <label>Email address</label>
                                                    <input type="email" className="form-control" placeholder="Email" value={this.state.profile.email} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 pl-1">
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <input type="text" className="form-control" placeholder="First Name" value={this.state.profile.name} readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-6 pr-1">
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input type="text" className="form-control" placeholder="Last Name" value="" readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Receipts size quota</label>
                                                    <input type="text" className="form-control" placeholder="Receipts" value={size + ' (' + quota + ')'} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-md-4 pr-1">
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input type="text" className="form-control" placeholder="City" value="" readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-4 px-1">
                                                <div className="form-group">
                                                    <label>Country</label>
                                                    <input type="text" className="form-control" placeholder="Country" value="" readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-4 pl-1">
                                                <div className="form-group">
                                                    <label>Postal Code</label>
                                                    <input type="number" className="form-control" placeholder="ZIP Code" readOnly />
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>About Me</label>
                                                    <textarea rows="4" cols="80" className="form-control" placeholder="Here can be your description" value="" readOnly></textarea>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <button type="submit" className="btn btn-info btn-fill pull-right">Update Profile</button> */}
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-4">
                            <div className="card card-user">
                                <div className="card-header no-padding">
                                    <div className="card-image">
                                        <img src="./assets/img/full-screen-image-3.jpg" alt="..." />
                                    </div>
                                </div>
                                <div className="card-body ">
                                    <div className="author">
                                        <a href="/profile">
                                            <img className="avatar border-gray" src="./assets/img/faces/tim_vector.jpe" alt="..." />
                                            <h5 className="card-title">{this.state.profile.name}</h5>
                                        </a>
                                        <p className="card-description">
                                            {this.state.profile.email}
                                        </p>
                                    </div>
                                    {/* <p className="card-description text-center">
                                        Hey there! As you can see,
                                        <br /> it is already looking great.
                                    </p> */}
                                </div>
                                <div className="card-footer ">
                                    <hr />
                                    {/* <div className="button-container text-center">
                                        <button href="#" className="btn btn-simple btn-link btn-icon">
                                            <i className="fa fa-facebook-square"></i>
                                        </button>
                                        <button href="#" className="btn btn-simple btn-link btn-icon">
                                            <i className="fa fa-twitter"></i>
                                        </button>
                                        <button href="#" className="btn btn-simple btn-link btn-icon">
                                            <i className="fa fa-google-plus-square"></i>
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </div>
        )
    }
}
export default withSnackbar(Profile);