import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';

class LoaderOverlay extends Component {
  state = {
    openLoading: true,
    text: this.props.text || "Loading..."
  };

  handleCloseLoading = () => {
    this.setState({
      openLoading: false
    });
  };
  render() {
    return (
      <React.Fragment>
        <LoadingOverlay
          styles={{
            overlay: base => ({
              ...base,
              position: 'fixed'
            })
          }}
          classNamePrefix="loader_"
          className="cypress_loader_overlay classxyz"
          active={true}
          spinner
          text={this.state.text}
        />
      </React.Fragment>
    );
  }
}
LoaderOverlay.propTypes = {
  text: PropTypes.string
}

export default LoaderOverlay;
