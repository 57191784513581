import ApiHandler from './ApiHandler';
import Constants from '../utils/constants';

export default class 
TransactionHandler extends ApiHandler {

    constructor(callerComponent) {
        super(callerComponent);
        this.baseUrl = Constants.URLs.TRANS;
    }

    recalculateAccount() {
        let ac = this.getActiveAccount();
		if (!ac) {
			this.showWarn('Please select an account to recalculate.');
			return;
		}
        let url = `${this.baseUrl}/recalculate/${ac}`;
        return new Promise((resolve, reject)=>{
            this.getInstance().post(url).then((response) => {
                this.showSuccess(response.data.message);
                if(response.data.doRefresh) 
                    this.comp.handleRefreshRequest();
                resolve(true);
            }).catch(err => 
                this.handleError(err, `Error while recalculating account. Try again.`))
        });
    }

    reorderAccount(by='date') {
        let ac = this.getActiveAccount();
		if (!ac) {
			this.showWarn('Please select an account to reorder.');
			return;
		}
        let url = `${this.baseUrl}/reorder/${ac}/${by}`;
        return new Promise((resolve, reject)=>{
            this.getInstance().post(url).then((response) => {
                this.showSuccess(response.data.message);
                resolve(true);
            }).catch(err => 
                this.handleError(err, `Error while reordering account. Try again.`))
        });
    }

    refreshBeneficiaries() {
        let ac = this.getActiveAccount();
		if (!ac) {
			this.showWarn('Please select an account to recalculate.');
			return;
		}
        let url = `${this.baseUrl}/batch/synclinkac`;
        let payload = { account: ac };
        return new Promise((resolve, reject)=>{
            this.getInstance().post(url, payload).then((response) => {
                this.showSuccess(`Beneficiaries synced successfully [${response.data.message}]`);
                if (response.data.counts.updates > 0 && this.comp.props.onRefreshTranGrid) {
                    this.comp.props.onRefreshTranGrid(true);
                }
                resolve(true);
            }).catch(err => 
                this.handleError(err, `Error while syncing beneficiaries. Try again.`))
        });
    }

    unLinkTransactions() {
		let ids = this.comp.props.rowsToUnlink.reduce((arr, tran)=>{
			arr.push(tran._id);
			return arr;
		},[]);
		if (ids.length === 0) {
			this.showWarn('No transactions selected to unlink.');
			return;
		}
        let url = `${this.baseUrl}/bulkunlink`;
        let payload = { ids };
        return new Promise((resolve, reject)=>{
            this.getInstance().post(url, payload).then((response) => {
                this.showSuccess(response.data.message);
                // if(response.data.doRefresh) 
                    this.comp.handleRefreshRequest();
                resolve(true);
            }).catch(err => 
                this.handleError(err, `Error while unlinking selected transactions. Try again.`))
        });
    }

    cloneTransaction() {
		let {_id} = this.comp.props.rowsSelected[0];
		if (!_id) {
			this.showWarn('No transactions selected to unlink.');
			return;
		}
        let url = `${this.baseUrl}/clone/${_id}`;
        return new Promise((resolve, reject)=>{
            this.getInstance().post(url).then((response) => {
                // let updatedTransaction = response.data;
                this.showSuccess('Transaction cloned successfully');
                this.recalculateAccount();
                // if(response.data.doRefresh) 
                    // this.comp.handleRefreshRequest();
                resolve(true);
            }).catch(err => 
                this.handleError(err, `Error while cloning selected transaction. Try again.`))
        });
    }

}
