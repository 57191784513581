// reducers/uiReducer.js
const uiReducer = (state = {}, action) => {
    let delay = action.delay || 1500;
    switch (action.type) {
      case "SNACKBAR_SUCCESS":
        return {
          ...state,
          open: true,
          message: action.message,
          severity: "success",
          delay: delay
        };
    case "SNACKBAR_INFO":
        return {
          ...state,
          open: true,
          message: action.message,
          severity: "info",
          delay: delay
        };
    case "SNACKBAR_WARNING":
        return {
          ...state,
          open: true,
          message: action.message,
          severity: "warning",
          delay: delay
        };    
    case "SNACKBAR_ERROR":
        return {
          ...state,
          open: true,
          message: action.message,
          severity: "error",
          delay: delay
        };        
      case "SNACKBAR_CLEAR":
        return {
          ...state,
          open: false,
          message: '',
          severity: 'info',
          delay: delay
        };
      default:
        return state;
    }
  };
  
  export default uiReducer;