import ApiHandler from './ApiHandler';
import Constants from '../utils/constants';

export default class 
ReceiptHandler extends ApiHandler {

    getReceiptQuota() {
        let retrieveFrom = Constants.URLs.USERS + "/me";
        return new Promise((resolve, reject)=>{
            this.getInstance().get(retrieveFrom).then((response)=>{
                let inMB = 0;
                if (response.data.receipts_size) {
                    inMB = (response.data.receipts_size / 1024 / 1024);
                }
                resolve(inMB)
            }).catch(err => this.handleError(err, `Error while fetching user quota.`))
        })
    }

    getReceiptQuotaBal() {
        let quota = Constants.FREE_USER_RECEIPT_SIZE_QUOTA / 1024;
        return new Promise((resolve, reject)=>{
            this.getReceiptQuota().then((used)=>{
                resolve(quota - used);
            })
        })
    }

    getEntityReceipts(entity, entityId) {
        let retrieveFrom = Constants.URLs.ACCOUNT_RECEIPTS + "/" + entity + "/" + entityId;
        return new Promise((resolve, reject)=>{
            this.getInstance().get(retrieveFrom).then((response) => {
                resolve(response.data);
            }).catch(err => this.handleError(err, `Error while fetching entity receipts.`))
        });
    }

    deleteReceipt(id) {
        return new Promise((resolve, reject)=>{
            let retrieveFrom = `${Constants.URLs.ACCOUNT_RECEIPTS}/${id}`;
            this.getInstance().delete(retrieveFrom).then(() => {
                this.showSuccess('Receipt deleted successfully');
                resolve('');
            }).catch(this.handleError)
        });
    }

    getReceiptSignedUrl (id) {
        return new Promise((resolve, reject)=>{
            this.getInstance().get(Constants.URLs.RECEIPTS + '/' + id).then((response) => {
                if (response.status === 200) {
                    resolve(response.data.url)
                } else {
                    this.showWarn(response.data.error);
                }
            }).catch(this.handleError);
        });
    }
        
    deleteEntityReceipts(entity, entityId) {
        let retrieveFrom = Constants.URLs.ACCOUNT_RECEIPTS + "/" + entity + "/" + entityId;
        this.getInstance().get(retrieveFrom).then(() => {
            this.showSuccess('Receipts deleted successfully.');
        }).catch(this.handleError);
    }
    
    updateReceiptDetails(id, data) {
        this.getInstance().put(Constants.URLs.RECEIPTS + '/' + id, data).then((response) => {
            this.showSuccess('Receipt updated successfully.')
        }).catch(this.handleError);
    }
      
}
