
function getCharCodeFromEvent(event) {
  event = event || window.event;
  return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
  return !!/\d/.test(charStr);
}

function isKeyPressedNumeric(event) {
  var charCode = getCharCodeFromEvent(event);
  var charStr = String.fromCharCode(charCode);
  return isCharNumeric(charStr);
}

function NumericEditor() {

  // function to act as a class
  function NumericEditor() {}

  // gets called once before the renderer is used
  NumericEditor.prototype.init = function (params) {
      // create the cell
      this.eInput = document.createElement('input');

      if (isCharNumeric(params.charPress)) {
          this.eInput.value = params.charPress;
      } else {
          if (params.value !== undefined && params.value !== null) {
              this.eInput.value = params.value;
          }
      }

      var that = this;
      this.eInput.addEventListener('keypress', function (event) {
          if (!isKeyPressedNumeric(event)) {
              that.eInput.focus();
              if (event.preventDefault) event.preventDefault();
          } else if (that.isKeyPressedNavigation(event)){
              event.stopPropagation();
          }
      });

      // only start edit if key pressed is a number, not a letter
      var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
      this.cancelBeforeStart = charPressIsNotANumber;
  };

  NumericEditor.prototype.isKeyPressedNavigation = function (event){
      return event.keyCode===39
          || event.keyCode===37;
  };


  // gets called once when grid ready to insert the element
  NumericEditor.prototype.getGui = function () {
      return this.eInput;
  };

  // focus and select can be done after the gui is attached
  NumericEditor.prototype.afterGuiAttached = function () {
      this.eInput.focus();
  };

  // returns the new value after editing
  NumericEditor.prototype.isCancelBeforeStart = function () {
      return this.cancelBeforeStart;
  };

  // example - will reject the number if it contains the value 007
  // - not very practical, but demonstrates the method.
  NumericEditor.prototype.isCancelAfterEnd = function () {
      // var value = this.getValue();
      // return value.indexOf('007') >= 0;
      return false;
  };

  // returns the new value after editing
  NumericEditor.prototype.getValue = function () {
      return this.eInput.value;
  };

  // any cleanup we need to be done here
  NumericEditor.prototype.destroy = function () {
      // but this example is simple, no cleanup, we could  even leave this method out as it's optional
  };

  // if true, then this editor will appear in a popup 
  NumericEditor.prototype.isPopup = function () {
      // and we could leave this method out also, false is the default
      return false;
  };

  return NumericEditor;
}
export default NumericEditor;