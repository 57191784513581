import ApiHandler from './ApiHandler';
import Constants from '../utils/constants';
import IndexDb from '../utils/indexDb';
import Storage from '../utils/Storage';

export default class 
AccountHandler extends ApiHandler {
    
    baseUrl = Constants.URLs.ACCOUNTS;
    
	prepareObjectForSaving = (_obj = null) => {
		let out = {};
		let _keys = Object.keys(_obj);
		for (let key of _keys) out[key] = _obj[key].value;
		return out;
	};

    startTheChit(acId) {
        let url = `${this.baseUrl}/${acId}`;
        return new Promise((resolve, reject)=>{
            this.getInstance().patch(url, { startChitFlag: true }).then((account) => {
                this.showSuccess(`Chitfund started successfully.`);
                resolve(account);
            }).catch(err => this.handleError(err, `Error while starting chitfund. Try again.`))
        });
    }

    updateSubscriberDetails(acId, index, data) {
        return new Promise((resolve, reject) => {
            this.getInstance().patch(Constants.URLs.ACCOUNT_SUBSCRIBERS + '/ac/' + acId + '/subscriber/id/' + index, data).then((response) => {
                this.showSuccess('Subscriber info updated successfully.')
                resolve(true);
            }).catch((err)=>{
                reject(err);
            });
        })
    }
     
    postNewChitTerm(acId, payload) {
        let url = `${Constants.URLs.TRANS}/newchitterm/ac/${acId}`;
        return new Promise((resolve, reject)=>{
            this.getInstance().post(url, payload).then((response)=>{
                this.showSuccess(response.data.message);
                resolve(response);
            }).catch(err => this.handleError(err, `Error while posting chit term. Try again.`))
        });
    }

    patchAccountStatus(acId, status, hooks={}) {
        // let msg = status === 'cl' ? `Account archived successfully.` : 
        //                             `Account re-activated successfully.`;
        let url = `${this.baseUrl}/${acId}`;
        if (hooks && hooks.before) hooks.before();
        return new Promise((resolve, reject)=>{
            this.getInstance().patch(url, { status }).then((account) => {
                // this.showSuccess(msg);
                if (hooks && hooks.after) hooks.after();
                resolve(account);
            }).catch(err => this.handleError(err, `Error while archiving account. Try again.`))
        });
    }

    unArchiveAccount(acId, hooks={}) {
        return this.patchAccountStatus(acId, 'ac', hooks);
    }

    archiveAccount(acId, hooks={}) {
        return this.patchAccountStatus(acId, 'cl', hooks);
    }

    deleteAccount() {
        let ac = this.getActiveAccount();
		if (!ac) {
			this.showWarn('Please select an account to delete.');
			return;
		}
        this.nav.showOverlay();
        /* call archive signature */
        this.archiveAccount(ac)
        .finally(()=> {
            this.nav.hideOverlay(); 
            this.nav.closeModal(); 
            this.nav.reloadToAccounts()
        });
    }

    fetchAccounts = (acId) => {
        let url = this.baseUrl + (acId ? ('/' + acId) : '');
        return new Promise((resolve, reject)=>{
            this.getInstance().get(url).then((response) => {
                resolve(acId ? response.data : response.data/*.filter(ac=>(ac.status==='ac'))*/.sort((a,b)=>{
                    if (a.name > b.name) return 1;
                    if (b.name > a.name) return -1;
                    return 0;
                }))
            }).catch(err => this.handleError(err, `Error while fetching accounts. Try again.`))
        });
    }
    fetchAccount = (acId) => this.fetchAccounts(acId);

    getAccountsCached(unset=false) {
        return this.cachedResponse(unset, Storage.INDEXED_KEYS.ACCOUNTS, this.fetchAccounts);
    }
    getAccountCached(acId, unset=false) {
        // return this.cachedResponse(unset, Storage.INDEXED_KEYS.ACCOUNTS, this.fetchAccounts, [acId]);
        return this.getAccountsCached(unset).then(accounts=>{
            return accounts.filter((ac)=>ac._id === acId)[0];
        })
    }


    getAccountTrans(acId, isUploader) {
        //fetch account transactions to compare, only if asked by uploader page; no where else
        if (!isUploader) {
            return new Promise((resolve, reject)=>{
                resolve([])
            })
        }
        let retrieveFrom = Constants.URLs.TRANS_OF_ACCOUNT + "/" + acId;
        return new Promise((resolve, reject)=>{
            this.getInstance().get(retrieveFrom).then((response) => {
                //console.log("transaction fetched:", response.data)
                resolve(response.data);
            }).catch(err => this.handleError(err, `Error while fetching account accounts.`))
        });
    }

    getAccountMembers(acId) {
        let retrieveFrom = Constants.URLs.ACCOUNT_MEMBERS + "/ac/" + acId;
        return new Promise((resolve, reject)=>{
            this.getInstance().get(retrieveFrom).then((response) => {
                resolve(response.data);
            }).catch(err => this.handleError(err, `Error while fetching account members.`))
        });
    }

    getAccountSubscribers(acId) {
        let retrieveFrom = Constants.URLs.ACCOUNT_SUBSCRIBERS + "/ac/" + acId;
        return new Promise((resolve, reject)=>{
            this.getInstance().get(retrieveFrom).then((response) => {
                resolve(response.data);
            }).catch(err => this.handleError(err, `Error while fetching account subscribers.`))
        });
    }

    getUserRoles(acId, acUid) {
        return new Promise((resolve, reject)=>{
            let _roles = {};
            this.getAccountMembers(acId).then(async (members)=>{
                let currentUser = await IndexDb.getItem(IndexDb.KEYS.USER_INFO);
                _roles.owner = (acUid === currentUser.id)
                let _members = members.filter((member)=>{
                    return member.uid === currentUser.id &&
                        member.status === 'ac';
                });
                if (Array.isArray(_members) && _members.length > 0) {
                    _roles.admin = (_members[0].role === 'admin');
                    _roles.editor = (_members[0].role === 'editor');
                    _roles.viewer = (_members[0].role === 'viewer');
                    _roles.any = _roles.admin || _roles.editor || _roles.viewer;
                }
                resolve(_roles);
            });
        })
    }

    deleteAccountMember(acId, memId) {
        return new Promise((resolve, reject)=>{
            let retrieveFrom = `${Constants.URLs.ACCOUNT_MEMBERS}/ac/${acId}/member/${memId}`;
            this.getInstance().delete(retrieveFrom).then(() => {
                this.showSuccess('Member deleted successfully.')
                resolve('')
            }).catch((error) => this.handleError('Error: Could not be delete member. Try again') );
        });
    }
    /*
}


class AccountChitHandler extends ApiHandler {

    constructor(callerComponent) {
        super(callerComponent);
        this.comp = callerComponent;
    }
*/
    addDummySubscribers(accId) {
        let url = Constants.URLs.ACCOUNT_SUBSCRIBERS + "/ac/" + accId + "/add/dummy";
        let payload = {};
        return new Promise((resolve, reject)=>{
            this.getInstance().post(url, payload).then((response)=>{
                this.showSuccess(`Default subscribers added successfully.`)
                resolve(response)
            }).catch(err => this.handleError(err, `Error while adding subscribers to account.`))
        })
    }

    createContributor() {
        let ac = this.getActiveAccount();
		if (!ac) {
			this.showWarn('Please select an account to add subscriber.');
			return;
		}
        let contributor = this.comp.state.contributor;
        let member = this.prepareObjectForSaving(contributor);

        let url = Constants.URLs.ACCOUNT_MEMBERS + "/ac/" + ac + "/add/email";
        let payload = {
            "domain": Constants.getDomain(),
            "name": member.name,
            "email": member.email,
            "role": member.role || 'viewer',
            "status": member.status || 'ac'
        };
        this.nav.setActionInProgress();
        return new Promise((resolve, reject)=>{
            this.getInstance().post(url, payload).then((response)=>{
                this.showSuccess(`Member invited successfully.`)
                this.nav.unsetActionInProgress();
                resolve(response)
            }).catch(err => {
                this.nav.unsetActionInProgress();
                this.handleError(err, `Error while adding member to account.`)
            }).finally(()=>this.comp.props.onSubmit())
        });
    }
}
