import React from 'react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

class Slideshow extends Component {
  render() {
    return (
      <div>
        <Slide easing="ease">
          {this.props.slides.map((slide, index) => {
            return (
              <div className="each-slide" key={`slide${index}`}>
                <div style={{'backgroundImage': `url(${slide.link})`}}>
                  <span>{slide.desc}</span>
                </div>
              </div>
            )
          })}
        </Slide>
      </div>
    )
  }
};

Slideshow.propTypes = {
  slides: PropTypes.array.isRequired
};

export default Slideshow;