import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import Select from '@material-ui/core/Select';
import Select2 from 'react-select2-wrapper';
// import MenuItem from '@material-ui/core/MenuItem';
import Modal from 'react-responsive-modal';
import { Button, Grid, Typography, TextField, LinearProgress, withStyles } from '@material-ui/core';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import {ContributorStyle} from './ContributorStyle';

import {validator} from '../utils/validator';
import { withSnackbar } from 'notistack';
import mapStateToProps from '../actions/stateToProps';
import { AccountHandler } from '../api';

class NewContributor extends Component {

    constructor(props) {
      super(props)
      this.handleRoleFieldChange =  this.handleRoleFieldChange.bind(this)
	  this._handler = new AccountHandler(this);
    }

	state = {
		actionInProgress      : {
			openLinearLoading   : false,
			disableActionButton : false
		},
		sqlErrorMessage: '',
		contributor          : this.props.member,
    };


	handleFieldValidation = (e) => {
        let _listitem = this.state.contributor;
		_listitem[e.target.name].value = e.target.value;
		// _listitem.list.value = this.props.listCode;
		// _field.value = e.target.value;
		
		for (let _fieldKey of Object.keys(_listitem)) {
			let _field = _listitem[_fieldKey];
			let rules = Object.keys(_field.rules);
			if (rules) {
				for (let rule of rules) {
					if (_field.rules[rule] && rule in validator) {
						let validation = validator[rule](_field.value, _field.rules[rule]);

						if (!validation.passed) {
							_field.hasErrors.error = true;
							_field.hasErrors.message = validation.message;

							break;
						}
						else {
							_field.hasErrors.error = false;
							_field.hasErrors.message = validation.message;
						}
					}
				}
			}
		}
		this.handleFieldsErrorFree(_listitem)
	};

	handleFieldsErrorFree = async (fields/* type = 'N' */) => {
		// let _originalCopiedRelease = this.state.originalCopiedRelease;
        // let fields = this.state.release;
		let _keys = Object.keys(fields);
		let errorFree = [];

		for (let key of _keys) {
			if (fields[key].hasErrors.error) {
				errorFree.push(fields[key].hasErrors.error);
				continue;
			}
			else {
				if (fields[key].rules.hasOwnProperty('required')) {
					let validation = validator['required'](fields[key].value, fields[key].rules.required);
					if (!validation.passed) {
						fields[key].hasErrors.error = true;
						fields[key].hasErrors.message = validation.message;
					}
					else {
						fields[key].hasErrors.error = false;
						fields[key].hasErrors.message = validation.message;
					}
				}
				errorFree.push(fields[key].hasErrors.error);
			}
		}

		this.setState({ 
			contributor: fields,
			actionInProgress : {disableActionButton: errorFree.includes(true)}
		});
		return !errorFree.includes(true); // if the array includes at least one "true" then field(s) has errors
    };
    
    handleRoleFieldChange(e) {
		let _contributor = this.state.contributor;
		_contributor.role.value = e.target.value;
		this.setState({ contributor: _contributor });
    }
    
	render() {
        const { classes } = this.props;
        let rolesList = [{
            id: 'admin', text: 'Administrator'
        },{
            id: 'editor', text: 'Editor'
        },{
            id: 'viewer', text: 'Viewer'
        }]
		return (
			<Modal
				open={this.props.display}
				center
				showCloseIcon={false}
				closeOnOverlayClick={false}
				closeOnEsc={false}
				onClose={() => {}}
				styles={{ modal: { padding: 0 } }}>
				<Grid container className={classes.newReleaseModal}>
					<Grid item md={12} lg={12} xs={12}>
                        <Typography align="center">
                            {`${(this.props.update ? 'Edit' : 'Add')} a contributor`}
                        </Typography>
					</Grid>
					<Grid item md={12} lg={12} xs={12}>
						<TextField
							name={'name'}
							label={this.state.contributor.name.alias}
							className={classes.textField}
							margin="normal"
							type="string"
							value={this.state.contributor.name.value}
							error={this.state.contributor.name.hasErrors.error}
							required={true}
							onChange={this.handleFieldValidation}
							variant="standard" // opt: standard, outlined or filled
							helperText={this.state.contributor.name.hasErrors.message}
						/>
						<TextField
							name={'email'}
							label={this.state.contributor.email.alias}
							className={classes.textField}
							margin="normal"
							type="string"
							value={this.state.contributor.email.value}
							error={this.state.contributor.email.hasErrors.error}
							required={true}
							onChange={this.handleFieldValidation}
							variant="standard" // opt: standard, outlined or filled
							helperText={this.state.contributor.email.hasErrors.message}
						/>
						{/* <TextField
							name={'role'}
							label={this.state.contributor.role.alias}
							className={classes.textField}
							margin="normal"
							type="string"
							value={this.state.contributor.role.value}
							error={this.state.contributor.role.hasErrors.error}
							required={true}
							onChange={this.handleFieldValidation}
							variant="standard" // opt: standard, outlined or filled
							helperText={this.state.contributor.role.hasErrors.message}
						/> */}
                        {/* <Select
                            name={'role'}
                            label={this.state.contributor.role.alias}
                            value={this.state.contributor.role.value ? this.state.contributor.role.value : "viewer"}
                            className={classes.textField}
                            style={{paddingTop: '14px' }}
                            onChange={this.handleRoleFieldChange}
                            >
                                {rolesList.map((role, index) => {
                                    return (
                                        <MenuItem
                                            key={`role-${role.code}`}
                                            value={role.code}>
                                            {`${role.name}`}
                                        </MenuItem>
                                    );
                                })}
                        </Select> */}
                        <div style={{marginTop: 12}}>Contribution Level:
                            <Select2
                                data={rolesList}
                                defaultValue={'viewer'}
                                className={classes.textField}
                                value={this.state.contributor.role.value}
                                options={{
                                    placeholder: 'Please choose a role:',
                                }}
                                onChange={this.handleRoleFieldChange}
                                />
                        </div>
						<TextField
							name={'status'}
							label={this.state.contributor.status.alias}
							className={classes.textField}
							margin="normal"
                            type="string"
							value={this.state.contributor.status.value}
							error={this.state.contributor.status.hasErrors.error}
							required={true}
							onChange={this.handleFieldValidation}
							variant="standard" // opt: standard, outlined or filled
							helperText={this.state.contributor.status.hasErrors.message}
                            inputProps={{
                                readOnly: true,
                            }}
                            InputProps={{
                                className: 'Mui-disabled',
                            }}
						/>
					</Grid>
					<Grid item md={12} lg={12} xs={12}>
						<Typography variant="caption" align="center" className={classes.sqlErrorMessage}>
							{this.state.sqlErrorMessage}
						</Typography>
					</Grid>
				</Grid>
				<div className={classes.newReleaseModalActions}>
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={() => this.props.onCancel()}>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="secondary"
						className={classes.button}
						disabled={this.state.actionInProgress.disableActionButton}
						onClick={() => this._handler.createContributor()}>
						Submit
					</Button>
				</div>
				{this.state.actionInProgress.openLinearLoading && (
					<LinearProgress color="secondary" variant="query" />
				)}
			</Modal>
		);
	}
}

NewContributor.propTypes = {
	classes : PropTypes.object.isRequired,
    display: PropTypes.bool.isRequired,
    account: PropTypes.object.isRequired,
    update: PropTypes.bool.isRequired,
    member: PropTypes.object.isRequired,
	onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(withSnackbar(withStyles(ContributorStyle)(NewContributor)));
