import React, { Component } from 'react';
import Slideshow from '../widgets/Slideshow';
import '../widgets/Slideshow.css';

const slideImages = [
  {
    link: '/assets/img/william-iven-gcsNOsPEXfs-unsplash-q.jpg',
    desc: ''
  },{
    link: '/assets/img/micheile-henderson-SoT4-mZhyhE-unsplash-trim-q.jpg',
    desc: ''
  },{
    link: '/assets/img/alvaro-reyes-qWwpHwip31M-unsplash-q.jpg',
    desc: ''
  },{
    link: '/assets/img/stephen-phillips-hostreviews-co-uk-em37kS8WJJQ-unsplash-q.jpg',
    desc: ''
  }
];

export default class Home extends Component {
  render() {
    return (
      <div>
        <div>
          <img 
              src="/assets/img/Gumasta-banner.jpg"
              alt="Gumasta Banner"
              style={{
                marginTop: -30,
                zoom: '60%'

              }} />
          <a style={{
            textAlign: 'right',
            fontSize: '2.250rem',
            marginLeft: '150px',
          }} href="/dashboard">Login</a>
        </div>
        <Slideshow slides={slideImages} />
      </div>
    )
  }
}