import React from 'react'
import {/*  object,  */string, func, element, oneOfType } from 'prop-types'
const XML2JS = require('xml2js');

function sgml2Xml(sgml) {
  return sgml
      .replace(/>\s+</g, '><')    // remove whitespace inbetween tag close/open
      .replace(/\s+</g, '<')      // remove whitespace before a close tag
      .replace(/>\s+/g, '>')      // remove whitespace after a close tag
      .replace(/<([A-Z0-9_]*)+\.+([A-Z0-9_]*)>([^<]+)/g, '<$1$2>$3' )
      .replace(/<(\w+?)>([^<]+)/g, '<$1>$2</$1>');
}

function deArrayfy(arr) {
    let out = arr.reduce((out, item) => {
      let obj = {};
      for (let key of Object.keys(item)) {
        obj[key] = item[key][0];
      }
      out.push(obj);
      return out;
    }, []);
    return out;
}

function prepareOutput(ofxJson) {
        //OFX.BANKMSGSRSV1[0].STMTTRNRS[0].STMTRS[0].BANKTRANLIST[0].STMTTRN
        //OFX.CREDITCARDMSGSRSV1[0].CCSTMTTRNRS[0].CCSTMTRS[0].BANKTRANLIST[0].STMTTRN

        let OFX = ofxJson.OFX;
        let reply = {
          parsed: false,
          raw: OFX,
          currency: '',
          type: 'unknown',
          id: 0,
          start: '',
          end: '',
          ledger: '',
          ledgerAsOf: '',
          available: '',
          availableAsOf: '',
          transactions: []
        };
        //let SIGNONMSGSRSV1 = OFX.SIGNONMSGSRSV1
        let STMTRS = {};
        if (OFX.hasOwnProperty('BANKMSGSRSV1') 
            && OFX.BANKMSGSRSV1[0].hasOwnProperty('STMTTRNRS') 
            && OFX.BANKMSGSRSV1[0].STMTTRNRS[0].hasOwnProperty('STMTRS')) {

          reply.type = 'bank'
          STMTRS = OFX.BANKMSGSRSV1[0].STMTTRNRS[0].STMTRS[0];

        } else if (OFX.hasOwnProperty('CREDITCARDMSGSRSV1') 
                && OFX.CREDITCARDMSGSRSV1[0].hasOwnProperty('CCSTMTTRNRS')
                && OFX.CREDITCARDMSGSRSV1[0].CCSTMTTRNRS[0].hasOwnProperty('CCSTMTRS')) {

          reply.type = 'cc'
          STMTRS = OFX.CREDITCARDMSGSRSV1[0].CCSTMTTRNRS[0].CCSTMTRS[0];

        } else {
            let MSGSRSV1 = OFX[Object.keys(OFX)[1]][0];
            let TRNRS = MSGSRSV1[Object.keys(MSGSRSV1)[0]][0];
            for (let key of Object.keys(TRNRS)) {
              if (!['TRNUID', 'STATUS'].includes(key)) {
                STMTRS = TRNRS[key][0];
                break;
              }
            }
        }

        // if (STMTRS.hasOwnProperty('BANKTRANLIST') && STMTRS.BANKTRANLIST[0].hasOwnProperty('STMTTRN')) {
        for (let key of Object.keys(STMTRS)) {
            let node = STMTRS[key][0];
            if (key === 'CURDEF') {
                reply.currency = node;
            } else if (key.endsWith('ACCTFROM')) {
                reply.id = node.ACCTID[0];
            } else if (key.endsWith('TRANLIST')) {
                reply.start = node.DTSTART[0];
                reply.end = node.DTEND[0];
                reply.parsed = true;
                reply.transactions = deArrayfy(node.STMTTRN);
            } else if (key === 'LEDGERBAL') {
                reply.ledger = node.BALAMT[0];
                reply.ledgerAsOf = node.DTASOF[0];
            } else if (key === 'AVAILBAL') {
                reply.available = node.BALAMT[0];
                reply.availableAsOf = node.DTASOF[0];
            }
        }
        // } else {
        //   reply.parsed = false;
        // }
        return reply;
}

const OFXParser = ({
  accept = '.ofx, .qfx, .qbo',
  cssClass = 'csv-reader-input',
  cssInputClass = 'csv-input',
  fileEncoding = 'UTF-8',
  inputId = null,
  inputStyle = {},
  // transOnly,
  label,
  onError,
  onFileLoaded,
  /* parserOptions = {}, */
}) => {
  const handleChangeFile = e => {
    let reader = new FileReader()
    if (e.target.files.length > 0) {
      const filename = e.target.files[0].name

      reader.onload = async event => {
        const buffer = event.target.result;
        // console.log(buffer);
        // firstly, split into the header attributes and the footer sgml
        const ofx = buffer.split('<OFX>', 2);
        
        // second, parse the headers
        const headerString = ofx[0].split(/\r?\n/);
        const header = {};
        headerString.forEach((attrs) => {
            if (attrs !== '' && attrs.includes(':')) {
                const headAttr = attrs.split(/:/,2);
                header[headAttr[0]] = headAttr[1];
            }
        });

        // make the SGML and the XML
        var content = `<OFX>${ofx[1]}`;
        // let json = {};
        XML2JS.parseStringPromise(content /*, options */)
          .then(function (result) {
              console.log('OFX processed in default method'); // + JSON.stringify(result, null, 2));
              // json = result;
              // if (transOnly === 'true') {
              //   json = json.OFX.BANKMSGSRSV1[0].STMTTRNRS[0].STMTRS[0].BANKTRANLIST[0].STMTTRN
              // }
              onFileLoaded(prepareOutput(result), filename)
          })
          .catch(function (err) {
              // Failover method
              content = sgml2Xml(content)
              XML2JS.parseStringPromise(content /*, options */).then(function (result) {
                  console.log('OFX processed in failover method'); // + JSON.stringify(result, null, 2));    
                  // json = result;
                  // if (transOnly === 'true') {
                  //   //result = result.OFX.BANKMSGSRSV1[0].STMTTRNRS[0].STMTRS[0].BANKTRANLIST[0].STMTTRN
                  //   json = json.OFX.CREDITCARDMSGSRSV1[0].CCSTMTTRNRS[0].CCSTMTRS[0].BANKTRANLIST[0].STMTTRN
                  // }
                  onFileLoaded(prepareOutput(result), filename)
              })
              .catch(function (err) {
                  // if (err.message === "Cannot read property '0' of undefined") {
                  //   try {
                  //     if (transOnly === 'true') {
                  //       json = json.OFX.BANKMSGSRSV1[0].STMTTRNRS[0].STMTRS[0].BANKTRANLIST[0].STMTTRN
                  //     }
                  //     onFileLoaded(prepareOutput(json), filename)
                  //     return;
                  //   } catch (error) {
                  //     console.log('OFX Processing produced an internal error: ' + error.message)
                  //   }
                  // }
                  console.log('OFX Processing produced an internal error: ' + err.message)
                  onError('An internal error occured while processing the supplied OFX file. Please check the file and submit again.')
              });
          });
      }

      reader.readAsText(e.target.files[0], fileEncoding)
    }
  }

  return (
    <div className={cssClass}>
      {label && <label htmlFor={inputId}>{label}</label>}
      <input
        className={cssInputClass}
        type="file"
        id={inputId}
        style={inputStyle}
        accept={accept}
        onChange={e => handleChangeFile(e)}
      />
    </div>
  )
}

OFXParser.propTypes = {
  /* accept: string,
  cssClass: string,
  cssInputClass: string,
  fileEncoding: string,
  inputId: string,
  inputStyle: object,*/
  // transOnly: string,
  label: oneOfType([string, element]),
  onError: func, 
  onFileLoaded: func.isRequired,
  /* parserOptions: object, */
}

export default OFXParser
