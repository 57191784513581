import { combineReducers } from 'redux';
import pageTitleReducer from './pageTitleReducer';
import appStateReducer from './appStateReducer';
import authTokenReducer from './authTokenReducer';
import uiReducer from './uiReducer';
import authRefreshTokenReducer from './authRefreshTokenReducer';

const rootReducer = combineReducers({
  pageTitle: pageTitleReducer,
  appState: appStateReducer,
  authToken: authTokenReducer,
  authRefreshToken: authRefreshTokenReducer,
  snackBar: uiReducer
});

export default rootReducer;
