
import axios from 'axios';
import IndexDb from './indexDb';
import Constants from './constants';
import { getSession } from './Session';
let SESSION_KEYS = Constants.SESSION_KEYS;
let keyTranTypes = IndexDb.KEYS.TRAN_TYPES;

let defaultErrorHandler = (err, reject, errMsg = 'An unknown error has occurred. Please check console.') => {
  console.log(err);
  if (err.response === undefined) {
    reject({type: 'error', message: errMsg})
  } else if (err.response.status === 419 || err.response.status === 401) {
    reject({type: 'timeout', message: 'Session expired. Please Login again.'})
  } else {
    let msg = err.message;
    if (err.response && err.response.data && err.response.data.message) {
      msg = err.response.data.message;
    }
    reject({type: 'error', message: msg})
  }
}

export const getInstance = () => {
  return axios.create({ headers: { 'x-access-token': getSession() } })
}

export const syncTranType = async (by, val, tag='B') => {
  return new Promise(async (resolve, reject) => {
    let url = Constants.URLs.TRAN_TYPES + '/sync-' + by;
    console.log('sending Sync request: ' + url)
    let payload = {tag: tag};
    if (by === 'ac') {
      payload.acType = val;
    } else if (by === 'tran') {
      payload.tranType = val;
    } else {
      reject({type: 'timeout', message: 'Invalid [by] supplied'})
    }
    await getInstance()
      .post(url, payload)
      .then((response) => {
          resolve({
              type: ((response === 200) ? 'success' : 'warning'), 
              message: response.message
          });
      })
      .catch(err => defaultErrorHandler(err, reject, 
        'An error occured while syncing transaction type. Please check console.'));
  });
}

export const deleteTranType = async (by, val) => {
  return new Promise(async (resolve, reject) => {
    let url = Constants.URLs.TRAN_TYPES + '/delete-' + by;
    console.log('sending delete request: ' + url)
    let payload = {};
    if (by === 'ac') {
      payload.acType = val;
    } else if (by === 'tran') {
      payload.tranType = val;
    } else {
      reject({type: 'timeout', message: 'Invalid [by] supplied'})
    }
    await getInstance()
      .post(url, payload)
      .then((response) => {
          resolve({
              type: ((response === 200) ? 'success' : 'warning'), 
              message: response.message
          });
      })
      .catch(err => defaultErrorHandler(err, reject, 
        'An error occured while deleting transaction type. Please check console.'));
  });
}


export const getTranTypesByAc = async (type='DEF') => {
  return new Promise(async (resolve, reject) => {
    let retrieveFrom = Constants.URLs.TRAN_TYPES + '/ac';
		// let type = 'DEF'; //default value
		if (sessionStorage.getItem(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE)) {
			type = sessionStorage.getItem(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE);
		}
    await getInstance()
      .post(retrieveFrom, {acType: type})
      .then((response) => {
        IndexDb.setItem(keyTranTypes, response.data)
          .then((value) => {
            resolve(value);
          })
          .catch((err) => {
            console.log(err);
            reject({type: 'error', message: err.message});
          })
      })
      .catch(err => defaultErrorHandler(err, reject, 
        'An error occured while fetching account types from server. Please check console.'));
  });
}

