import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, TextField, LinearProgress, Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import {AccountStyle} from './AccountStyle';
import { cloneDeep } from 'lodash';
import {validator} from '../utils/validator';
import { acctObject, mapAccount } from './acctObject';
import moment from 'moment-timezone';
import { getInstance } from '../utils/axiosLoader';
import Constants from '../utils/constants';
import DatePicker from './DatePicker';
import mapStateToProps from '../actions/stateToProps';
import { AccountHandler } from '../api';
import Messenger from '../utils/Messenger';
import ListItemHandler from '../api/ListItemHandler';

class NewAccount extends Component {
    constructor(props) {
      super(props)
      this._handler = new AccountHandler(this);
      this._handlerListItem = new ListItemHandler(this);
      this.handleAccountCurrFieldChange = this.handleAccountCurrFieldChange.bind(this)
      this.handleAccountTypeFieldChange = this.handleAccountTypeFieldChange.bind(this)
    }

    state = {
		currencies: [],
		accountTypes: ['DEF'],
		sqlErrorMessage: '',
		actionInProgress      : {
			openLinearLoading   : false,
			disableActionButton : false
		},
		account : mapAccount(this.props.account),
        accountArchiveConfirmation: false,
        page : 0
    };

	componentDidMount = () => {
		this._isMounted = true;

		if (this._isMounted) {
			this.handleGetCurrencies();
			this.handleGetAcctTypes();
		}
    };

    handleGetCurrencies = async () => {
        // console.log('handleGetCurrencies')
        this._handlerListItem.getCurrencies()
		.then((data) => {
            // console.log('returned to New Account', data);
            this.setState({ currencies: data });
		})
		.catch((err) => {
            console.error(err)
			console.log('error while fetching currencies:' + err.message);
            this.setState({ currencies: [] });
		})
	}

	handleGetAcctTypes = async () => {
        // console.log('handleGetAcctTypes')
        this._handlerListItem.getAcctTypes()
		.then((data) => {
            let filter = data;
            if(window.isAndroid()) {
                filter = data.filter((value)=>{
                    return value.code !== 'CHIT';
                })
            }
            this.setState({ accountTypes: filter });
		})
		.catch((err) => {
            console.error(err)
			console.log('error while fetching account types:' + err.message);
            this.setState({ accountTypes: [] });
		})
	}

    handleAllAccountFieldValidation = () => {
		let _account = this.state.account;
        let allPassed = true;

        console.log("_account", _account)

        Object.keys(_account).forEach((name)=>{
            let _field = _account[name];
            let rules = Object.keys(_field.rules);
            console.log("_field", _field)

            if (rules) {
                for (let rule of rules) {
                    if (_field.rules[rule] && rule in validator) {
                        let validation = validator[rule](_field.value, _field.rules[rule]);
    
                        if (!validation.passed) {
                            _field.hasErrors.error = true;
                            _field.hasErrors.message = validation.message;
                            allPassed = false;
    
                            break;
                        }
                        else {
                            _field.hasErrors.error = false;
                            _field.hasErrors.message = validation.message;
                        }
                    }
                }
            }
        })

		this.setState({
			account : _account,
		});
        if (allPassed) {
            if(_account.type.value === 'CHIT') {
                this.props.onInitChitInfo(_account);
            } else {
                this.handleCreateAccount();
            }
        } else {
            let warning = Object.entries(_account).reduce((warn, [field, data])=>{
                warn += `\n${field} = [${data.value}] ${data.hasErrors.error ? data.hasErrors.message : 'OK'}\n`;
                return warn;
            },'');
            this._handler.showWarn('some data validations failed. Please check console.')
            console.log('some data validations failed.', warning);
        }
    }

	handleAccountFieldValidation = (e) => {
		let _account = this.state.account;
		let _field = _account[e.target.name];
		let rules = Object.keys(_field.rules);

		_field.value = e.target.value;

		if (rules) {
			for (let rule of rules) {
				if (_field.rules[rule] && rule in validator) {
					let validation = validator[rule](_field.value, _field.rules[rule]);

					if (!validation.passed) {
						_field.hasErrors.error = true;
						_field.hasErrors.message = validation.message;

						break;
					}
					else {
						_field.hasErrors.error = false;
						_field.hasErrors.message = validation.message;
					}
				}
			}
		}

		this.setState({
			account : _account
		});
    }

	handleCreateAccount = async () => {
		let _account = this.state.account;
        this._handler.nav.setActionInProgress();
		
		let preparedReleaseObject = this._handler.prepareObjectForSaving(_account);
        // console.log(`preparedReleaseObject: `, preparedReleaseObject);
		let responseErr = '';
		await getInstance()
			.post(`${Constants.URLs.ACCOUNTS}`, preparedReleaseObject)
			.then(async (response) => {
                this.props.enqueueSnackbar('Account created successfully', {
                    variant : 'success'
                });
                return this._handler.getAccountsCached(true).then(()=>{
                    _account = cloneDeep(acctObject);
                })
                
			})
			.catch((error) => {
                console.log(error.response);
                if (error.response.data && error.response.data.message.startsWith('E11000 duplicate key error')) {
                    this.props.enqueueSnackbar('Error: Account Code already exists. Please fix and try again.', {
                        variant : 'error'
                    });
                    responseErr = 'Account code should be unique.';
                } else {
                    responseErr = error.message;
                    this.props.enqueueSnackbar('An error occurred from the server', {
                        variant : 'error'
                    });
                }
			})
			.finally(()=>{
                this._handler.nav.unsetActionInProgress();
				this.setState({
					sqlErrorMessage  : responseErr,
					account: _account,
				});
				if (responseErr === '') {
					this.props.onSubmit();
				}
			})
	};

    handleCloseAccount = async () => {
        this.setState({ 
            accountArchiveConfirmation: false
        })
        this.props.onBusy(true);
        let _id = this.props.account._id;
        if (!_id) {
            this.props.enqueueSnackbar('Account Id missing to update', {
                variant : 'warning'
            });
            return;
        }
        let hooks = {
            before: () => this._handler.nav.showOverlay(),
            after: () => {
                this._handler.nav.hideOverlay();
                this._handler.showSuccess(`Account archived successfully.`);
            },
            // error: () => {}
        }
        this._handler.archiveAccount(_id, hooks).then((ac)=>{
            // console.log("ac", ac);
            //just re-load accounts to cache.
            return this._handler.getAccountsCached(true).then(()=>{
                window.open('/dashboard',"_self");
            })
        }).finally(()=>this.props.onBusy(false))
    }
    
    handleUpdateAccount = async () => {
        let _id = this.props.account._id;
        if (!_id) {
            this.props.enqueueSnackbar('Account Id missing to update', {
                variant : 'warning'
            });
            return;
        }
        let payloadAccount = this._handler.prepareObjectForSaving(this.state.account);
        this._handler.nav.setActionInProgress();
        
        await getInstance()
            .patch(`${Constants.URLs.ACCOUNTS}/` + _id, payloadAccount)
            .then((response) => {
                if (response.status === 400) {
                    this.props.enqueueSnackbar('Error: An internal error occured while saving account..', {
                        variant : 'error'
                    });
                    this.setState({
                        sqlErrorMessage  : response.data.message,
                    });
                    this.props.onSubmit();
                }
                else {
                    this.props.enqueueSnackbar('Account updated successfully', {
                        variant : 'success'
                    });
                    // this.setState({ 
                    //     openOverlay: true, 
                    // });
                    this.props.onSubmit();
                }
            })
            .catch((error) => {
                console.log(error);
                this.props.enqueueSnackbar('Error: Could not update the account.', {
                    variant : 'error'
                });
            }).finally(async ()=>{
                this._handler.nav.unsetActionInProgress();
                
                if (this.props.account.curr !== payloadAccount.curr) {
                    // console.log('currency updated, refreshing transactions')
                    let payload = {
                        account: _id,
                        updates: {	
                            curr : payloadAccount.curr
                        }
                    }
                    await getInstance()
                        .post(`${Constants.URLs.TRANS}/bulkaccountupdate/ac/${_id}`, payload)
                        .then(async (response) => {
                            if (response.status === 400) {
                                this.props.enqueueSnackbar('Error: An internal error occured while refreshing transactions.', {
                                    variant : 'error'
                                });
                            } else {
                                this.props.enqueueSnackbar('Transactions currency updated successfully', {
                                    variant : 'success'
                                });
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.props.enqueueSnackbar('An error occurred from the server while refreshing transactions', {
                                variant : 'error'
                            });
                        }).finally(()=>this.props.onSubmit())
                } else {
                    this.props.onSubmit();
                }
            })
    };
  
  
	handleAccountCurrFieldChange(e) {
		let _account = this.state.account;
		_account.curr.value = e.target.value;
		this.setState({ account: _account });
	}
	handleAccountTypeFieldChange(e) {
		let _account = this.state.account;
		_account.type.value = e.target.value;
		this.setState({ account: _account });
	}

    renderPageForm() {
        let _this = this;
        const {classes} = this.props;
        let {account, accountTypes} = this.state;
        let _name = account['name'];
        let _code = account['code'];
        let _date = account['date'];
        let _desc = account['desc'];
        return (
            <Grid item md={12} lg={12} xs={12}>
                    <TextField
                        name={'name'}
                        label={_name.alias}
                        data-cy={"txtAccountname"}
                        className={classes.textField}
                        value={_name.value ? _name.value : ""}
                        type={_name.type ? _name.type : 'text'}
                        required={true}
                        onChange={this.handleAccountFieldValidation}
                        variant="standard" // opt: standard, outlined or filled
                        helperText={_name.hasErrors.message}
                        inputProps={{
                            readOnly: this.props.readOnly,
                        }}
                        InputProps={{
                            className: (this.props.readOnly) ? 'Mui-disabled' : undefined,
                        }}
                    />
                    
                    <TextField
                        name={'code'}
                        label={_code.alias}
                        data-cy={"txtAccountcode"}
                        className={classes.textField}
                        value={_code.value ? _code.value : ""}
                        type={_code.type ? _code.type : 'text'}
                        required={true}
                        onChange={this.handleAccountFieldValidation}
                        variant="standard" // opt: standard, outlined or filled
                        helperText={_code.hasErrors.message}
                        inputProps={{
                            readOnly: this.props.modeUpdate || this.props.readOnly,
                        }}
                        InputProps={{
                            className: (this.props.modeUpdate || this.props.readOnly) ? 'Mui-disabled' : undefined,
                        }}
                    />
                    
                    <Select
                        name="select-type"
                        data-cy="ddAccountSelectType"
                        value={account.type.value ? account.type.value : ""}
                        className={classes.textField}
                        style={{paddingTop: '14px' }}
                        onChange={this.handleAccountTypeFieldChange}
                        disabled={this.props.modeUpdate}
                        >
                            {accountTypes.map((type, index) => {
                                return (
                                    <MenuItem
                                        key={`type-${type.code}`}
                                        value={type.code}>
                                        {`${type.name}`}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    <Select
                        name="select-currency"
                        data-cy="ddAccountSelectCurrency"
                        value={account.curr.value ? account.curr.value : ""}
                        className={classes.textField}
                        style={{paddingTop: '20x' }}
                        onChange={this.handleAccountCurrFieldChange}
                        disabled={this.props.readOnly}
                        >
                            {this.state.currencies && this.state.currencies.map((curr, index) => {
                                return (
                                    <MenuItem
                                        key={`type-${curr.code}`}
                                        value={curr.code}>
                                        {`${curr.code}`}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    {!window.isAndroid() && 
                        <TextField
                            name={'date'}
                            label={_date.alias}
                            data-cy={"txtAccountdate"}
                            className={classes.textField}
                            value={_date.value ? _date.value : ""}
                            type={_date.type ? _date.type : 'text'}
                            required={true}
                            onChange={this.handleAccountFieldValidation}
                            variant="standard" // opt: standard, outlined or filled
                            helperText={_date.hasErrors.message}
                            inputProps={{
                                readOnly: this.props.readOnly,
                            }}
                            InputProps={{
                                className: (this.props.readOnly) ? 'Mui-disabled' : undefined,
                            }}
                        />
                    }
                    {window.isAndroid() && <label htmlFor="newAccountDate">Date:</label>}
                    {window.isAndroid() && <DatePicker 
                        id="newAccountDate"
                        onSetDate={(d)=>{
                            let _ac = account;
                            _ac.date.value = moment(d).format('YYYY-MM-DD');
                            // console.log("date selected", JSON.stringify(_ac.date));
                            _this.setState({ account: _ac })
                        }}
                        label={account.date.alias}
                        value={account.date.value}
                        inputProps={{
                            readOnly: this.props.readOnly,
                        }}
                        InputProps={{
                            className: (this.props.readOnly) ? 'Mui-disabled' : undefined,
                        }}
                    />}
                    {
                        <TextField
                            name={'desc'}
                            label={_desc.alias}
                            data-cy={"txtAccountdesc"}
                            className={classes.textField}
                            value={_desc.value ? _desc.value : ""}
                            type={_desc.type ? _desc.type : 'text'}
                            required={true}
                            onChange={this.handleAccountFieldValidation}
                            variant="standard" // opt: standard, outlined or filled
                            helperText={_desc.hasErrors.message}
                            inputProps={{
                                readOnly: this.props.readOnly,
                            }}
                            InputProps={{
                                className: (this.props.readOnly) ? 'Mui-disabled' : undefined,
                            }}
                        />}
                </Grid>
        )
    }

    
    renderActions() {
        const {classes} = this.props;
        let {account} = this.state;
        return (
            <Grid item md={12} lg={12} xs={12}>
                <div className={classes.newReleaseModalActions} data-cy="mdlCreateNewReleaseActions">
                    {!this.props.hideActions && this.props.readOnly && <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        data-cy="mdlCreateNewAccountActionEdit"
                        onClick={() => this.props.onEnableEdit()}>
                        Edit
                    </Button>}
                    {!this.props.hideActions && !this.props.readOnly && <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        data-cy="mdlCreateNewAccountActionCancel"
                        onClick={() => this.props.onCancel()}>
                        Cancel
                    </Button>}
                    {!this.props.hideActions && !this.props.readOnly && !this.props.modeUpdate && <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        data-cy="mdlCreateNewAccountActionSubmit"
                        disabled={this.state.actionInProgress.disableActionButton}
                        onClick={this.handleAllAccountFieldValidation}>
                        {account.type.value === 'CHIT' ? `Next` : `Submit`}
                    </Button>}
                    {!this.props.hideActions && !this.props.readOnly && this.props.modeUpdate && <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        data-cy="mdlCreateNewAccountActionUpdate"
                        disabled={this.state.actionInProgress.disableActionButton}
                        onClick={this.handleUpdateAccount}>
                        Update
                    </Button>}
                    {!this.props.hideActions && !this.props.readOnly && this.props.modeUpdate && !this.props.hideArchive && <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        data-cy="mdlCreateNewAccountActionArchive"
                        disabled={this.state.actionInProgress.disableActionButton}
                        onClick={() => this.setState({ accountArchiveConfirmation: true })}>
                        Archive Account
                    </Button>}
                </div>
            </Grid>
        )
    }

    renderForm() {
        const {classes} = this.props;
        return (
            <Grid container className={classes.newReleaseModal} data-cy="mdlCreateNewAccountGrid">
                <Grid item md={12} lg={12} xs={12}>
                    <Typography variant="h6" align="center">
                        {this.props.modeUpdate ? '' : 'Create new Account'}
                    </Typography>
                </Grid>

                {this.renderPageForm()}
                
                <Grid item md={12} lg={12} xs={12}>
                    <Typography variant="caption" align="center" className={classes.sqlErrorMessage}>
                        {this.state.sqlErrorMessage}
                    </Typography>
                </Grid>

                {this.renderActions()}

                {this.state.actionInProgress.openLinearLoading && (
                    <LinearProgress color="secondary" variant="query" />
                )}
            </Grid>
        )
    }

    renderAsGrid() {
        return (
            <div>
                {this.props.display && this.renderForm()}
                <Dialog
                    open={this.state.accountArchiveConfirmation}
                    style={{ padding: '30px' }} >
                    <Typography>
                        {Messenger.getAccountArchiveConfirmation()}
                    </Typography>
                    <Button 
                        color="secondary"
                        onClick={() => this.handleCloseAccount()}>
                        Yes
                    </Button>
                    <Button  
                        color="primary"
                        onClick={() => this.setState({ accountArchiveConfirmation: false })}>
                        No
                    </Button>
                </Dialog>
            </div>
        )
    }

    renderAsDialog() {
        return (
            <Dialog
                open={this.props.display}
                onClose={() => {}}
                style={{margin: '10px'}}>
                {this.renderForm()}
            </Dialog>
        )
    }

    render() {
        if (this.props.asDialog) {
            return this.renderAsDialog();
        } 
        return this.renderAsGrid();
    }
}

NewAccount.propTypes = {
  classes: PropTypes.object.isRequired,
  display: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onInitChitInfo: PropTypes.func.isRequired,
  modeUpdate:  PropTypes.bool.isRequired,
  onBusy: PropTypes.func,
  account: PropTypes.object,
  asDialog: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  onEnableEdit: PropTypes.func,
  hideActions: PropTypes.bool,
  hideArchive: PropTypes.bool
};

export default connect(mapStateToProps)(withSnackbar(withStyles(AccountStyle)(NewAccount)));
  