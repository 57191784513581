import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { cloneDeep as _cloneDeep } from 'lodash';
import moment from 'moment-timezone';

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, LinearProgress, Dialog, InputLabel } from '@material-ui/core';

import './Tran.css'
import {TranStyle} from './TranStyle';
import { getInstance } from '../utils/axiosLoader';
import Constants from '../utils/constants';
import mapStateToProps from '../actions/stateToProps';
import { tranObject } from './tranObject';

let classes = null;

function cloneTransactions(trans=[], fromAc='unknown') {
    return trans.reduce((arr, tran)=>{
        let tranToLink = _cloneDeep(tranObject);
        if (tran._id) tranToLink._id = tran._id;
        if (tran.sno) tranToLink.sno.value = tran.sno;
        if (tran.dr) tranToLink.dr.value = tran.dr;
        if (tran.cr) tranToLink.cr.value = tran.cr;
        if (tran.bal) tranToLink.bal.value = tran.bal;
        if (tran.curr) tranToLink.curr.value = tran.curr;
        if (tran.type) tranToLink.type.value = tran.type;
        if (tran.qtr) tranToLink.qtr.value = tran.qtr;
        if (tran.account) tranToLink.account.value = tran.account;
        if (tran.date) tranToLink.date.value = tran.date.substr(0,10)
        if (tran.desc) tranToLink.desc.value = tran.desc;
        if (tran.note) tranToLink.note.value = tran.note;

        arr.push(tranToLink);
        return arr;
    }, []);
}

class CopyTran extends Component {
	constructor(props) {
		super(props)
		classes = this.props.classes;
		this.handleFieldLinkAccountChange = this.handleFieldLinkAccountChange.bind(this)
	}

	state = {
		actionInProgress      : {
			openLinearLoading   : false,
			disableActionButton : false,
			disableLinkAction: true
		},
		sqlErrorMessage: '',
		mode: this.props.mode,
		linkAccountId: 'unknown',
        accounts: [],
        transactions: []
	};

	componentDidMount() {
        let updates = {};

        let lastAc = sessionStorage.getItem(Constants.SESSION_KEYS.LAST_LINK_ACCOUNT);
		if (lastAc) {
			let _actionInProgress = this.state.actionInProgress;
            _actionInProgress.disableLinkAction = false;
            updates.linkAccountId = lastAc;
            updates.actionInProgress = _actionInProgress;
        }

        let accounts = this.props.accountLineUp;
        let trans = this.props.transactions;
        let fromAc;
        let active = sessionStorage.getItem(Constants.SESSION_KEYS.ACTIVE_ACCOUNT);
        if (active !== undefined && active !== '') {
            fromAc = accounts.filter((item) => {
                return item._id === active; 
            })[0];
        }
        if (!fromAc) {
            this.props.onError('Please select an account before move/copy a transaction');
            return;
        }        
        
        //Allow link between accounts with same current for now; excluding current account
        let linkEligibleAccounts = accounts.filter((item) => {
            return item.isOwner && (
                (item.curr === fromAc.curr && item._id !== fromAc._id) || item._id === 'unknown' 
            ); 
        });
        updates.accounts = linkEligibleAccounts;

        let transToLink = cloneTransactions(trans, fromAc);
        updates.transactions = transToLink;
        
        this.setState(updates);
	}

	handleFieldLinkAccountChange(e) {
		let _actionInProgress = this.state.actionInProgress;
		_actionInProgress.disableLinkAction = (e.target.value === 'unknown')
		this.setState({
			linkAccountId: e.target.value,
			actionInProgress: _actionInProgress
		});
	}

	prepareReleaseObjectForSaving = (_transaction = null) => {
		let preparingReleaseObject = {};
		let _keys = Object.keys(_transaction);
		for (let key of _keys) {
			if (key !== '_id') { 
				preparingReleaseObject[key] = _transaction[key].value;
			}
		}
		//Currently only same currency account is possible;
		//TODO: ability to chose a different currency account & do currency conversion
		preparingReleaseObject.curr = this.props.account.curr;
		//Current transaction id becomes 'link' transaction
		// preparingReleaseObject.link = _transaction._id;
		//Transfer the transaction to the new account
		preparingReleaseObject.account = this.state.linkAccountId;
		//reset transaction id
		delete preparingReleaseObject._id;
		
		// if (_transaction._id) preparingReleaseObject._id = _transaction._id;
		return preparingReleaseObject;
	};
	
	handleCopyMultiTransactions = async () => {
		let _transactions = this.state.transactions;
		/* TODO: make this error disable the submit button in 'FieldsErrorFree' logic similar to new list item. */
		if (this.props.account.code === 'unknown') {
			this.props.enqueueSnackbar('Selected Account is invalid. Transaction cannot be linked.', {
				variant : 'error'
			});
			return;
		}
		let _actionInProgress = this.state.actionInProgress;
		_actionInProgress.disableActionButton = true;
		_actionInProgress.openLinearLoading = true;
		this.setState({
			actionInProgress : _actionInProgress
		});
		
		let _processed = _transactions.reduce((arr, _transaction)=>{
			arr.push(this.prepareReleaseObjectForSaving(_transaction))
			return arr;
		},[]);
		
		console.log('bulk transactions before copy process:', _processed);
		// let preparedReleaseObject = this.prepareReleaseObjectForSaving(_transactions);
		
		sessionStorage.setItem(Constants.SESSION_KEYS.LAST_LINK_ACCOUNT, _processed[0].account);
		let responseErr = '';
		// console.log('sending link request to:' + `${Constants.URLs.TRANS}/${_transaction._id}`, preparedReleaseObject)
		await getInstance()
			.post(`${Constants.URLs.TRANS}/bulkcopy/ac/${this.state.linkAccountId}`, _processed)
			.then(async (response) => {
				if (response.status === 400) {
					console.log(response)
					this.props.enqueueSnackbar('Error: An internal error occured while copied transaction.', {
						variant : 'error'
					});
					responseErr = response.data.message
				}
				else {
					this.props.enqueueSnackbar('Transaction copied successfully', {
						variant : 'success'
					});
					//set the target account to refresh on next visit
					sessionStorage.setItem(_processed[0].account + '_req_ts', moment().format('YYYYMMDDhhmmss'));
					// _transaction = cloneDeep(tranObject)
				}
			})
			.catch((error) => {
				console.log(error);
				let msg = 'An internal error occured';
				if (error.response && error.response.data && error.response.data.message) {
					msg = error.response.data.message;
				}
				responseErr = msg;
				this.props.enqueueSnackbar(msg, {
					variant : 'error'
				});
			})
			.finally(()=>{
				_actionInProgress.disableActionButton = false;
				_actionInProgress.openLinearLoading = false;
				this.setState({
					sqlErrorMessage  : responseErr,
					// transaction: _transaction,
					actionInProgress : _actionInProgress
				});
				if (responseErr === '') {
					this.props.onSubmit();
				}
			})
	}

	handleMoveMultiTransactions = async () => {
		let _ac = this.state.linkAccountId;
		let _transactions = this.state.transactions;
		/* TODO: make this error disable the submit button in 'FieldsErrorFree' logic similar to new list item. */
		if (this.props.account.code === 'unknown') {
			this.props.enqueueSnackbar('Selected Account is invalid. Transaction cannot be moved.', {
				variant : 'error'
			});
			return;
		}
		let _actionInProgress = this.state.actionInProgress;
		_actionInProgress.disableActionButton = true;
		_actionInProgress.openLinearLoading = true;
		this.setState({
			actionInProgress : _actionInProgress
		});
		
		let ids = _transactions.reduce((arr, _transaction)=>{
			arr.push(_transaction._id)
			return arr;
		},[]);
		let payload = {
			account: _ac,
			ids: ids
		}
		
		console.log('bulk transactions before move process:', payload);
		sessionStorage.setItem(Constants.SESSION_KEYS.LAST_LINK_ACCOUNT, _ac);
		let responseErr = '';
		// console.log('sending link request to:' + `${Constants.URLs.TRANS}/${_transaction._id}`, preparedReleaseObject)
		await getInstance()
			.post(`${Constants.URLs.TRANS}/bulkmove/ac/${this.state.linkAccountId}`, payload)
			.then(async (response) => {
				if (response.status === 400) {
					console.log(response)
					this.props.enqueueSnackbar('Error: An internal error occured while moving transactions.', {
						variant : 'error'
					});
					responseErr = response.data.message
				}
				else {
					this.props.enqueueSnackbar('Transaction moved successfully', {
						variant : 'success'
					});
					//set the target account to refresh on next visit
					sessionStorage.setItem(_ac + '_req_ts', moment().format('YYYYMMDDhhmmss'));
				}
			})
			.catch((error) => {
				console.log(error);
				let msg = 'An internal error occured';
				if (error.response && error.response.data && error.response.data.message) {
					msg = error.response.data.message;
				}
				responseErr = msg;
				this.props.enqueueSnackbar(msg, {
					variant : 'warning'
				});
			})
			.finally(()=>{
				_actionInProgress.disableActionButton = false;
				_actionInProgress.openLinearLoading = false;
				this.setState({
					sqlErrorMessage  : responseErr,
					// transaction: _transaction,
					actionInProgress : _actionInProgress
				});
				if (responseErr === '') {
					this.props.onSubmit();
				}
			})
	}


	/**
	 * Render UI components
	 */

	renderFormTitle() {
		let label = this.props.mode === 'copy' ? "Copy " : "Move ";
		label += ' transaction in';
		return (
			<Grid item md={12} lg={12} xs={12} style={{marginBottom: "5px"}}>
				<Typography align="center">
					{label}
				</Typography>
				<Typography variant="h5" align="center">
					{this.props.account.name}
				</Typography>
			</Grid>
		)
	}

	renderFormCopyMoveAccount() {
		// let last = sessionStorage.getItem(Constants.SESSION_KEYS.LAST_LINK_ACCOUNT);
		// if (!last) {
		// 	last = 'unknown';
		// }
		return (
			<Grid item md={6} sm={6} xs={6}>
				<div className={classes.tranAccount}>
					<InputLabel>Select Account To {this.props.mode === 'copy' ? 'Copy' : 'Move'}:</InputLabel>
					<Select
						name="select-link-account"
						value={this.state.linkAccountId}
						onChange={this.handleFieldLinkAccountChange}
						className={'trans-account-select-width'}>
						{this.props.accountLineUp.map((account, index) => {
							return (
								<MenuItem
									key={`account-${account._id}`}
									value={account._id}>
									{`${account.name}`}	
								</MenuItem>
							);
						})}
					</Select>
				</div>
			</Grid>
		)
	}
	
	renderActions() {
		return (
			<div className={classes.newAccountModalActions}>
				<Button
					variant="contained"
					color="primary"
					className={classes.button}
					onClick={() => this.props.onCancel()}>
					Cancel
				</Button>
				{this.props.mode === 'copy' && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableLinkAction}
					onClick={this.handleCopyMultiTransactions}>
					Bulk Copy
				</Button>}
				{this.props.mode === 'move' && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableLinkAction}
					onClick={this.handleMoveMultiTransactions}>
					Bulk Move
				</Button>}
			</div>
		)
	}

	renderLinearLoader() {
		return (
			this.state.actionInProgress.openLinearLoading &&
				<LinearProgress color="primary" variant="query" />
		)
	}

	renderMultiMoveCopyForm() {
		return (
			<Dialog
					open={this.props.display}
					onClose={() => {}}
					styles={{ modal: { padding: 0 } }} >
					<Grid container className={classes.newAccountModal}>
							{this.renderFormTitle()}
							{this.renderFormCopyMoveAccount()}
							</Grid>
								<Grid container className={classes.newAccountModalActions}>
								{this.renderActions()}
								{this.renderLinearLoader()}
							</Grid>
			</Dialog>
		);
	}

	

	render() {
		return (
			this.renderMultiMoveCopyForm()
		);
	}
}

CopyTran.propTypes = {
	classes : PropTypes.object.isRequired,
    display: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired,
	tranTypes: PropTypes.array.isRequired,
	account: PropTypes.object.isRequired,
	isCredit: PropTypes.bool.isRequired,
	//Needed for update transaction
	mode: PropTypes.string.isRequired,
	accountLineUp: PropTypes.array.isRequired,
	transactions: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(withSnackbar(withStyles(TranStyle)(CopyTran)));
