import moment from 'moment-timezone';
import { cloneDeep as _cloneDeep } from 'lodash';
import Constants from '../utils/constants';
import { fieldTypes } from '../utils/validator';
let isRequired = true;
let isOptional = false;

export const tranObject = {
  sno     : fieldTypes.Amount('sno', 'S.No', isRequired, '0'),
  dr      : fieldTypes.Amount('dr', 'Debit', isRequired, '0.00'),
  cr      : fieldTypes.Amount('cr', 'Credit', isRequired, '0.00'),
  bal     : fieldTypes.Amount('bal', 'Balance'),
  curr    : fieldTypes.Text('curr', 'Currency', isOptional, 'CAD'),
  type    : fieldTypes.Text('type', 'Transaction Type', isOptional, 'adjustment'),
  qtr     : fieldTypes.Text('qtr', 'Quarter', isOptional, Constants.getQtrStr()),
  account : fieldTypes.Text('account', 'Account', isOptional, 'ICICI_CC_3000'),
  date    : fieldTypes.Date('date', 'Date'),
  desc    : fieldTypes.Text('desc', 'Description'),
  note    : fieldTypes.Text('note', 'Notes')
};

export const mapTran = (_tran) => {
  let tran = _cloneDeep(tranObject);
  if (!_tran) {
      return tran;
  }
  let {_id, sno, dr, cr, bal, curr, type, qtr, account, date, desc, note} = _tran;
  if (_id) tran._id = _id;
  if (sno) tran.sno.value = sno;
  if (dr) tran.dr.value = dr;
  if (cr) tran.cr.value = cr;
  if (bal) tran.bal.value = bal;
  if (curr) tran.curr.value = curr;
  if (type) tran.type.value = type;
  if (qtr) tran.qtr.value = qtr;
  if (account) tran.account.value = account;
  tran.date.value = date ? date.substr(0,10) : moment(date).format('YYYY-MM-DD');
  if (desc) tran.desc.value = desc;
  if (note) tran.note.value = note;
  return tran;
}