import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

import rootReducer from './reducers/rootReducer';
import { setSession, killSession, setRefreshToken } from './components/utils/Session';
import IndexDb from './components/utils/indexDb';
import AuthRoute from './components/utils/AuthRoute';
import Home from './components/layout/Home';
import Auth from './Auth';

const store = createStore(rootReducer);

const handleLogout = () => {
  //clear current user info
  IndexDb.removeItem(IndexDb.KEYS.USER_INFO)
  // kill the session cookie
  killSession();
  return <Redirect to="/auth/login" />;
}

function setAuthState(state) {
  try {
    setSession(state.authToken);
    setRefreshToken(state.authRefreshToken);
  } catch (err) { return undefined; }
}

store.subscribe(() => {
  setAuthState(store.getState())
});

const renderAuthenticatedView = () => {
  return (
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        autoHideDuration={4000}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/logout" component={handleLogout} />
            <Route exact path="/" component={Home} />
            <AuthRoute path="/auth" component={Auth} />
            <Route path="/" component={App} />
          </Switch>
        </BrowserRouter>
      </SnackbarProvider>
    </Provider>
  )
}

ReactDOM.render(
  renderAuthenticatedView(),
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
