import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import './AccountInfo.css';
import { AccountStyle } from './AccountStyle';
import mapStateToProps from '../actions/stateToProps';
import mapDispatchToProps from '../actions/dispatchProps';
import Constants from '../utils/constants';
import LoadingOverlay from '../common/loadingOverlay';
import { Button, Typography, Dialog, Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import NewContributor from './NewContributor';
import { AccountHandler } from '../api';

class SharingManager extends React.Component {

  constructor(props) {
    super(props);
    this._isMounted = false; // prevents -> Warning: Can't perform a React state update on an unmounted component
    this._accountHandler = new AccountHandler(this);
  }

  state = {
    open: {
      openNewContributor: false,
      contributorDeleteConfirmation: false,
    },
    roles: this.props.roles,
    openOverlay: false,
    selectedAccount: this.props.selectedAccount,
    members: [],
    enableAddContributor: false,
    enableEditContributor: false,
    enableDeleteContributor: false,
    contributorEditMode: false,
    memberRowsSelected: [],
  };

  componentDidMount() {
    this.handleGetMembers();
  }

  handleGetMembers = async () => {
    this.setState({
      openOverlay: true
    })
    let { _id } = this.state.selectedAccount;
    this._accountHandler.getAccountMembers(_id).then((members) => {
      this.setState({
        members,
        openOverlay: false,
        enableAddContributor: this.state.roles.owner || this.state.roles.admin,
        enableDeleteContributor: false,
        enableEditContributor: false,
      })
    })
  }

  handleDeleteContributor = async () => {
    let member = this.state.memberRowsSelected[0];
    this.setState({ openOverlay: true });
    this._accountHandler.deleteAccountMember(this.state.selectedAccount._id, member.uid).then(()=>{
      this.setState({
        openOverlay: false,
      });
      this.handleCloseModal("contributor");
    })
  };

  handleOpenModal = (modal = "contributor-add") => {
    let showModal = this.state.open;
    showModal.contributorDeleteConfirmation = (modal === "contributor-delete");
    showModal.openNewContributor = (modal === "contributor-add" || modal === "contributor-edit");

    this.setState({
      open: showModal,
      contributorEditMode: (modal === "contributor-edit")
    })
  }

  handleCloseModal = (modal = "contributor") => {
    let showModal = this.state.open;
    showModal.contributorDeleteConfirmation = false;
    showModal.openNewContributor = false;
    this.setState({
      open: showModal
    });
    if (modal === "contributor") {
      this.handleGetMembers()
    }
  };

  onSharingSelectionChanged() {
    let selectedRows = this.sharingGridApi.getSelectedRows();
    this.setState({
      memberRowsSelected: selectedRows,
      enableAddContributor: (this.state.roles.owner || this.state.roles.admin),
      enableDeleteContributor: (selectedRows.length === 1 && (this.state.roles.owner || this.state.roles.admin)),
      enableEditContributor: (selectedRows.length === 1 && (this.state.roles.owner || this.state.roles.admin))
    })
  }

  handleOnSharingGridReady = params => {
    this.sharingGridApi = params.api;
    this.sharingGridColumnApi = params.columnApi;
    this.sharingGridApi.sizeColumnsToFit();
  };

  renderContributorModal() {
    let member = this.state.memberRowsSelected[0];
    let contributor = Constants.mapMemberToContributor(member);
    return (
      <NewContributor
        display={this.state.open.openNewContributor}
        account={this.state.selectedAccount}
        onCancel={() => this.handleCloseModal("contributor")}
        onSubmit={() => this.handleCloseModal("contributor")}
        update={this.state.contributorEditMode}
        member={contributor}
      />
    )
  }
  renderSharingActions() {
    return (
      <Grid container style={{ margin: "4px" }}>
        <Grid item md={12} sm={12} xs={12}>
          {this.state.enableAddContributor && <Button disabled={!this.state.enableAddContributor}
            onClick={() => this.handleOpenModal("contributor-add")}
            variant="contained"
            size="small"
            color="default">
            <i className="pe-7s-add-user"></i> Add
                      </Button>}
        &nbsp;
        {this.state.enableEditContributor && <Button disabled={!this.state.enableEditContributor}
            onClick={() => this.handleOpenModal("contributor-edit")}
            variant="contained"
            size="small"
            color="primary">Edit</Button>}
        &nbsp;
        {this.state.enableDeleteContributor && <Button disabled={!this.state.enableDeleteContributor}
            onClick={() => this.handleOpenModal("contributor-delete")}
            variant="contained"
            size="small"
            color="secondary">Delete</Button>}
        &nbsp;
      </Grid>
      </Grid>
    )
  }

  renderSharingGrid() {
    const { classes } = this.props;
    const agStyles = `ag-theme-balham ${classes.agThemeModified}`;
    return (
      <Grid container id='accountinfo-members-grid' /* style={{"height": "70%", width: "96%", marginLeft: "2%", marginTop: "10px"}} */ >
        <Grid item md={12} sm={12} xs={12}>
          {this.renderSharingActions()}
          {(this.state.members.length === 0) &&
            <Typography>This account is not shared with anybody yet.</Typography>}
          {(this.state.members.length > 0) && <div className={agStyles}>
            <AgGridReact
              gridOptions={{
                defaultColDef: {
                  resizable: false,
                  sortable: false,
                }
              }}
              // onRowDragEnter={this.onRowDragEnter}
              // onRowDragEnd={this.onRowDragEnd}
              onGridReady={this.handleOnSharingGridReady}
              // onRowDragLeave={this.onRowDragLeave}
              columnDefs={[{
                header: "email",
                field: "email",
                width: 280
              }, {
                header: "uid",
                field: "uid",
                width: 180
              }, {
                header: "role",
                field: "role",
                width: 120,
                cellRenderer: (params) => {
                  switch (params.value) {
                    case "admin":
                      return "Administrator"
                    case "viewer":
                      return "Viewer"
                    case "editor":
                      return "Editor"
                    case "contributor":
                      return "Contributor"
                    default:
                      break;
                  }
                }
              }, {
                header: "status",
                field: "status",
                width: 100,
                cellRenderer: (params) => {
                  return (params.value === "ac" ? "Active" : "Inactive")
                }
              }]}
              rowData={this.state.members}
              // getRowClass={this.handleRowHighlights}
              rowSelection="single"
              onSelectionChanged={this.onSharingSelectionChanged.bind(this)}
              // rowDragManaged={true}
              animateRows
              // pagination={!this.state.gridEditMode}
              // paginationPageSize={25}
              enableCellTextSelection="true"
              ensureDomOrder="true"
            />
          </div>}
        </Grid>
      </Grid>
    )
  }

  render() {
    return (
      <div>
        {this.renderSharingGrid()}
        <Dialog
          open={this.state.open.contributorDeleteConfirmation}><Typography>
            {`Are you sure to delete the member?`}
          </Typography><Button
            onClick={() => this.handleDeleteContributor()}
          >Yes</Button><Button onClick={() => this.handleCloseModal("")}>No</Button></Dialog>

        {this.state.open.openNewContributor && this.renderContributorModal()}

        {this.state.openOverlay && <LoadingOverlay />}
      </div>
    )
  }
}

SharingManager.propTypes = {
  classes: PropTypes.object.isRequired,
  display: PropTypes.bool.isRequired,
  selectedAccount: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withStyles(AccountStyle)(SharingManager)));
