
export const ListItemGrid = {
  defaultColDef: {
    resizable: true,
    filter: true,
    sortable: true,
  },

  columns: [
    {
      headerName: 'Name',
      field: 'name',
      width: 100
    },{
      headerName: 'Code',
      field: 'code',
      width: 100
    },/* {
      headerName: 'Group',
      field: 'list',
      width: 120
    }, */ {
      headerName: 'Status',
      field: 'status',
      width: 40,
      cellRenderer: function (params) {
        return (params.value === 'ac') ? 'Active' : 'Inactive';
      }
    } 
  ]
};
