export default function mapDispatchToProps (dispatch) {
  return {
      changePageTitle: pageTitle => {
          dispatch({ type: 'SET_PAGE_TITLE', data: pageTitle})
      },
      setViewPort: (viewPort='mobile') => {
        dispatch({ type: 'SET_VIEW_PORT', data: viewPort})
      },
      setPlatForm: (platform='web') => {
        dispatch({ type: 'SET_DEVICE_PLATFORM', data: platform})
      },
      setScreenWidth: (width=0) => {
        dispatch({ type: 'SET_SCREEN_WIDTH', data: width})
      },
      // showSnackbar: (message, severity='info') => {
      //     const severityType = {
      //       'success': 'SNACKBAR_SUCCESS',
      //       'info': 'SNACKBAR_INFO',
      //       'warning': 'SNACKBAR_WARNING',
      //       'error': 'SNACKBAR_ERROR'
      //     }
      //     dispatch({ type: severityType[severity], message, delay: 2000 })
      // },
      // enqueueSnackbar: (message, options={variant: 'info', delay: 2000}) => {
      //     const severityType = {
      //       'success': 'SNACKBAR_SUCCESS',
      //       'info': 'SNACKBAR_INFO',
      //       'warning': 'SNACKBAR_WARNING',
      //       'error': 'SNACKBAR_ERROR'
      //     }
      //     dispatch({ type: severityType[options.variant], message, delay: options.delay})
      // },
      changeAuthToken: (authToken) => {
        dispatch({ type: 'SET_SESSION', data: authToken });
      },
      changeRefreshToken: (refreshToken) => {
        dispatch({ type: 'SET_REFRESH_TOKEN', data: refreshToken })
      }
  }
}